import { User, UserDocumentsByUserIdList, UsersList } from '../../type/user';

import { parseUser, parseUserDocuments } from './parsers/user-parser';
import { HTTPUserResponse, HTTPUserListResponse, HTTPUserDocumentsByUserId, HTTPUserDocumentsByUserIdList } from './http-users-response';
import api from '../api';
import DefaultAvatar from '../../assets/img/wanna/wanna3.png';

export async function getAllUsers(): Promise<UsersList> {
	return api.get(`/api/v1/users/?max_results=10000`, {
		headers: {
			accept: 'application/json',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPUserListResponse;

		return responseJson.results.map(parseUser);
	});
}

export async function getUserById(userId: number): Promise<User> {
	return api.get(`/api/v1/users/${userId}`, {
		headers: {
			accept: 'application/json',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPUserResponse;

		return parseUser(responseJson);
	});
}

export async function getUserByTaxid(taxid: string): Promise<User> {
	return api.get(`/api/v1/users/taxid/${taxid}`, {
		headers: {
			accept: 'application/json',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPUserResponse;

		return parseUser(responseJson);
	});
}

interface GetUserSelfieById {
	setAvatar: React.Dispatch<React.SetStateAction<string>>;
}
export async function getUserSelfieById(userId: number, { setAvatar }: GetUserSelfieById, url?: string) {
	if (userId !== null && userId.toString() !== "" && userId !== 0) {
		const result = await api.get(`/api/v1/users/selfie/${userId}?returnUrl=true`, {
			headers: {
				accept: 'application/json',
			}
		}).then((response) => {
			setAvatar(response.data.url)
			return response.data.url;
		}).catch((error) => {
			setAvatar(DefaultAvatar);
			return DefaultAvatar;
		});

		return result;
	} else {
		setAvatar(DefaultAvatar);
		return DefaultAvatar;
	}
}

interface SendMessageToUserProps {
	message: string;
	telephone: string;
}
// @ts-ignore
export async function sendMessageToUser({ telephone, message }: SendMessageToUserProps): Promise {
	const url = `https://web.whatsapp.com/send?phone=${telephone}&text=${encodeURI(
		message,
	)}&app_absent=0`;

	const response = await fetch(url, {
		method: 'POST',
		body: JSON.stringify({}),
		headers: { 'Content-type': 'application/json; charset=UTF-8' },
	});

	if (response.ok) {
		return response.ok;
	}

	throw new Error(response.statusText);
}

export async function getUserDocumentsByUserId(userId: number): Promise<UserDocumentsByUserIdList> {
	return await api.get(`/api/v1/userdocuments/user_id/${userId}`, {
		headers: {
			accept: 'application/json',
		}
	}).then((response) => {
		if (response && response.data) {
			const responseJson = response.data as HTTPUserDocumentsByUserIdList;
			return responseJson.results.map(parseUserDocuments);
		}

		return {} as UserDocumentsByUserIdList
	});
}

// export async function getUserByTaxid(taxid: string): Promise<User> {
// 	const url = `${API_HOST}/api/v1/users/taxid/${taxid}`;

// 	return traceSpan(url, async () => {
// 		const response = await fetch(url);

// 		if (response.ok) {
// 			const responseJson = (await response.json()) as HTTPUserResponse;

// 			return parseUser(responseJson);
// 		}

// 		throw new Error(response.statusText);
// 	});
// }

interface UpdateUserByIdParams {
	id: number;
	name: string;
	taxid: string | null;
	telephone: string;
	email: string | null;
	selfie: string | null;
	role: string;
	birthdate?: string | null;
	fatherName?: string | null;
	motherName?: string | null;
	documentId: string | null;
	selfieUploadedAt: string | undefined;
	selfieSourceUrl: string | undefined;
	status: string;
	createdBy: number | undefined;
	createdAt: string | undefined;
	modifiedAt: string | undefined | null;
	modifiedBy: number | undefined | null;
	isSuperuser: boolean;
	observation: string;
	inquiry_status: string;
}

export async function updateUserById({
	id,
	name,
	taxid,
	telephone,
	email,
	selfie,
	role,
	birthdate,
	fatherName,
	motherName,
	documentId,
	selfieUploadedAt,
	selfieSourceUrl,
	status,
	createdBy,
	createdAt,
	modifiedAt,
	modifiedBy,
	isSuperuser,
	observation,
	inquiry_status,
}: UpdateUserByIdParams): Promise<User> {
	const body = {
		id,
		name,
		taxid,
		telephone,
		email: email == '' ? null : email,
		selfie,
		role,
		birthdate,
		father_name: fatherName,
		mother_name: motherName,
		document_id: documentId,
		selfie_uploaded_at: selfieUploadedAt,
		selfie_source_url: selfieSourceUrl,
		status,
		created_by: createdBy,
		created_at: createdAt,
		modified_at: modifiedAt,
		modified_by: modifiedBy,
		is_superuser: isSuperuser,
		observation,
		inquiry_status,
	};

	return api.put(`/api/v1/users`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPUserResponse;

		return parseUser(responseJson);
	});
}

interface CreateUserInterface {
	name: string;
	taxid: string | null;
	telephone: string;
	email: string | null;
	selfie: string | null;
	role: string;
	birthdate?: string | null;
	fatherName?: string | null;
	motherName?: string | null;
	documentId: string | null;
	status: string;
	isSuperuser: boolean;
	createdBy: number;
	createdAt: string;
	inquiry_status: string;
	observation: string;
}
export async function createUser({
	name,
	taxid,
	telephone,
	email,
	selfie,
	role,
	birthdate,
	fatherName,
	motherName,
	documentId,
	status,
	isSuperuser,
	createdBy,
	createdAt,
	inquiry_status,
	observation
}: CreateUserInterface): Promise<User> {
	const body = {
		name,
		taxid,
		telephone,
		email,
		selfie,
		role,
		birthdate,
		father_name: fatherName,
		mother_name: motherName,
		document_id: documentId,
		is_superuser: isSuperuser,
		status,
		created_by: createdBy,
		created_at: createdAt,
		inquiry_status,
		observation,
	};

	return api.post(`/api/v1/users/`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPUserResponse;

		return parseUser(responseJson);
	});
}