
export const INQUIRY_STATUS = {
	NEW: 'new',
	ANALYZE: 'analyze',
	REJECTED: 'rejected',
	ACCEPTED: 'accepted',
	BACKGROUND: 'background',
}

export const getRegisterStatusColor = (tokenStatus: string) => {
	switch (tokenStatus) {
		case INQUIRY_STATUS.NEW:
			return 'primary';
		case INQUIRY_STATUS.ANALYZE:
			return 'warning';
		case INQUIRY_STATUS.REJECTED:
			return 'danger';
		case INQUIRY_STATUS.ACCEPTED:
			return 'success';
		case INQUIRY_STATUS.BACKGROUND:
			return 'info';
		default:
			return 'primary';
	}
};

export const getRegisterStatusText = (tokenStatus: string) => {
	switch (tokenStatus) {
		case INQUIRY_STATUS.NEW:
			return 'Novo';
		case INQUIRY_STATUS.ANALYZE:
			return 'Analisar';
		case INQUIRY_STATUS.REJECTED:
			return 'Rejeitado';
		case INQUIRY_STATUS.ACCEPTED:
			return 'Aprovado';
		case INQUIRY_STATUS.BACKGROUND:
			return 'Notificado';
		default:
			return 'Indefinido';
	}
};