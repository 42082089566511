import { Gate } from '../../../type/gate';
import { HTTPGateResponse } from '../http-gate-response';

export function parseGate(httpGate: HTTPGateResponse): Gate {
	return {
		id: httpGate.id,
		name: httpGate.name,
		buildingId: httpGate.building_id,
		gateType: httpGate.gate_type,
		isOT: httpGate.isIOT,
		hasIntegration: httpGate.hasIntegration,
		iotManufacturer: httpGate.iot_manufacturer,
		iotModel: httpGate.iot_model,
		apiEndpoint: httpGate.api_endpoint,
		apiUser: httpGate.api_user,
		apiPassword: httpGate.api_password,
		apiToken: httpGate.api_token,
		status: httpGate.status,
		createdBy: httpGate.created_by,
	};
}
