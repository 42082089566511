import { AccessLog } from '../../../type/access-log';
import { HTTPAccessLogResponse } from '../http-access-log-response';

export function parseAccessLog(httpAccessLog: HTTPAccessLogResponse | undefined): AccessLog {
	return {
		id: httpAccessLog?.id,
		tokenId: httpAccessLog?.token_id,
		buildingId: httpAccessLog?.building_id,
		spotId: httpAccessLog?.spot_id,
		authorizationChain: httpAccessLog?.authorization_chain,
		onboardingGateId: httpAccessLog?.onboarding_gate_id,
		onboardingAt: httpAccessLog?.onboarding_at,
		conciergeUserId: httpAccessLog?.concierge_user_id,
		checkoutGateId: httpAccessLog?.checkout_gate_id,
		checkoutAt: httpAccessLog?.checkout_at,
		rejectedAt: httpAccessLog?.rejected_at,
		description: httpAccessLog?.description,
		controlStatus: httpAccessLog?.control_status,
		status: httpAccessLog?.status,
		createdAt: httpAccessLog?.created_at,
		createdBy: httpAccessLog?.created_by,
		modifiedAt: httpAccessLog?.modified_at,
		modifiedBy: httpAccessLog?.modified_by,
	};
}
