import { useContext } from 'react';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';

import { CardBody } from '../../../components/bootstrap/Card';
import { BuildingForm } from '../../../components/entrada-segura/forms/building/building-form';

import { UserContext } from '../../../contexts/user-context';
import { useUserBuildings } from '../../../hooks/entrada-segura/use-user-buildings';
import { useIsUserManager } from '../../../hooks/entrada-segura/use-is-user-manager';

import { UserFormAdmin } from '../../../components/entrada-segura/forms/user/user-form-admin';
import { GateForm } from '../../../components/entrada-segura/forms/gate/gate-form';
import { SpotForm } from '../../../components/entrada-segura/forms/spot/spot-form';

export const AdminView = () => {
	const { user } = useContext(UserContext);
	const userBuildings = useUserBuildings({ userId: user.id, isSuperUser: user.isSuperUser });
  const isUserAManager = useIsUserManager({ userId: user.id, role: 'contract_manager' })

  const canUserCreateNewSpots = user.isSuperUser || isUserAManager

	return (
		<PageWrapper title={'Admin'}>
			<Page container='fluid'>
				<CardBody className='table-responsive'>
					<div className='row'>
						{user.isSuperUser && (
							<div className='col-xl-4'>
								<UserFormAdmin />
							</div>
						)}

						{user.isSuperUser && (
							<div className='col-xl-4'>
								<BuildingForm />
							</div>
						)}

						{user.isSuperUser && (
							<div className='col-xl-4'>
								<GateForm />
							</div>
						)}
					</div>

					{canUserCreateNewSpots && (
            <div className='row'>
							<div className='col-xl-4'>
								<SpotForm userBuildings={userBuildings} />
							</div>
					</div>)}
				</CardBody>
			</Page>
		</PageWrapper>
	);
};
