import { HighestRole, Token } from '../../../type/token';
import { User } from '../../../type/user';
import { HTTPUserResponse } from '../../user/http-users-response';
import { HTTPTokenHighestRoleUser, HTTPTokenResponse } from '../http-token-response';

export function parseToken(httpToken: HTTPTokenResponse): Token {
	return {
		id: httpToken.id,
		issuedFor: httpToken.issued_for,
		issuedBy: httpToken.issued_by,
		issuedAt: httpToken.issued_at,
		spotId: httpToken.spot_id,
		buildingId: httpToken.building_id,
		authorizationType: httpToken.authorization_type,
		authorizationChain: httpToken.authorization_chain,
		expiresAt: httpToken.expires_at,
		expiresCount: httpToken.expires_count,
		status: httpToken.status,
		subject: httpToken.subject,
		role: httpToken.role,
		accessType: httpToken.access_type,
		linkId: httpToken.link_id,
		linkType: httpToken.link_type,
		whastappURL: httpToken.whastappURL,
		recurrent: httpToken.recurrent,
		tag: httpToken.tag,
		userRequested: httpToken.user_requested && parseTokenUser(httpToken.user_requested),
		userIssued: httpToken.user_issued && parseTokenUser(httpToken.user_issued)
	};
}

const parseTokenUser = (user: HTTPUserResponse): User => {
	return ({
		id: user.id,
		name: user.name,
		taxid: user.taxid,
		telephone: user.telephone,
		email: user.email,
		status: user.status,
		birthdate: user.birthdate,
		isSuperuser: user.is_superuser
	})
}

export const parseUserHighestRole = (data: HTTPTokenHighestRoleUser): HighestRole => {
	return ({
		buildingId: data.building_id,
		id: data.id,
		index: data.index,
		role: data.role
	})
}