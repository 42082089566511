import Axios from 'axios';
import showNotification from '../components/extras/showNotification';
import { API_HOST } from './constants';
import { HandleErrosSlack, HandleErrosSlackProps } from './slack/handle-notifications-slack';

const api = Axios.create({ baseURL: API_HOST });

const sendMessageToSlack = async (bodySlackBot: HandleErrosSlackProps) => {
    await HandleErrosSlack(bodySlackBot);
}

api.interceptors.response.use(response => response, error => {
    if (error.response?.status !== 401 && error.response?.status !== 409) {
        if (error.response?.status === 404 && error.config.url.includes("/api/v1/boardingpass/") && error.config.method === "get") {
            return;
        }

        if (error.response?.status === 403 && error.config.method === "get") {
            return;
        }

        if (error.response?.status === 404 && error.config.url.includes("/api/v1/userdocuments/") && error.config.method === "get") {
            return;
        }

        if (error.response?.status === 404 && error.config.url.includes("/api/v1/tokens/hightest-token/") && error.config.method === "get") {
            return;
        }

        const bodySlackBot: HandleErrosSlackProps = {
            data: error.config.data,
            detail: error.response.data.detail,
            headers: error.config.headers.toString(),
            status: `${error.response.status} - ${error.response.statusText}`,
            url: error.config.baseURL,
            method: error.config.method,
            endpoint: error.config.url
        }

        sendMessageToSlack(bodySlackBot);
    }

    if (error.response?.status === 401) {
        showNotification(`Sessão expirada! Favor, efetuar login novamente!`, ``, 'danger');
    }

    if (error.response?.status === 409) {
        throw new Error(error.response);
    }

    if (error.response?.status === 400) {
        return showNotification(`Ops, parece que ocorreu um error! Favor, entre em contato com nosso suporte!`, ``, 'danger');
    }
});

export default api;