
import classNames from 'classnames';
import moment from 'moment';
import { useContext, useEffect, useState } from "react";
import Swal from 'sweetalert2';
import DefaultAvatar from '../../../../assets/img/wanna/wanna3.png';
import { UserContext } from '../../../../contexts/user-context';
import { formatCpf, phoneMask } from '../../../../helpers/helpers';
import useDarkMode from "../../../../hooks/useDarkMode";
import { getBuildingById } from '../../../../services/building/building-service';
import { getSpotById } from '../../../../services/spot/spot-service';
import { getTokenAcestors, getTokenDescendants, getTokenGrantOrCheckInFromTokenQualify, updateTokenById } from '../../../../services/token/token-service';
import { getUserDocumentsByUserId, getUserSelfieById } from '../../../../services/user/user-service';
import { requestBackgroundCheck } from '../../../../services/zapi/zapi-service';
import { Building } from '../../../../type/building';
import { Spot } from '../../../../type/spot';
import { Token } from '../../../../type/token';
import Avatar from '../../../Avatar';
import Button, { ButtonGroup } from '../../../bootstrap/Button';
import Popovers from '../../../bootstrap/Popovers';
import showNotification from '../../../extras/showNotification';
import { TableColumn } from '../../consult-components/table-components/table-column';
import { AUTH_TYPES, TOKEN_STATUS } from '../../token/token-activation-form/blocks/constants';

async function openAvatarModal(avatar: string) {
	Swal.fire({
		title: 'Selfie do usuário',
		html:
			`<img src="${avatar}" style="display: block; !important; margin-left: auto !important; margin-right: auto !important; max-width: 300px !important; max-height: 500px !important;" />`,
		showDenyButton: false,
		confirmButtonText: 'Ok',
		allowOutsideClick: true,
		backdrop: true
	});
}

function getRoleText(role: string | undefined) {
	switch (role) {
		case "visitor":
			return 'Visitante';
		case "family":
			return 'Familiar';
		case "party":
			return 'Festa';
		case "service":
			return 'Serviço';
		case "owner":
			return 'Proprietário';
		case "rent":
			return 'Aluguel';
		case "realtor":
			return 'Corretor';
		case "delivery":
			return 'Entrega';
		case "service_season":
			return 'Serviço Temporada';
		default:
			return 'Perfil não encontrado'
	}
}

export async function handleRequestBackgroundCheck(userName: string, telephone: string) {
	Swal.fire({
		title: 'ATENÇÃO',
		html:
			`<span class"fs-3">Deseja realmente reenviar a solicitação de Background Check para <b>${userName}</b>?</span>`,
		customClass: {
			container: 'bg-dark',
			htmlContainer: 'text-left',
			confirmButton: 'bg-info',
		},
		showDenyButton: true,
		confirmButtonText: 'Sim',
		allowOutsideClick: false,
		denyButtonText: `Não`,
	}).then(async (result) => {
		if (result.isConfirmed) {
			try {
				await requestBackgroundCheck({ phoneNumber: telephone });

				showNotification(`Solicitação enviada com sucesso`, ``, 'success');
			} catch (error) {
				showNotification(`Ocorreu um erro ao enviar a solicitação! Tente novamente`, ``, 'error');
				console.error(error);
			}
		}
		else if (result.isDenied) {
			showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
		}
	});
}

interface InquiryNewRowTokensProps {
	item: Token;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
	setModalCreateOrEditUserVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setUserToEdit: React.Dispatch<React.SetStateAction<number>>;
}

export const InquiryNewRowTokens: React.FC<InquiryNewRowTokensProps> = ({
	item,
	setRefreshTable,
	setModalCreateOrEditUserVisible,
	setUserToEdit
}) => {
	const { darkModeStatus } = useDarkMode();
	const { user } = useContext(UserContext)

	const [avatar, setAvatar] = useState<string>(DefaultAvatar);
	const [building, setBuilding] = useState<Building>();
	const [spot, setSpot] = useState<Spot>();
	const [serviceHasBackgroundAlready, setServiceHasBackgroundAlready] = useState<boolean>(true);

	useEffect(() => {
		if (item.userRequested.id)
			getUserSelfieById(item.userRequested.id, { setAvatar })
	}, [item.userRequested.id]);

	useEffect(() => {
		const getBuildingSpotData = async () => {
			if (item.buildingId) {
				const buildingData = await getBuildingById(item.buildingId)
				setBuilding(buildingData)
			}

			if (item.spotId) {
				const spotData = await getSpotById(item.spotId)
				setSpot(spotData)
			}
		}

		getBuildingSpotData()
	}, [item])

	useEffect(() => {
		const getUserRequestedData = async () => {
			const userData = await getUserDocumentsByUserId(item.userRequested.id)

			const userDocumentsBackgroundCheck = userData.filter((document) => document.documentType === "Background Check").map((document) => document.id);

			if (userDocumentsBackgroundCheck.length <= 0) {
				setServiceHasBackgroundAlready(false);
			}
		}

		if (item.userRequested.id) {
			getUserRequestedData()
		}
	}, [item])

	function handleOpenModalEditUser() {
		setUserToEdit(item.userRequested.id)
		setModalCreateOrEditUserVisible(true)
	}

	async function handleAcceptAccess() {
		if (serviceHasBackgroundAlready === false && item.tag === "service") {
			Swal.fire({
				title: 'ATENÇÃO',
				html:
					`<span class"fs-3">Deseja realmente aceitar esse acesso? Ele ainda não enviou nenhum documento para o Background Check!</span>`,
				customClass: {
					container: 'bg-dark',
					htmlContainer: 'text-left',
					confirmButton: 'bg-info',
				},
				showDenyButton: true,
				confirmButtonText: 'Sim',
				allowOutsideClick: false,
				denyButtonText: `Não`,
			}).then(async (result) => {
				if (result.isConfirmed) {
					PostAcceptAccess()
				}
				else if (result.isDenied) {
					showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
				}
			});
		}
		else {
			Swal.fire({
				title: 'ATENÇÃO',
				html:
					`<span class"fs-3">Deseja realmente aceitar esse acesso?</span>`,
				customClass: {
					container: 'bg-dark',
					htmlContainer: 'text-left',
					confirmButton: 'bg-info',
				},
				showDenyButton: true,
				confirmButtonText: 'Sim',
				allowOutsideClick: false,
				denyButtonText: `Não`,
			}).then(async (result) => {
				if (result.isConfirmed) {
					PostAcceptAccess()
				}
				else if (result.isDenied) {
					showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
				}
			});
		}
	}

	const PostAcceptAccess = async () => {
		try {
			//INACTIVATE token QUALIFY
			await updateTokenById({
				reasonAccess: item.tag!,
				tokenId: item.id,
				modifiedAt: moment().toISOString(true),
				modifiedBy: user.id,
				status: TOKEN_STATUS.INACTIVE,
			});

			try {
				const tokenGrantOrCheckinList = await getTokenGrantOrCheckInFromTokenQualify({ token: item })

				const tokenGrantOrCheckin = tokenGrantOrCheckinList.results.at(-1)

				if (tokenGrantOrCheckin !== undefined) {
					//ACTIVATE token GRANT/CHECKIN
					return await updateTokenById({
						reasonAccess: tokenGrantOrCheckin.tag!,
						tokenId: tokenGrantOrCheckin.id,
						modifiedAt: moment().toISOString(true),
						modifiedBy: user.id,
						status: TOKEN_STATUS.ACTIVE,
					});
				}
				else {
					//Case not find GRANT/CHECKIN => Turn QUALIFY back to ACTIVE
					await updateTokenById({
						reasonAccess: item.tag!,
						tokenId: item.id,
						modifiedAt: moment().toISOString(true),
						modifiedBy: user.id,
						status: TOKEN_STATUS.ACTIVE,
					});
					return showNotification(`Não foi possível encontrar o token GRANT/CHECKIN pendente para este usuário que pertença a esta cadeia de autorização!`, ``, 'danger');
				}
			} catch (error) {
				//Case error on activating GRANT/CHECKIN => Turn QUALIFY back to ACTIVE
				await updateTokenById({
					reasonAccess: item.tag!,
					tokenId: item.id,
					modifiedAt: moment().toISOString(true),
					modifiedBy: user.id,
					status: TOKEN_STATUS.ACTIVE,
				});
				showNotification(`Parece que algo deu errado ao ativar o token grant/checkin deste usuário!`, ``, 'danger');
			}
		} catch (error) {
			showNotification(`Parece que algo deu errado ao aceitar esse acesso!`, ``, 'danger');
		} finally  {			
			setRefreshTable(true);
		}
	}

	async function handleDenyAccess() {
		if (serviceHasBackgroundAlready === false && item.tag === "service") {
			Swal.fire({
				title: 'ATENÇÃO',
				html:
					`<span class"fs-3">Deseja realmente negar esse acesso? Ele ainda não enviou nenhum documento para o Background Check!</span>`,
				customClass: {
					container: 'bg-dark',
					htmlContainer: 'text-left',
					confirmButton: 'bg-info',
				},
				showDenyButton: true,
				confirmButtonText: 'Sim',
				allowOutsideClick: false,
				denyButtonText: `Não`,
			}).then(async (result) => {
				if (result.isConfirmed) {
					await PostRejectAccess()
				}
				else if (result.isDenied) {
					showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
				}
			});
		}
		else {
			Swal.fire({
				title: 'ATENÇÃO',
				html:
					`<span class"fs-3">Deseja realmente negar esse acesso?</span>`,
				customClass: {
					container: 'bg-dark',
					htmlContainer: 'text-left',
					confirmButton: 'bg-info',
				},
				showDenyButton: true,
				confirmButtonText: 'Sim',
				allowOutsideClick: false,
				denyButtonText: `Não`,
			}).then(async (result) => {
				if (result.isConfirmed) {
					await PostRejectAccess()
				}
				else if (result.isDenied) {
					showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
				}
			});
		}
	}

	const PostRejectAccess = async () => {
		try {
			//REJECT token QUALIFY
			await updateTokenById({
				reasonAccess: item.tag!,
				tokenId: item.id,
				modifiedAt: moment().toISOString(true),
				modifiedBy: user.id,
				status: TOKEN_STATUS.REJECTED,
			});

			try {
				const tokenGrantOrCheckinList = await getTokenGrantOrCheckInFromTokenQualify({ token: item })

				const tokenGrantOrCheckin = tokenGrantOrCheckinList.results.find((token) => token.id !== "")
				
				if (tokenGrantOrCheckin !== undefined) {
					//INACTIVATE token GRANT/CHECKIN
					return await updateTokenById({
						reasonAccess: tokenGrantOrCheckin.tag!,
						tokenId: tokenGrantOrCheckin.id,
						modifiedAt: moment().toISOString(true),
						modifiedBy: user.id,
						status: TOKEN_STATUS.INACTIVE,
					});
				}
				else {
					//Case not find GRANT/CHECKIN => Turn QUALIFY back to ACTIVE
					await updateTokenById({
						reasonAccess: item.tag!,
						tokenId: item.id,
						modifiedAt: moment().toISOString(true),
						modifiedBy: user.id,
						status: TOKEN_STATUS.ACTIVE,
					});
					return showNotification(`Não foi possível encontrar o token GRANT/CHECKIN pendente para este usuário que pertença a esta cadeia de autorização!`, ``, 'danger');
				}
			} catch (error) {
				//Case error on activating GRANT/CHECKIN => Turn QUALIFY back to ACTIVE
				await updateTokenById({
					reasonAccess: item.tag!,
					tokenId: item.id,
					modifiedAt: moment().toISOString(true),
					modifiedBy: user.id,
					status: TOKEN_STATUS.ACTIVE,
				});
				showNotification(`Parece que algo deu errado ao inativar o token grant/checkin deste usuário!`, ``, 'danger');
			}
		} catch (error) {
			showNotification(`Parece que algo deu errado ao rejeitar esse acesso!`, ``, 'danger');
		} finally  {			
			setRefreshTable(true);
		}
	}

	async function handleAnalyzeAccess() {
		if (serviceHasBackgroundAlready === false && item.tag === "service") {
			Swal.fire({
				title: 'ATENÇÃO',
				html:
					`<span class"fs-3">Deseja realmente colocar esse acesso como Analisar? Ele ainda não enviou nenhum documento para o Background Check!</span>`,
				customClass: {
					container: 'bg-dark',
					htmlContainer: 'text-left',
					confirmButton: 'bg-info',
				},
				showDenyButton: true,
				confirmButtonText: 'Sim',
				allowOutsideClick: false,
				denyButtonText: `Não`,
			}).then(async (result) => {
				if (result.isConfirmed) {
					await PostAnalyzeAccess()
				}
				else if (result.isDenied) {
					showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
				}
			});
		}
		else {
			Swal.fire({
				title: 'ATENÇÃO',
				html:
					`<span class"fs-3">Deseja realmente colocar esse acesso como Analisar?</span>`,
				customClass: {
					container: 'bg-dark',
					htmlContainer: 'text-left',
					confirmButton: 'bg-info',
				},
				showDenyButton: true,
				confirmButtonText: 'Sim',
				allowOutsideClick: false,
				denyButtonText: `Não`,
			}).then(async (result) => {
				if (result.isConfirmed) {
					await PostAnalyzeAccess()
				}
				else if (result.isDenied) {
					showNotification(`Ação cancelada pelo usuário`, ``, 'warning');
				}
			});
		}
	}

	const PostAnalyzeAccess = async () => {
		try {
			//ANALYZE token QUALIFY
			await updateTokenById({
				reasonAccess: item.tag!,
				tokenId: item.id,
				modifiedAt: moment().toISOString(true),
				modifiedBy: user.id,
				status: TOKEN_STATUS.PENDING,
			});
		} catch (error) {
			showNotification(`Parece que algo deu errado ao colocar o acesso como Analisar!`, ``, 'danger');
		} finally  {			
			setRefreshTable(true);
		}
	}

	return (
		<>
			<tr key={item.id}>
				<TableColumn>
					<Button
						isOutline={!darkModeStatus}
						color='dark'
						isLight={darkModeStatus}
						className={classNames('text-nowrap', {
							'border-light': !darkModeStatus,
						})}
						icon='RemoveRedEye'
						style={{ marginRight: '5px' }}
						title={"Documentos"}
						onClick={() => handleOpenModalEditUser()}
					>
						Ver documentos
					</Button>

					<ButtonGroup>
						<Button
							isOutline={!darkModeStatus}
							color='dark'
							isLight={darkModeStatus}
							className={classNames('text-nowrap', {
								'border-light': !darkModeStatus,
							})}
							icon='Check'
							style={{ marginRight: '5px' }}
							title={"Aceitar"}
							onClick={() => handleAcceptAccess()}
						/>
						<Button
							isOutline={!darkModeStatus}
							color='dark'
							isLight={darkModeStatus}
							className={classNames('text-nowrap', {
								'border-light': !darkModeStatus,
							})}
							icon='NotificationsActive'
							style={{ marginRight: '5px' }}
							title={"Analisar"}
							onClick={() => handleAnalyzeAccess()}
						/>
						<Button
							isOutline={!darkModeStatus}
							color='dark'
							isLight={darkModeStatus}
							className={classNames('text-nowrap', {
								'border-light': !darkModeStatus,
							})}
							icon='Dangerous'
							style={{ marginRight: '5px' }}
							title={"Negar"}
							onClick={() => handleDenyAccess()}
						/>
					</ButtonGroup>
				</TableColumn>
				<TableColumn>
					{serviceHasBackgroundAlready === false ?
						<span onClick={() => handleRequestBackgroundCheck(item.userRequested.name, item.userRequested.telephone)} style={{ color: '#E74C3C', fontWeight: 'bold' }} className='ms-3 d-flex align-items-center text-nowrap'>
							<Popovers trigger='hover' desc='Este usuário ainda não enviou os documentos necessários para Background Check! Clique aqui para reenviar a solicitação de documentos.'>
								{getRoleText(item.tag)}
							</Popovers>
						</span>
						:
						<span className='ms-3 d-flex align-items-center text-nowrap'>
							{getRoleText(item.tag)}
						</span>
					}
				</TableColumn>
				<TableColumn>{<Avatar title={"Clique para visualizar a selfie"} onClick={() => openAvatarModal(avatar)} style={{ cursor: 'pointer' }} src={avatar} srcSet={avatar} color={'success'} size={40} />}</TableColumn>
				<TableColumn>{item.userRequested.name}</TableColumn>
				<TableColumn>{building?.name} | {spot?.name}</TableColumn>
				<TableColumn>{item?.userIssued.name} | {moment(item.issuedAt).format("DD/MM/yy HH:mm")}</TableColumn>
				<TableColumn>{formatCpf(item.userRequested.taxid)}</TableColumn>
				<TableColumn>{phoneMask(item.userRequested.telephone)}</TableColumn>
			</tr>
		</>
	);
}