import React, { Fragment, ReactNode } from 'react';
import { GeneralHeader } from '../pages/common/Headers/general-header';
import { Private } from './private';

interface PrivateRouteWrapperProps {
	children: ReactNode;
}

export const PrivateRouteWrapper: React.FC<PrivateRouteWrapperProps> = ({ children }) => {
	return (
		<Fragment>
			<Private>
				<GeneralHeader />
				{children}
			</Private>
		</Fragment>
	);
};
