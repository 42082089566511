import { TokenInactivationSummaryList } from "../../type/token";
import api from "../api";
import { ENV } from "../constants";

export type HandleErrosSlackProps = {
    data: string;
    detail: string;
    headers: string;
    status: string;
    url: string;
    method: string;
    endpoint: string;
}

export async function HandleErrosSlack(data: HandleErrosSlackProps) {
    const loggedUser = localStorage.getItem('logged-user')

    const environment = ENV;
    let channel = "";
    switch (environment) {
        case "qa":
            channel = "#errors-qa"
            break;
        case "production":
            channel = "#errors"
            break;
        case "development":
            channel = "#errors-development"
            break;
        case "demo":
            channel = "#errors-demo"
            break;
        default:
            channel = "#errors"
            break;
    }
    const payload = data.data === undefined ? "Sem payload" : data.data
    const body = {
        channel: channel,
        attachments: [
            {
                color: "#c0392b",
                blocks: [
                    {
                        type: "section",
                        text: {
                            type: "mrkdwn",
                            text: "*_Frontend_*",
                        },
                    },
                    {
                        type: "section",
                        text: {
                            type: "mrkdwn",
                            text: `*Environment: * ${environment}`,
                        },
                    },
                    {
                        type: "section",
                        text: {
                            type: "mrkdwn",
                            text: `${data.detail}`,
                        },
                    },
                    {
                        type: "context",
                        elements: [
                            {
                                type: "mrkdwn",
                                text: `*Logged User: * ${loggedUser}`,
                            },
                        ],
                    },
                    {
                        type: "context",
                        elements: [
                            {
                                type: "mrkdwn",
                                text: `*URL: * ${data.url}`,
                            },
                        ],
                    },
                    {
                        type: "context",
                        elements: [
                            {
                                type: "mrkdwn",
                                text: "*REQUEST: *" + "`" + data.method.toUpperCase() + data.endpoint + "`",
                            },
                        ],
                    },
                    {
                        type: "context",
                        elements: [
                            {
                                type: "mrkdwn",
                                text: "*HEADERS: *" + "```" + data.headers + "```",
                            },
                        ],
                    },
                    {
                        type: "context",
                        elements: [
                            {
                                type: "mrkdwn",
                                text: "*EXCEPTION: *" + "`" + data.status + "`",
                            },
                        ],
                    },
                    {
                        type: "context",
                        elements: [
                            {
                                type: "mrkdwn",
                                text: "*PAYLOAD: *" + "```" + payload + "```",
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return api.post(`/api/v1/slack-service/send-message`, JSON.stringify(body), {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    }).then((response) => {
        return;
    });
}

export interface HandleInactivationSlackProps {
    childrenTokens: TokenInactivationSummaryList;
    inactivatingToken?: string;
    inactivatingUser: string;
    buildingName: string | undefined;
    spotName: string | undefined;
}

export async function HandleInactivationSlack({ childrenTokens, inactivatingToken, inactivatingUser, buildingName, spotName }: HandleInactivationSlackProps) {
    const loggedUser = localStorage.getItem('logged-user')

    const environment = ENV;
    let channel = "";
    switch (environment) {
        case "qa":
            channel = "#token-inactivation-qa"
            break;
        case "production":
            channel = "#token-inactivation"
            break;
        case "development":
            channel = "#token-inactivation-dev"
            break;
        case "demo":
            channel = "#token-inactivation-demo"
            break;
        default:
            channel = "#token-inactivation"
            break;
    }

    const childrenTokensData = childrenTokens.length > 0
        ?
        childrenTokens.map((token) => {
            return `${token.count} - ${token.tokenType.toUpperCase()} \n`
        }).join('')
        :
        "Sem tokens filhos";

    const body = {
        channel: channel,
        attachments: [
            {
                color: "#f39c12",
                blocks: [
                    {
                        type: "section",
                        text: {
                            type: "mrkdwn",
                            text: "*_Frontend_*",
                        },
                    },
                    {
                        type: "section",
                        text: {
                            type: "mrkdwn",
                            text: `*Environment: * ${environment.toUpperCase()}`,
                        },
                    },
                    {
                        type: "section",
                        text: {
                            type: "mrkdwn",
                            text: `O usuário ${loggedUser?.toUpperCase()} acabou de inativar o token do usuário ${inactivatingUser.toUpperCase()}`,
                        },
                    },
                    {
                        type: "context",
                        elements: [
                            {
                                type: "mrkdwn",
                                text: `*Token inativado: * ${inactivatingToken}`,
                            },
                        ],
                    },
                    {
                        type: "context",
                        elements: [
                            {
                                type: "mrkdwn",
                                text: `*Condomínio / SPOT: * ${buildingName} | ${spotName}`,
                            },
                        ],
                    },
                    {
                        type: "context",
                        elements: [
                            {
                                type: "mrkdwn",
                                text: `*Tokens filhos: * \n ${childrenTokensData}`,
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return api.post(`/api/v1/slack-service/send-message`, JSON.stringify(body), {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    }).then((response) => {
        return;
    });
}

export interface HandleRejectTokenSlackProps {
    tokenId: string;
    userRequestedName: string;
}

export async function HandleRejectTokenSlack({ tokenId, userRequestedName }: HandleRejectTokenSlackProps) {
    const loggedUser = localStorage.getItem('logged-user')

    const environment = ENV;
    let channel = "";
    switch (environment) {
        case "qa":
            channel = "#token-inactivation-qa"
            break;
        case "production":
            channel = "#token-inactivation"
            break;
        case "development":
            channel = "#token-inactivation-dev"
            break;
        case "demo":
            channel = "#token-inactivation-demo"
            break;
        default:
            channel = "#token-inactivation"
            break;
    }

    const body = {
        channel: channel,
        attachments: [
            {
                color: "#f39c12",
                blocks: [
                    {
                        type: "section",
                        text: {
                            type: "mrkdwn",
                            text: "*_Frontend_*",
                        },
                    },
                    {
                        type: "section",
                        text: {
                            type: "mrkdwn",
                            text: `*Environment: * ${environment.toUpperCase()}`,
                        },
                    },
                    {
                        type: "section",
                        text: {
                            type: "mrkdwn",
                            text: `O usuário ${loggedUser?.toUpperCase()} acabou de rejeitar o token de ${userRequestedName}`,
                        },
                    },
                    {
                        type: "context",
                        elements: [
                            {
                                type: "mrkdwn",
                                text: `*Token rejeitado: * ${tokenId}`,
                            },
                        ],
                    },
                ],
            },
        ],
    };

    return api.post(`/api/v1/slack-service/send-message`, JSON.stringify(body), {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    }).then((response) => {
        return;
    });
}