import moment from 'moment';
import 'moment/locale/pt-br';

export function test() {
	return null;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	// @ts-ignore
	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price: number) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const formatDate = (dateIn: string | null) => {
	const today = new Date();
	let dateOut = "";

	if (dateIn !== null) {
		moment.locale('pt-br');
		const dateInDateType = Date.parse(dateIn);

		let todayFormatted = moment(today).format('YYYY-MM-DD');
		let dateInFormatted = moment(dateInDateType).format('YYYY-MM-DD');

		dateOut = moment(dateInDateType).format('DD/MM/YYYY HH:mm:ss');

		if (dateInFormatted === todayFormatted) {
			dateOut = moment(dateInDateType).startOf('minute').fromNow();
		}
	}

	return dateOut
}

export const formatCpf = (document: string | null | undefined) => {
	if (document) {
		//retira os caracteres indesejados...
		document = document.replace(/[^\d]/g, "");
		
		const firstThreeNumbers = document.substring(0, 3)
		const secondThreeNumbers = document.substring(3, 6);
		const thirdThreeNumbers = document.substring(6, 9);
		const lastTwoNumbers = document.substring(9, 11)
		
		document = firstThreeNumbers.replace(/\d/g,"*") + '.' + secondThreeNumbers + '.' + thirdThreeNumbers + '-' + lastTwoNumbers.replace(/\d/g,"*");

		return document;
	}

	return "Documento não encontrado"
}

export const formatCpfClear = (document: string | null | undefined) => {
	if (document && document.length > 0) {
        document = document.replace(/\D/g, "")
    
        if (document.length <= 11) {
            document = document.replace(/(\d{3})(\d)/, "$1.$2")
            document = document.replace(/(\d{3})(\d)/, "$1.$2")
            document = document.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
        } else {
            document = document.substring(0, 14);
            document = document.replace(/^(\d{2})(\d)/, "$1.$2")
            document = document.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
            document = document.replace(/\.(\d{3})(\d)/, ".$1/$2")
            document = document.replace(/(\d{4})(\d)/, "$1-$2")
        }
		
		return document;
    }

    return "Documento não encontrado"
}

export const formatPhoneNumber = (phoneNumberString: string | null | undefined) => {
	if (phoneNumberString !== null && phoneNumberString !== undefined) {
		var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
		var match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
		if (match) {
			return '(' + match[1] + ') ' + match[2] + '-' + match[3];
		}
	}
	return "Telefone não encontrado";
}
export const phoneMask = (value: string) => {
	if (!value) return "Telefone não encontrado"
	value = value.substring(2, value.length)
	value = value.replace(/\D/g, '')
	value = value.replace(/(\d{2})(\d)/, "($1) $2")
	value = value.replace(/(\d)(\d{4})$/, "$1-$2")
	return value
}
export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const getFirstLetter = (text: string, letterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};
