import { useContext, useEffect, useState } from 'react';
import { USER_ROLES_LOWER } from '../../components/entrada-segura/token/token-activation-form/blocks/constants';
import { UserContext } from '../../contexts/user-context';
import { getUserHighestRole } from '../../services/token/token-service';

interface useUserHighestRoleParams {
	userId: number;
}

export function useUserHighestRole({
	userId,
}: useUserHighestRoleParams): string {
	const [userRole, setUserRole] = useState<string>('guest');
	const { user } = useContext(UserContext)

	useEffect(() => {
		const getUserInfo = async () => {
			if (user.isSuperUser) {
				setUserRole(USER_ROLES_LOWER.SYSADMIN)
			}
			else if (userId !== 0 && !user.isSuperUser) {
				const role = localStorage.getItem('highest-role');
				if (role)
					setUserRole(role);
			}
		};

		getUserInfo()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [userId, user.isSuperUser]);

	return userRole;
}
