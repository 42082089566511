import { useEffect, useState } from 'react';

import { getUserById } from '../../services/user/user-service';
import { User } from '../../type/user';

interface UseUserDataParams {
	userId: number;
}

export function useUserData({ userId }: UseUserDataParams): User | undefined {
	const [userData, setUserData] = useState<User>();

	useEffect(() => {
		const getUserInfo = async () => {
			if (userId !== 0) {
				const data = await getUserById(userId);

				setUserData(data);
			}
		};

		getUserInfo()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [userId]);

	return userData;
}
