import { useEffect, useState } from 'react';

import { getBuildingById } from '../../services/building/building-service';
import { Building } from '../../type/building';

interface UseBuildingDataParams {
	buildingId: number;
}

export function useBuildingData({ buildingId }: UseBuildingDataParams): Building | undefined {
	const [buildingData, setBuildingData] = useState<Building>();

	useEffect(() => {
		const getBuildingsData = async () => {
			const data = await getBuildingById(buildingId);
			setBuildingData(data);
		};

		getBuildingsData()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [buildingId]);

	return buildingData;
}
