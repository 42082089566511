import { useFormik } from 'formik';
import { useState } from 'react';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';

import Input from '../../../components/bootstrap/forms/Input';
import { resetPassword } from '../../../services/login/login-service';
import { getUserByTaxid, sendMessageToUser } from '../../../services/user/user-service';
import { InvalidCredentialsAlert } from './invalid-credentials-alert';
import Spinner from '../../../components/bootstrap/Spinner';
import showNotification from '../../../components/extras/showNotification';

interface ResetPasswordModalProps {
	setShowResetPasswordModal(...args: unknown[]): unknown | undefined;
	showResetPasswordModal: boolean;
}

export const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({
	setShowResetPasswordModal,
	showResetPasswordModal,
}) => {
	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [loading, setLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			username: '',
		},
		// validate: (values) => validateUserForm(values),

		onSubmit: async (values) => {
			try {
				await resetPassword({ username: values.username });
				showNotification('Senha resetada com sucesso!', 'Por favor, verifique seu WhatsApp.', 'sucess');
			} catch (e) {
				console.error(e);
				setShowErrorAlert(true);
			}
		},
	});

	return (
		<>
			<Modal
				isOpen={showResetPasswordModal}
				setIsOpen={setShowResetPasswordModal}
				titleId='reset-password-modal'>
				<ModalHeader setIsOpen={setShowResetPasswordModal}>
					<ModalTitle id='reset-password-title' className='d-flex align-items-end'>
						Resetar senha
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='d-flex align-items-end'>
						Uma nova senha será enviada para você pelo WhatsApp.
					</div>

					<form className='row g-4' onSubmit={formik.handleSubmit}>
						<div className='col-12'>
							<FormGroup id='username' isFloating label='Usuário' style={{ marginTop: '20px' }}>
								<Input
									type='text'
									required={true}
									onChange={formik.handleChange}
									value={formik.values.username}
								/>
							</FormGroup>
						</div>
					</form>
					{loading && <Spinner />}
				</ModalBody>
				<ModalFooter>
					<Button
						icon='Done'
						color='success'
						isLink
						onClick={async () => {
							setLoading(true);
							await formik.handleSubmit();
							setShowResetPasswordModal(false);
						}}>
						Enviar
					</Button>
				</ModalFooter>
			</Modal>
			{showErrorAlert && (
				<InvalidCredentialsAlert
					setShowErrorAlert={setShowErrorAlert}
					message='Erro ao enviar mensage. Verifique seus dados.'
				/>
			)}
		</>
	);
};
