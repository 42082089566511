export enum DocumentType {
    ID = "ID",
    DRIVER_LICENSE = "Driver License",
    BACKGROUND_CHECK = "Background Check",
}

export enum DocumentStatus {
    ACTIVE = "Active",
    INACTIVE = "Inactive",
}

export type Document = {
    id: number
    user_id: number
    document_type: DocumentType
    document_url: string
    document_source_url: string
    uploaded_at: string
    document_issued_at: string | null
    document_expires_at: string | null
    status: DocumentStatus
    created_at: string
    created_by: number
    modified_at: string
    modified_by: number
}
