import { useState } from 'react';
import classNames from 'classnames';
import 'react-circular-progressbar/dist/styles.css';
import Swal from 'sweetalert2';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import useDarkMode from '../../../../../hooks/useDarkMode';
import Button from '../../../../bootstrap/Button';
import DefaultImag from '../../files.png';
import {
  Container,
  FileInfo,
  Preview,
  SelfieInfo
} from './styles';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../bootstrap/Modal';

async function openDocumentModal(src: string, details?: string) {
  Swal.fire({
    title: 'Documento',
    width: '100%',
    html: `
      <img src="${src}" style="display: block !important; margin-left: auto !important; margin-right: auto !important; max-width: 100% !important; max-height: 100% !important;" />

      ${details ? `<div style="margin-top: 10px;">${details}</div>` : ''}
      `,
    showDenyButton: false,
    confirmButtonText: 'Ok',
    allowOutsideClick: true,
    backdrop: true
  })
}

interface FileListProps {
  files: any[];
  onDelete?: Function;
  isSelfie?: boolean;
  isReadOnly?: boolean;
}

export const FileList = ({ files, onDelete, isReadOnly = false, isSelfie = false }: FileListProps) => {
  const { darkModeStatus } = useDarkMode();

  const [shouldOpenModalFiles, setShouldOpenModalFiles] = useState<boolean>(false);
  const [urlDocument, setUrlDocument] = useState("");
  pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


  const getExtensionFile = (url: string) => {
    let inverseUrl = url.split('').reverse().join('');
    return inverseUrl.substring(0, inverseUrl.indexOf('.')).split('').reverse().join('');
  }

  const EXTENSIONS = [
    'pdf', 'txt', 'doc', 'docx', 'pptx', 'rar', 'zip'
  ]

  const getPreview = (url: string, extension: string) => {
    if (EXTENSIONS.includes(extension ? extension : getExtensionFile(url)))
      return DefaultImag
    return url;
  }

  function openModalDocuments(doc: string) {
    setShouldOpenModalFiles(true)
    setUrlDocument(doc)
  }
  
  return (
    <Container isSelfie={isSelfie}>
      {
        files.map((uploadedFile: any) => (
          uploadedFile.deleted === false && (
            <li style={{ display: 'flex', justifyContent: 'center' }} key={uploadedFile.id}>
              {
                isSelfie ?
                  (
                    <SelfieInfo src={uploadedFile.preview} />
                  )
                  :
                  (
                    <FileInfo>

                      <Preview src={getPreview(uploadedFile.preview, uploadedFile.extension)} />

                      <div style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        <div style={{ flexDirection: 'row' }}>
                          <div className="gap-2">
                            {!isReadOnly && !isSelfie &&
                              <Button
                                isOutline={!darkModeStatus}
                                color='dark'
                                isLight={darkModeStatus}
                                className={classNames('text-nowrap', {
                                  'border-light': !darkModeStatus,
                                })}
                                icon='DeleteForever'
                                title={"Excluir"}
                                type="button"
                                onClick={() => onDelete && onDelete(uploadedFile.id)}
                              />
                            }
                            {uploadedFile.url &&
                              <>
                                <Button
                                  isOutline={!darkModeStatus}
                                  color='dark'
                                  isLight={darkModeStatus}
                                  className={classNames('text-nowrap', {
                                    'border-light': !darkModeStatus,
                                  })}
                                  icon='CloudDownload'
                                  style={{ marginRight: '5px' }}
                                  title={"Baixar"}
                                  onClick={() => window.open(uploadedFile.url)}
                                >
                                </Button>
                                <Button
                                  isOutline={!darkModeStatus}
                                  color='dark'
                                  isLight={darkModeStatus}
                                  className={classNames('text-nowrap', {
                                    'border-light': !darkModeStatus,
                                  })}
                                  icon='RemoveRedEye'
                                  style={{ marginRight: '5px' }}
                                  title={"Visualizar"}
                                  onClick={() =>
                                    uploadedFile.extension === 'pdf' ?
                                    openModalDocuments(uploadedFile.preview)
                                    :
                                     openDocumentModal(
                                    uploadedFile.url, 
                                    (uploadedFile.issuedAt || uploadedFile.expiresAt) &&
                                      `${uploadedFile.issuedAt ? `Emitido em: ${uploadedFile.issuedAt.split('T')[0]}` : ""} ${uploadedFile.issuedAt && uploadedFile.expiresAt ? "-" : ""} ${
                                          uploadedFile.expiresAt ? `Expira em: ${uploadedFile.expiresAt.split('T')[0]}` : ""
                                      }`,
                                  )
                                }
                                >
                                </Button>
                              </>
                            }
                          </div>
                        </div>
                      </div>
                    </FileInfo>
                  )
              }
            </li>
          )
        ))
      }

      <Modal
        setIsOpen={setShouldOpenModalFiles}
        isOpen={shouldOpenModalFiles}
        titleId='modalDoc'
        size={'xl'}>
          <ModalHeader setIsOpen={setShouldOpenModalFiles}>
            <ModalTitle id='documento'>Documento</ModalTitle>
        </ModalHeader>
        <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Document
            file={urlDocument}
          >
            <Page pageNumber={1} />
          </Document>
        </ModalBody>
      </Modal>
    </Container >
  )
}