import { Fragment } from 'react';
import Card, { CardHeader, CardLabel, CardTitle } from '../../../../components/bootstrap/Card';

import { UserForm } from './user-form';

export const UserFormAdmin = () => {
	return (
		<Fragment>
			<div key={'User-form'} className={'col-12'}>
				<Card className='table-responsive' borderSize={3}>
					<CardHeader borderSize={2}>
						<CardLabel icon='PersonAdd' iconColor='warning'>
							<CardTitle>Criar User</CardTitle>
						</CardLabel>
					</CardHeader>
					<UserForm />
				</Card>
			</div>
		</Fragment>
	);
};
