import { useEffect, useState } from 'react';
import {
	getTokensCountByBuildingAndAuthType,
	getTokensCountByBuildingAndRole,
	getTokensCountByBuildingAndTokenStatus,
	getTokensCountInBuilding,
} from '../../../services/metrics/tokens/tokens-metrics-service';

interface UseTokensCountByBuildingIdParams {
	buildingId: number | undefined;
}

export function useTokensCountByBuildingId({
	buildingId,
}: UseTokensCountByBuildingIdParams): number | undefined {
	const [count, setCount] = useState<number>();

	useEffect(() => {
		if (buildingId) {
			const getBuildingsData = async () => {
				const data = await getTokensCountInBuilding(buildingId);
				setCount(data);
			};

			getBuildingsData()
				.then((data) => {
					return data;
				})
				.catch(console.error);
		}
	}, [buildingId]);

	return count;
}

interface UseTokensCountByBuildingIdAndTokenRoleParams {
	buildingId: number | undefined;
	tokenRole: string | undefined;
}

export function useTokensCountByBuildingIdAndTokenRole({
	buildingId,
	tokenRole,
}: UseTokensCountByBuildingIdAndTokenRoleParams): number | undefined {
	const [count, setCount] = useState<number>();

	useEffect(() => {
		if (buildingId && tokenRole) {
			const getBuildingsData = async () => {
				const data = await getTokensCountByBuildingAndRole({ buildingId, tokenRole });
				setCount(data);
			};

			getBuildingsData()
				.then((data) => {
					return data;
				})
				.catch(console.error);
		}
	}, [buildingId, tokenRole]);

	return count;
}

interface UseTokensCountByBuildingIdAndTokenAuthTypeParams {
	buildingId: number | undefined;
	tokenAuthType: string | undefined;
}

export function useTokensCountByBuildingIdAndTokenAuthType({
	buildingId,
	tokenAuthType,
}: UseTokensCountByBuildingIdAndTokenAuthTypeParams): number | undefined {
	const [count, setCount] = useState<number>();

	useEffect(() => {
		if (buildingId && tokenAuthType) {
			const getBuildingsData = async () => {
				const data = await getTokensCountByBuildingAndAuthType({ buildingId, tokenAuthType });
				setCount(data);
			};

			getBuildingsData()
				.then((data) => {
					return data;
				})
				.catch(console.error);
		}
	}, [buildingId, tokenAuthType]);

	return count;
}

interface UseTokensCountByBuildingIdAndTokenStatusParams {
	buildingId: number | undefined;
	tokenStatus: string | undefined;
}

export function useTokensCountByBuildingIdAndTokenStatus({
	buildingId,
	tokenStatus,
}: UseTokensCountByBuildingIdAndTokenStatusParams): number | undefined {
	const [count, setCount] = useState<number>();

	useEffect(() => {
		if (buildingId && tokenStatus) {
			const getBuildingsData = async () => {
				const data = await getTokensCountByBuildingAndTokenStatus({ buildingId, tokenStatus });
				setCount(data);
			};

			getBuildingsData()
				.then((data) => {
					return data;
				})
				.catch(console.error);
		}
	}, [buildingId, tokenStatus]);

	return count;
}
