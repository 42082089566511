import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import _get from 'lodash/get';
import classNames from 'classnames';

import { Token } from '../../../../type/token';
import Button, { ButtonGroup } from '../../../bootstrap/Button';
import useDarkMode from '../../../../hooks/useDarkMode';
import Avatar from '../../../Avatar';
import showNotification from '../../../extras/showNotification';

import Swal from 'sweetalert2';
import { getUserSelfieById } from '../../../../services/user/user-service';
import { createAccessLog } from "../../../../services/access-logs/access-logs-service";
import DefaultAvatar from '../../../../assets/img/wanna/wanna3.png';
import { TableColumn } from '../../consult-components/table-components/table-column';
import '../../../../styles/components/control/_control-row.scss';
import { GateList } from '../../../../type/gate';
import { UserContext } from '../../../../contexts/user-context';
import { gatGatesByBuildingId } from '../../../../services/gate/gate-service';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../bootstrap/Modal';
import Select from '../../../bootstrap/forms/Select';
import Option from '../../../bootstrap/Option';
import Popovers from '../../../bootstrap/Popovers';
import { useNavigate } from 'react-router-dom';
import { USER_ROLES_LOWER } from '../../token/token-activation-form/blocks/constants';
import { formatCpf } from '../../../../helpers/helpers';

async function openAvatarModal(avatar: string) {
	Swal.fire({
		title: 'Selfie do usuário',
		html:
			`<img src="${avatar}" style="display: block; !important; margin-left: auto !important; margin-right: auto !important; max-width: 300px !important; max-height: 500px !important;" />`,
		showDenyButton: false,
		confirmButtonText: 'Ok',
		allowOutsideClick: true,
		backdrop: true
	});
}

interface getAccessTypeTextParams {
	recurrent: boolean;
	authorizationType: string;
}
const getAccessTypeText = ({ recurrent, authorizationType }: getAccessTypeTextParams) => {
	if (recurrent) return 'Recorrente'

	switch (authorizationType) {
		case 'grant':
			return 'Grant';
		case 'invite':
			return 'Convite';
		case 'register':
			return 'Temporário';
		case 'checkin':
			return 'Check-in';
		default:
			return 'Não identificado';
	}
};

interface ControlRowTokensProps {
	token: Token;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ControlRowTokens: React.FC<ControlRowTokensProps> = ({
	token,
	setRefreshTable
}) => {
	const { darkModeStatus } = useDarkMode();
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const activeUserId = user.id;
	const userHighestRole = localStorage.getItem('highest-role');

	const [isOpenModal, setIsOpenModal] = useState(false);
	const [gates, setGates] = useState<GateList | null>(null);
	const [gate, setGate] = useState<number>(0);

	const issuedAt = _get(token, 'issuedAt');
	const humanizedIssuedAt = moment(issuedAt).format('DD/MM/YYYY');
	const expiresAt = _get(token, 'expiresAt');
	const humanizedExpiresAt = expiresAt ? moment(expiresAt).format('DD/MM/YYYY') : null;

	const userIssuedForId = _get(token, 'userRequested.id', '')
	const userIssuedForName = _get(token, 'userRequested.name', '')
	const document = _get(token, 'userRequested.taxid')
	const userIssuedByName = _get(token, 'userIssued.name', '')
	const authorizationType = _get(token, 'authorizationType')
	const recurrent = _get(token, 'recurrent')
	const birthdate = _get(token, 'userRequested.birthdate');

	const tokenType = authorizationType;

	const [avatar, setAvatar] = useState<string>(DefaultAvatar);

	useEffect(() => {
		getUserSelfieById(userIssuedForId, { setAvatar })
	}, [userIssuedForId]);

	useEffect(() => {
		const getGatesData = async () => {
			if (token) {
				const gatesData = await gatGatesByBuildingId(token.buildingId);

				setGates(gatesData);
			}
		}

		getGatesData()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [token]);

	const handleChangeGate = (gate: { target: { value: string; }; }) => {
		if (!validateGate(parseInt(gate.target.value))) {
			return showNotification(`Por favor, selecione um Gate para este convidado`, ``, 'danger');
		}

		const gateSelected = parseInt(gate.target.value);
		setGate(gateSelected);
	};

	function handleEditCadastro(userId: number) {
		navigate('/cadastro', {
			state: {
				userId: userId
			}
		});
	};

	function validateGate(idGate: number) {
		if (idGate)
			return true;

		return false
	};

	async function handleNextStep() {
		if (!validateGate(gate)) {
			return showNotification(`Por favor, selecione um Gate para este convidado`, ``, 'danger');
		};

		Swal.fire({
			title: `Avançar etapa`,
			html: `Deseja realmente avançar a etapa do usuário ${userIssuedForName.toUpperCase()}?`,
			customClass: {
				container: 'bg-dark',
				htmlContainer: 'text-left',
				confirmButton: 'bg-info',
			},
			showDenyButton: true,
			confirmButtonText: 'Continuar',
			allowOutsideClick: true,
			denyButtonText: `Cancelar`,
			backdrop: true,
		}).then(async (result) => {
			if (result.isConfirmed) {
				if (!validateGate(gate)) {
					return showNotification(`Por favor, selecione um Gate para este convidado`, ``, 'danger');
				};

				const createdBy = activeUserId;
				const createdAt = moment().toISOString(true);
				const onboardingAt = moment().toISOString(true);

				try {
					await createAccessLog({
						tokenId: token.id,
						buildingId: token.buildingId === null ? 0 : token.buildingId,
						spotId: token.spotId === null ? 0 : token.spotId,
						authorizationChain: token.authorizationChain.path,
						onboardingGateId: gate,
						onboardingAt: onboardingAt,
						conciergeUserId: createdBy,
						checkoutGateId: null,
						checkoutAt: null,
						rejectedAt: null,
						description: "",
						controlStatus: "Started",
						status: "Active",
						createdAt: createdAt,
						createdBy: createdBy,
						modifiedAt: null,
						modifiedBy: 0,
					});

					showNotification(`Etapa avançada com sucesso!`, ``, 'success');
				} catch (error) {
					showNotification(`Desculpe, não foi possível avançar a etapa desse usuário`, ``, 'danger');
				} finally {
					setRefreshTable(true);
				}
			}
		})
	};

	return (
		<>
			<tr key={token.id}>
				<TableColumn>
					<ButtonGroup>
						<Button
							isOutline={!darkModeStatus}
							isDisable={userHighestRole === USER_ROLES_LOWER.CONCIERGE}
							color='dark'
							isLight={darkModeStatus}
							className={classNames('text-nowrap', {
								'border-light': !darkModeStatus,
							})}
							icon='Edit'
							style={{ marginRight: '5px' }}
							title={"Editar cadastro"}
							onClick={() => handleEditCadastro(userIssuedForId)}
						/>
						<Button
							isOutline={!darkModeStatus}
							color='dark'
							isLight={darkModeStatus}
							className={classNames('text-nowrap', {
								'border-light': !darkModeStatus,
							})}
							icon='FastForward'
							style={{ marginRight: '5px' }}
							title={"Avançar etapa"}
							onClick={() => setIsOpenModal(true)}
						/>
					</ButtonGroup>
				</TableColumn>
				<TableColumn>
					<small
						className={`border borderActive border-2 fw-bold px-2 py-1 rounded-1`}>
						Autorizado
					</small>
				</TableColumn>
				<TableColumn>
					{userIssuedForId && <Avatar title={"Clique para visualizar a selfie"} style={{ cursor: 'pointer' }} onClick={() => openAvatarModal(avatar)} src={avatar} srcSet={avatar} color={'success'} size={40} />}
					{birthdate === null ?
						<span style={{ color: '#E74C3C', fontWeight: 'bold' }} className='ms-3 d-flex align-items-center text-nowrap'>
							<Popovers trigger='hover' desc='Faltam algumas informações deste usuário. Favor, verificar o quanto antes!'>
								{`${userIssuedForName} | CPF: ${formatCpf(document)}`}
							</Popovers>
						</span>
						:
						<span className='ms-3 d-flex align-items-center text-nowrap'>
							{`${userIssuedForName} | CPF: ${formatCpf(document)}`}
						</span>
					}
				</TableColumn>
				<TableColumn>{userIssuedByName}</TableColumn>
				<TableColumn>{tokenType && getAccessTypeText({ recurrent, authorizationType: tokenType })}</TableColumn>
				<TableColumn>{humanizedIssuedAt}</TableColumn>
				<TableColumn>{humanizedExpiresAt ? humanizedExpiresAt : '-'}</TableColumn>
			</tr>
			<Modal
				setIsOpen={setIsOpenModal}
				isOpen={isOpenModal}
				fullScreen={'lg'}
				isCentered
				titleId='avancar-etapa-modal'>
				<ModalHeader setIsOpen={setIsOpenModal}>
					<ModalTitle id='avancar-etapa-modal'>Avançar etapa</ModalTitle>
				</ModalHeader>
				<ModalBody className='h-100 d-flex align-items-center justify-content-center flex-column'>
					<div className='row w-100 d-flex align-items-center justify-content-center flex-column'>
						<h6>
							Você está avançando a etapa do usuário {userIssuedForName}
						</h6>
						<p>Por favor, informe um gate!</p>
						<Select
							ariaLabel='Board select'
							placeholder='Selecione o Gate'
							onChange={handleChangeGate}>
							{gates?.map((gate) => (
								<Option key={gate.id} value={gate.id}>
									{gate.name}
								</Option>
							))}
						</Select>
						<Button
							onClick={() => handleNextStep()}
							className="btn btn-primary mt-4">
							Continuar
						</Button>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};