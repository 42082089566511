import moment from "moment";
import { useEffect, useState } from "react";
import { USER_ROLES_LOWER } from "../../components/entrada-segura/token/token-activation-form/blocks/constants";
import { getTokenAccessLogsCards, getTokenAccessLogsCardsByBuildingId } from "../../services/access-logs/access-logs-service";
import { getAllTokensByTodayDateAuthorizationTypes, getTokensByTodayDateAuthorizationTypes, getUserGrantTokens } from "../../services/token/token-service";
import { AccessLog } from "../../type/access-log";
import { TokenList } from "../../type/token";

interface UseControlProps {
    page: number;
    perPage: number;
    refreshTable: boolean;
    superUser: boolean;
    userId: number;
}
export function useControl({ page, perPage, refreshTable, superUser, userId }: UseControlProps) {
    const [cardStarted, setCardStarted] = useState<number>();
    const [cardConcluded, setCardConcluded] = useState<number>();
    const [cardNew, setCardNew] = useState<number>();
    const [tokens, setTokens] = useState<TokenList>();
    const [accessLogsStarted, setAccessLogsStarted] = useState<AccessLog[]>();
    const [accessLogsConcluded, setAccessLogsConcluded] = useState<AccessLog[]>();
    const authorizationTypes = ["checkin"];
    const todayDate = new Date();
    const today = moment(todayDate).format("YYYY-MM-DD");

    useEffect(() => {
        const getActiveTokensByTodayDateAuthorizationTypes = async () => {
            const tokensGrantLoggedUser = await getUserGrantTokens(userId)

            let _buildingId = tokensGrantLoggedUser.results.find(token => token)!.buildingId;

            if (authorizationTypes) {
                const tokensPerPage = await getTokensByTodayDateAuthorizationTypes({ authorizationTypes, today, page, perPage, superUser, buildingId: _buildingId });

                setTokens(tokensPerPage);
            }
        }

        getActiveTokensByTodayDateAuthorizationTypes()
            .then((data) => {
                return data;
            })
            .catch(console.error);
    }, [page, perPage, refreshTable])

    useEffect(() => {
        const getTokenAccessLogsData = async () => {
            if (!superUser) {
                const tokensGrantLoggedUser = await getUserGrantTokens(userId)

                let _buildingId = tokensGrantLoggedUser.results.find(token => token)!.buildingId;

                const allAccessLogs = await getTokenAccessLogsCardsByBuildingId(_buildingId);

                if (allAccessLogs) {
                    const resultAccessLogsStarted = allAccessLogs.filter((accessLog) => accessLog.controlStatus === "Started");
                    const resultAccessLogsConcluded = allAccessLogs.filter((accessLog) => accessLog.controlStatus === "Concluded");
                    setAccessLogsStarted(resultAccessLogsStarted);
                    setAccessLogsConcluded(resultAccessLogsConcluded);
                    setCardStarted(resultAccessLogsStarted.length);
                    setCardConcluded(resultAccessLogsConcluded.length);
                }
            } else {
                const allAccessLogs = await getTokenAccessLogsCards();
                if (allAccessLogs) {
                    const resultAccessLogsStarted = allAccessLogs.filter((accessLog) => accessLog.controlStatus === "Started");
                    const resultAccessLogsConcluded = allAccessLogs.filter((accessLog) => accessLog.controlStatus === "Concluded");
                    setAccessLogsStarted(resultAccessLogsStarted);
                    setAccessLogsConcluded(resultAccessLogsConcluded);
                    setCardStarted(resultAccessLogsStarted.length);
                    setCardConcluded(resultAccessLogsConcluded.length);
                }
            }
        }

        getTokenAccessLogsData()
            .then((data) => {
                return data;
            })
            .catch(console.error);
    }, [refreshTable])

    useEffect(() => {
        const getAllTokenAccessLogData = async () => {
            const tokensGrantLoggedUser = await getUserGrantTokens(userId)

            let _buildingId = tokensGrantLoggedUser.results.find(token => token)!.buildingId;

            const allTokens = await getAllTokensByTodayDateAuthorizationTypes({ authorizationTypes, today, page, perPage: 10000, superUser, buildingId: _buildingId });

            if (allTokens) {
                const tokensNew = allTokens.results.length;
                setCardNew(tokensNew);
            };
        };

        getAllTokenAccessLogData()
            .then((data) => {
                return data;
            })
            .catch(console.error);
    }, [refreshTable])

    return { cardStarted, cardConcluded, cardNew, tokens, accessLogsStarted, accessLogsConcluded }
}