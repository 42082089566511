import React from 'react';
import Header from '../../../layout/Header/Header';
import { appMenu } from '../../../menu';
import Navigation from '../../../layout/Navigation/Navigation';
import { PendingActionsProvider } from '../../../contexts/pendingActionsContext';

export const GeneralHeader = () => {
	return (
		<Header>
			{/* @ts-ignore */}
			<PendingActionsProvider>
				<Navigation menu={appMenu} id={'app-menu'} horizontal={true} />
			</PendingActionsProvider>
		</Header>
	);
};
