import React, { useContext, useEffect, useState } from 'react';
import _isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';

import useDarkMode from '../../../hooks/useDarkMode';

import Card from '../../../components/bootstrap/Card';
import { CardBody } from '../../../components/bootstrap/Card';
import { TokenManagerHeadline } from '../spot/spot-card';
import { getTokensIssuedForUserThatHaveGrantAccessAsSpotList } from '../../../services/token/token-service';
import { SpotList } from '../../../type/spot';
import { TokenManager } from '../token/token-manager';

import { getUniqueSpotList } from '../spot/utils/get-unique-spots-from-tokens';
import { UserContext } from '../../../contexts/user-context';

import './user-spots-list.css';
import { BuildingList } from '../../../type/building';
import { getBuildingList } from '../../../services/building/building-service';

export const UserSpotsList = () => {
	const { darkModeStatus } = useDarkMode();
	const { user } = useContext(UserContext);
	const activeUserId = user.id;

	const [userSpotsList, setUserSpotsList] = useState<SpotList>([]);
	const [buildingList, setBuildingList] = useState<BuildingList>([]);

	useEffect(() => {
		const getUserTokens = async () => {
			if (user.id && !user.isSuperUser) {
				const spotsList = await getTokensIssuedForUserThatHaveGrantAccessAsSpotList(user.id);

				const uniqueSpotsList = getUniqueSpotList(spotsList);
				const spotsToSet = uniqueSpotsList.filter((spot) => spot.buildingId !== null);

				setUserSpotsList(spotsToSet);
			} else if (user.isSuperUser) {
				const buildings = await getBuildingList();
				setBuildingList(buildings);
			}
		};

		getUserTokens()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [user.id, user.isSuperUser]);


	if (user.isSuperUser) {
		return (
			<div className='user-spots-list' style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'row',
				gap: '3.2rem'
			}}>
				{buildingList.map(({ id }, index) => {
					return (
						<Card
							key={`card-user-${activeUserId}-spot-${id}-building-${id}-card-${index}`}
							className={classNames('user-spots-list__card', {
								'user-spots-list__card bg-dark': darkModeStatus,
							})}>
							<CardBody key={`card-user-${activeUserId}-spot-${id}-building-${id}-${index}`}>
								<TokenManagerHeadline
									key={`user-${activeUserId}-spot-${id}-building-${id}-${index}`}
									buildingId={id}
								/>
								<TokenManager
									key={`card-user-${activeUserId}-spot-${id}-building-${id}-token-manager-${index}`}
									role={'sys_admin'}
									buildingId={id}
									userId={activeUserId}
								/>
							</CardBody>
						</Card>
					);
				})}
			</div>
		)
	}

	if (_isEmpty(userSpotsList) || activeUserId === null) {
		return <CardBody className='user-spots-list__empty-spots-list'>Usuário sem tokens</CardBody>;
	}

	return (
		<div className='user-spots-list' style={{
			display: 'flex',
			justifyContent: 'center',
			flexDirection: 'row',
			gap: '3.2rem'
		}}>
			{userSpotsList.map(({ id, buildingId, role }, index) => {
				return (
					<Card
						key={`card-user-${activeUserId}-spot-${id}-building-${buildingId}-card-${index}`}
						className={classNames('user-spots-list__card', {
							'user-spots-list__card bg-dark': darkModeStatus,
						})}>
						<CardBody style={{
							height: '325px',
							display: 'flex',
							justifyContent: 'space-evenly',
							flexDirection: 'column'
						}} key={`card-user-${activeUserId}-spot-${id}-building-${buildingId}-${index}`}>
							<TokenManagerHeadline
								key={`user-${activeUserId}-spot-${id}-building-${buildingId}-${index}`}
								id={id}
								buildingId={buildingId}
							/>
							<TokenManager
								key={`card-user-${activeUserId}-spot-${id}-building-${buildingId}-token-manager-${index}`}
								role={role as string}
								spotId={id}
								buildingId={buildingId}
								userId={activeUserId}
							/>
						</CardBody>
					</Card>
				);
			})}
		</div>
	);
};
