import { useEffect, useState } from 'react';

import _get from 'lodash/get';

import { getSpotById } from '../../services/spot/spot-service';

interface UseSpotDataParams {
	spotId?: number | null;
}

export function useSpotName({ spotId }: UseSpotDataParams): string | undefined {
	const [spotName, setSpotName] = useState<string>();

	useEffect(() => {
		const getSpotData = async () => {
      if (spotId) {
        const localStorageSpotName = localStorage.getItem(`spot-${spotId}-name`);
        localStorageSpotName && setSpotName(localStorageSpotName);
  
        if (spotId && !localStorageSpotName) {
          const spot = await getSpotById(spotId);
          const name = _get(spot, 'name');
  
          name && setSpotName(name);
          name && localStorage.setItem(`spot-${spotId}-name`, name);
        }
      }
		};

		getSpotData()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [spotId, spotName]);

	return spotName;
}
