import { BlockList } from '../../type/Block';
import { Building, BuildingList } from '../../type/building';
import api from '../api';
import { getUserGrantTokens } from '../token/token-service';

import { HTTPBuildingListResponse, HTTPBuildingResponse } from './http-building-response';
import { parseBuilding } from './parsers/building-parser';

export async function getBuildingById(buildingId: number): Promise<Building> {
	if (buildingId !== null) {
		return api.get(`/api/v1/buildings/${buildingId}`, {
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
			}
		}).then((response) => {
			const responseJson = response.data as HTTPBuildingResponse;
	
			return parseBuilding(responseJson);
		});
	}

	return {} as Building;
}

export async function getBlocksByBuildingId(buildingId: number): Promise<BlockList> {
	return api.get(`/api/v1/buildings/${buildingId}/blocks`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		return response.data;
	});
}

export async function getBuildingList(): Promise<BuildingList> {
	return api.get(`/api/v1/buildings`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPBuildingListResponse;

		return responseJson.results.map(parseBuilding);
	});
}

export async function getBuildingId(userId: number): Promise<number> {
	const tokensGrantLoggedUser = await getUserGrantTokens(userId);
	return tokensGrantLoggedUser.results.find(token => token)!.buildingId;
}

interface CreateBuildingParams {
	name: string;
	taxid?: string;
	businessName: string;
	logo: string;
	buildingType?: string;
	address: string;
	addressNumber: string;
	addressComplement?: string | null;
	neighborhood: string;
	city: string;
	state: string;
	zipcode: string;
	status?: string;
	createdAt: string;
	createdBy: number;
}
export async function createBuilding({
	name,
	taxid,
	logo,
	buildingType,
	businessName,
	address,
	addressNumber,
	addressComplement,
	neighborhood,
	city,
	state,
	zipcode,
	status,
	createdAt,
	createdBy,
}: CreateBuildingParams): Promise<Building> {
	const body = {
		name,
		logo: 'http://logo.com',
		address,
		business_name: businessName,
		address_number: addressNumber,
		address_complement: addressComplement,
		building_type: buildingType,
		neighborhood,
		city,
		state,
		zipcode,
		taxid,
		status,
		created_at: createdAt,
		created_by: createdBy,
	};

	return api.post(`/api/v1/buildings/`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPBuildingResponse;

		return parseBuilding(responseJson);
	});
}