import { InquiryComponents } from "../../../components/entrada-segura/inquiry/inquiry-components";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";

export function InquiryView() {
    return (
        <>
            <PageWrapper title={'Avaliação'}>
                <Page container='fluid'>
                    <InquiryComponents />
                </Page>
            </PageWrapper>
        </>
    )
}