import React, { Fragment, useEffect, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';

import showNotification from '../../../components/extras/showNotification';

import Button from '../../../components/bootstrap/Button';

import { createToken } from '../../../services/token/token-service';

import _get from 'lodash/get';

import { TokenSharingModal } from './token-sharing-modal';
import { AUTH_TYPES } from './token-activation-form/blocks/constants';
import { getBuildingById } from '../../../services/building/building-service';

interface TokenManagerProps {
  userId: number;
  spotId?: number;
  buildingId: number;
  role: string;
}
export const TokenManager: React.FC<TokenManagerProps> = ({ spotId, buildingId, userId, role }) => {
  const [whatsAppLink, setWhatsAppLink] = useState('');

  const [showTokenSharingModal, setShowTokenSharingModal] = useState<boolean>(false);
  const [buttonText, setButtonText] = useState('Gerar Convite');
  const [buttonActive, setButtonActive] = useState<boolean>(false);
  const [buildingName, setBuildingName] = useState<string | undefined>('');

  useEffect(() => {
    const getBuildingData = async () => {
      const building = await getBuildingById(buildingId);
      const name = _get(building, 'name');
      setBuildingName(name);
    }

    getBuildingData()
			.then((data) => {
				return data;
			})
			.catch(console.error);
  }, [buildingId])

  const formik = useFormik({
    initialValues: {
      tokenHeadline: '',
      tokenAccessCount: 1,
    },

    onSubmit: async (values) => {
      const expiresAt = moment().add(1, 'day').toISOString();
      const issuedAt = moment().toISOString();
      setButtonText('Carregando...');
      setButtonActive(true);

      try {
        const tokenData = await createToken({
          reasonAccess: null,
          spotId: spotId ? spotId : null,
          buildingId,
          issuedBy: userId,
          issuedAt,
          authorizationType: AUTH_TYPES.INVITE,
          expiresAt,
          expiresCount: values.tokenAccessCount,
          role,
          subject: values.tokenHeadline,
          createdAt: issuedAt,
          createdBy: userId,
        });

        const whatsAppShareLink = tokenData.whastappURL || ''
        setWhatsAppLink(whatsAppShareLink)

        showNotification(
          `Geração de token`,
          `Token do condomínio ${buildingName} gerado com sucesso. Visite o menu Ativar!`,
          'success',
        );

        setShowTokenSharingModal(true);
        setButtonText('Gerar Convite');
        setButtonActive(false);
      } catch (error) {
        showNotification(
          `Erro ao gerar Token`,
          `Desculpe, houve um erro ao gerar o Token.`,
          'danger',
        );
        setButtonText('Gerar Convite');
        setButtonActive(false);
      }
    }
  })

  return (
    <Fragment>
      <div className='d-flex mt-3'>
        <Button
          isDisable={buttonActive}
          className='btn btn-success w-100'
          id='create-token-button'
          onClick={() => {
            formik.handleSubmit()
          }}>
          {buttonText}
        </Button>
      </div>
      {showTokenSharingModal && (
        <TokenSharingModal
          whatsAppLink={whatsAppLink}
          setShowTokenSharingModal={setShowTokenSharingModal}
          showTokenSharingModal={showTokenSharingModal}
        />
      )}
    </Fragment>
  );
};