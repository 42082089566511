import React, { useEffect, useState } from "react";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../../../components/bootstrap/Modal";
import { FileUploader } from "react-drag-drop-files";
import { Upload } from "../../../../../components/entrada-segura/Upload";
import { getDocument } from "../../services/register.view.service";

interface ModalFilesProps {
    userId?: number;
    setShouldOpenModalFiles: any;
    shouldOpenModalFiles: any;
}
export const ModalFiles = ({ userId, setShouldOpenModalFiles, shouldOpenModalFiles }: ModalFilesProps) => {
    const [files, setFiles] = useState<any>([]);

    useEffect(() => {
        if (shouldOpenModalFiles)
            getAnexos();
    }, [shouldOpenModalFiles])

    const handleUpload = () => {

    }

    const getExtensionFile = (url: string) => {
        let inverseUrl = url.split('').reverse().join('');
        return inverseUrl.substring(0, inverseUrl.indexOf('.')).split('').reverse().join('');

    }

    const getFileName = (url: string) => {
        let inverseUrl = url.split('').reverse().join('');
        return inverseUrl.substring(0, inverseUrl.indexOf('/')).split('').reverse().join('');
    }

    const getAnexos = async () => {
        let anexos = await getDocument(userId);
        if (anexos) {
            const anexosList: any[] = [];
            anexos.results.map((item: any) => {
                const dados = {
                    id: item.id,
                    url: item.document_source_url,
                    preview: item.document_source_url,
                    uploaded: true,
                    name: getFileName(item.document_source_url),
                    extension: getExtensionFile(item.document_source_url),
                    deleted: false,
                }
                anexosList.push(dados);
            })
            setFiles(anexosList);
        }
    }
    return (
        <Modal
            setIsOpen={setShouldOpenModalFiles}
            isOpen={shouldOpenModalFiles}
            titleId='modalFiles'
            isBodyScroll
            isStaticBackdrop
            isCentered
            size={'lg'}>
            <ModalHeader setIsOpen={setShouldOpenModalFiles}>
                <ModalTitle id='editUser'>Arquivos Anexados</ModalTitle>
            </ModalHeader>
            <ModalBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {
                    files.length > 0 ?
                        <Upload type='file' multiple={true} files={files} onUpload={handleUpload} isReadOnly userId={userId!}/>
                        :
                        <div className="d-flex justify-center"><p> Nenhum Arquivo Encontrado</p></div>
                }
            </ModalBody>
        </Modal>
    );
}