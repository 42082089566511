export enum AppRoute {
	Login = '/login',
	Admin = '/admin',
	Metrics = '/metricas',
	Activate = '/ativar',
	Dashboard = '/dashboard',
	Consult = '/consultar',
	Home = '/',
	Onboarding = '/onboarding',	
	Register = '/cadastro',
	Controle = '/controle',
	Qualificar = "/avaliacao",
}
