import { useEffect, useState } from 'react';

import _get from 'lodash/get';

import { getUserById } from '../../services/user/user-service';

interface UseUserNameParams {
	userId?: number;
}

export function useUserName({ userId }: UseUserNameParams): string | undefined {
	const [userName, setUserName] = useState<string>();

	useEffect(() => {
		const getUserInfo = async () => {
			if (userId) {
				const userData = await getUserById(userId);
				const name = _get(userData, 'name');

				name && setUserName(name);
				name && localStorage.setItem(`user-${userId}-name`, name);
			}
		};

		getUserInfo()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [userId, userName]);

	return userName;
}
