import { BoardingPass } from '../../../type/boarding-pass';
import { HTTPBoardingPassResponse } from '../http-boarding-pass-response';

export function parseBoardingPass(httpBuilding: HTTPBoardingPassResponse): BoardingPass {
	return {
		id: httpBuilding.id,
		tokenId: httpBuilding.token_id,
		backgroundColor: httpBuilding.background_color,
		qrCodeBackgroundColor: httpBuilding.qr_code_background_color,
		qrCodeFillColor: httpBuilding.qr_code_fill_color,
		qrCodeScale: httpBuilding.qr_code_scale,
		s3Path: httpBuilding.s3_path,
		uploadedAt: httpBuilding.uploaded_at,
		status: httpBuilding.status,
		createdAt: httpBuilding.created_at,
		createdBy: httpBuilding.created_by,
		modifiedAt: httpBuilding.modified_at,
		modifiedBy: httpBuilding.modified_by,
		link: httpBuilding.link,
	};
}
