import { Spot } from '../../../type/spot';
import { HTTPTokenResponse } from '../http-token-response';

export function parseTokenToSpotsList(httpToken: HTTPTokenResponse): Spot {
	return {
		id: httpToken.spot_id,
		buildingId: httpToken.building_id,
		role: httpToken.role,
		name: ""
	};
}
