import React from 'react';
import _isEmpty from 'lodash/isEmpty';

import { CardBody } from '../../../bootstrap/Card';

import { Token } from '../../../../type/token';
import { TableColumn } from '../../consult-components/table-components/table-column';
import { ControlRowTokens } from './control-token-row';

interface ControlTableTokensProps {
	tokens: Array<Token> | undefined;
	isFluid: any;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ControlTableTokens: React.FC<ControlTableTokensProps> = ({
	tokens,
	isFluid,
	setRefreshTable
}) => {
	if (_isEmpty(tokens)) return (
        <CardBody className='table-responsive' isScrollable={isFluid} style={{ height: '40rem' }}>
            <table className='table table-modern table-responsive'>
                <thead>
                    <tr key={'user-token-tables-names'}>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Ações</TableColumn>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Status</TableColumn>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Usuário</TableColumn>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Data</TableColumn>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Tipo de acesso</TableColumn>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{textAlign: 'center'}}>
                        <td colSpan={5}>Nenhum token ativado hoje ainda.</td>
                    </tr>
                </tbody>
            </table>
        </CardBody>
    );

	return (
		<CardBody className='table-responsive' isScrollable={isFluid} style={{ height: '40rem' }}>
			<table className='table table-modern table-responsive'>
				<thead>
					<tr key={'user-token-tables-names'}>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Ações</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Status</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Usuário</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Autorizado por</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Tipo de acesso</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Data início</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Data fim</TableColumn>
					</tr>
				</thead>
				<tbody>
					{tokens &&
						tokens.map((token) => {
							return (
								<ControlRowTokens
									key={`token-row-${token.id}`}
									token={token}
									setRefreshTable={setRefreshTable}
								/>
							);
						})}
				</tbody>
			</table>
		</CardBody>
	);
};