import React, { useEffect } from 'react';
import _isEmpty from 'lodash/isEmpty';

import { CardBody } from '../../../bootstrap/Card';

import { Token } from '../../../../type/token';

import { TableColumn } from '../table-components/table-column';
import { TokenRow } from './token-row';

export const getUniqueInviteTokens = (tokens: Array<Token>) => {
	const uniqueList: Array<Token> = [];

	for (let i = 0; i < tokens.length; i += 1) {
		if (
			!uniqueList.some((token) => token.id === tokens[i].id && token.authorizationType === 'invite')
		) {
			uniqueList.push(tokens[i]);
		}
	}
	return uniqueList;
};

interface TokensTableProps {
	tokens: Array<Token> | undefined;
	isFluid: any;
	handleUpcomingEdit: any;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
	setShouldOpenEditUserForm: React.Dispatch<React.SetStateAction<boolean>>;
	setShouldOpenSeeAccess: React.Dispatch<React.SetStateAction<boolean>>;
	setUserToEdit: React.Dispatch<React.SetStateAction<number>>;
	setTokenToEdit: React.Dispatch<React.SetStateAction<Token | null>>;
	setShouldOpenEditTokenForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TokensTable: React.FC<TokensTableProps> = ({
	tokens,
	isFluid,
	handleUpcomingEdit,
	setRefreshTable,
	setShouldOpenEditUserForm,
	setShouldOpenSeeAccess,
	setUserToEdit,
	setShouldOpenEditTokenForm,
	setTokenToEdit
}) => {
	if (_isEmpty(tokens)) return (
		<CardBody className='table-responsive' isScrollable={isFluid} style={{ height: '40rem' }}>
			<table className='table table-modern table-responsive'>
				<thead>
					<tr key={'user-token-tables-names'}>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Ações</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Situação acesso</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Nome</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Autorizado por</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Motivo</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Data início</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Data fim</TableColumn>
					</tr>
				</thead>
				<tbody>
					<tr style={{ textAlign: 'center' }}>
						<td colSpan={7}>Nenhum acesso realizado ainda.</td>
					</tr>
				</tbody>
			</table>
		</CardBody>
	);

	return (
		<CardBody className='table-responsive' isScrollable={isFluid} style={{ height: '40rem' }}>
			<table className='table table-modern table-responsive'>
				<thead>
					<tr key={'user-token-tables-names'}>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Ações</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Situação acesso</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Nome</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Autorizado por</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Motivo</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Data início</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Data fim</TableColumn>
					</tr>
				</thead>
				<tbody>
					{tokens &&
						tokens.map((token) => {
							return (
								<TokenRow
									otherTokens={tokens}
									setShouldOpenEditUserForm={setShouldOpenEditUserForm}
									setShouldOpenSeeAccess={setShouldOpenSeeAccess}
									setUserToEdit={setUserToEdit}
									key={`token-row-${token.id}`}
									token={token}
									setRefreshTable={setRefreshTable}
									handleUpcomingEdit={handleUpcomingEdit}
									setShouldOpenEditTokenForm={setShouldOpenEditTokenForm}
									setTokenToEdit={setTokenToEdit}
								/>
							);
						})}
				</tbody>
			</table>
		</CardBody>
	);
};
