import React, { Fragment } from 'react';

import Modal, { ModalHeader, ModalBody } from '../../bootstrap/Modal';
import Button from '../../bootstrap/Button';

import showNotification from '../../extras/showNotification';

interface TokenSharingModalProps {
	setShowTokenSharingModal: React.Dispatch<React.SetStateAction<boolean>>;
	showTokenSharingModal: boolean;
	whatsAppLink: string;
}
export const TokenSharingModal: React.FC<TokenSharingModalProps> = ({
	setShowTokenSharingModal,
	showTokenSharingModal = false,
	whatsAppLink
}) => {

	return (
		<Fragment>
			<Modal
				setIsOpen={setShowTokenSharingModal}
				isOpen={showTokenSharingModal}
				fullScreen={false}
				isCentered
				titleId='transfer-modal'>
				<ModalHeader className='w-100 d-flex justify-content-center align-items-center' setIsOpen={setShowTokenSharingModal}>
					
				</ModalHeader>
				<ModalBody className='h-100 d-flex align-items-center'>
					<div className='row w-100'>
						<div className='d-flex justify-content-center align-items-center flex-column gap-3'>
						<h4>Compartilhar Token</h4>
							<div className='text-center'>
								<p>Copie o link através do botão abaixo e envie para seus convidados e prestadores de serviço!</p>
							</div>
							<Button
								className={`btn btn-primary w-50`}
								onClick={() => {
									navigator.clipboard.writeText(whatsAppLink);
									showNotification(`Token Copiado com sucesso!`, ``, 'success');
								}}
								icon='CopyAll'								
							>
								Copiar Link
							</Button>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</Fragment>
	);
};