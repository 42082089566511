import { Spot, SpotList } from '../../type/spot';
import api from '../api';

import { HTTPSpotListResponse, HTTPSpotResponse } from './http-spot-response';
import { parseSpot } from './parsers/spot-parser';

export async function getSpotById(spotId: number): Promise<Spot> {
	return api.get(`/api/v1/spots/${spotId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPSpotResponse;

		return parseSpot(responseJson);
	});
}

export async function getBuildingSpotsList(buildingId: number): Promise<SpotList> {
	return api.get(`/api/v1/spots/building_id/${buildingId}?max_results=10000`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPSpotListResponse;

		return responseJson.results.map(parseSpot);
	});
}

export async function getSpotsList(): Promise<SpotList> {
	return api.get(`/api/v1/spots`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPSpotListResponse;

		return responseJson.results.map(parseSpot);
	});
}

export async function getSpotByType(spotType: string, buildingId: number): Promise<SpotList> {
	return api.get(`/api/v1/spots/spot_type/${spotType}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPSpotListResponse;

		const spots = responseJson.results.map(parseSpot);

		return spots.filter((spot) => spot.buildingId === buildingId);
	});
}

interface CreateSpotInterface {
	name?: string | null;
	buildingId: number;
	spotType?: string | null;
	spotLogo?: string | null;
	status?: string | null;
	createdBy: number;
	createdAt: string;
}
export async function createSpot({
	name,
	buildingId,
	spotType,
	spotLogo,
	status,
	createdBy,
	createdAt,
}: CreateSpotInterface): Promise<Spot> {
	const body = {
		name,
		building_id: buildingId,
		spot_type: spotType,
		spot_logo: spotLogo,
		status,
		created_by: createdBy,
		created_at: createdAt,
	};

	return api.post(`/api/v1/spots/`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPSpotResponse;

		return parseSpot(responseJson);
	});
}