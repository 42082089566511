import React, { useState } from 'react';
import Tracker from '@openreplay/tracker'
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useFormik } from 'formik';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import LogoEntradaSegura from '../../../assets/img/utilities/logo-entrada-segura-adminlte.png';

import useDarkMode from '../../../hooks/useDarkMode';
import { login } from '../../../services/login/login-service';
import { AppRoute } from '../../../App/routes';
import { InvalidCredentialsAlert } from './invalid-credentials-alert';
import { ResetPasswordModal } from './reset-password-modal';

interface LoginHeaderProps {
	isNewUser: boolean;
}

// eslint-disable-next-line react/prop-types
const LoginHeader: React.FC<LoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h2 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h5 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h2 fw-bold mt-5'>Bem-vindo!</div>
			<div className='text-center h5 text-muted mb-5'>Faça o seu login para continuar</div>
		</>
	);
};

interface LoginProps {
	isSignUp?: boolean;
	tracker?: Tracker;
}

export const LoginView: React.FC<LoginProps> = ({ isSignUp = false, tracker }) => {
	const { darkModeStatus } = useDarkMode();

	const [showErrorAlert, setShowErrorAlert] = useState(false);
	const [showResetPasswordModal, setShowResetPasswordModal] = useState<boolean>(false);
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			userName: '',
			password: '',
		},
		// validate: (values) => validateUserForm(values),

		onSubmit: async (values) => {
			// localStorage.clear('active-token');
			try {
				tracker?.setUserID(values.userName);
				
				await login({ username: values.userName, password: values.password });
				
				navigate(AppRoute.Home);
			} catch (e) {
				console.error(e);
				setShowErrorAlert(true);
			}
		},
	});

	return (
		<PageWrapper title={'Login'} className={'bg-dark'}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<CardBody>
							<div className='text-center my-5'>
								<img src={LogoEntradaSegura} className='img-fluid' alt='Entrada Segura' />
							</div>
							<div
								className={classNames('rounded-3', {
									'bg-l10-dark': !darkModeStatus,
									'bg-lo10-dark': darkModeStatus,
								})}
							/>

							<LoginHeader isNewUser={false} />

							<form className='row g-4' onSubmit={formik.handleSubmit}>
								<div className='col-12'>
									<FormGroup id='userName' isFloating label='CPF do usuário'>
										<Input
											type='text'
											required={true}
											onChange={formik.handleChange}
											value={formik.values.userName}
										/>
									</FormGroup>

									<FormGroup id='password' isFloating label='Senha' style={{ marginTop: '20px' }}>
										<Input
											type='password'
											required={true}
											onChange={formik.handleChange}
											value={formik.values.password}
										/>
									</FormGroup>
								</div>
								<div className='col-12'>
									<Button
										color='success'
										className='w-100 py-3'
										onClick={() => {
											formik.handleSubmit();
										}}>
										Login
									</Button>

									<Button
										color='info'
										className='w-100 py-3'
										style={{ marginTop: '20px' }}
										onClick={() => setShowResetPasswordModal(true)}>
										Esqueci minha senha
									</Button>
								</div>
							</form>
							{showErrorAlert && (
								<InvalidCredentialsAlert
									setShowErrorAlert={setShowErrorAlert}
									message='Credenciais inválidas.'
								/>
							)}
							{showResetPasswordModal && (
								<ResetPasswordModal
									showResetPasswordModal={showResetPasswordModal}
									setShowResetPasswordModal={setShowResetPasswordModal}
								/>
							)}
						</CardBody>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
