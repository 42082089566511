import React from 'react';
import _isEmpty from 'lodash/isEmpty';

import { CardBody } from '../../../bootstrap/Card';

import { TableColumn } from '../../consult-components/table-components/table-column';
import { AccessLogList } from '../../../../type/access-log';
import { ControlRowAccessLog } from './control-accesslog-row';

interface ControlTableAccessLogProps {
    accessLog: AccessLogList | undefined;
    isFluid: any;
    type: string;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ControlTableAccessLog: React.FC<ControlTableAccessLogProps> = ({
    accessLog,
    isFluid,
    type,
    setRefreshTable
}) => {
    const typeMessage = type === 'started' ? "Onboarding" : "Checkout";
    
    if (_isEmpty(accessLog)) return (
        <CardBody className='table-responsive' isScrollable={isFluid} style={{ height: '40rem' }}>
            <table className='table table-modern table-responsive'>
                <thead>
                    <tr key={'user-token-tables-names'}>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Ações</TableColumn>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Status</TableColumn>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Usuário</TableColumn>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Data</TableColumn>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Tipo de acesso</TableColumn>
                    </tr>
                </thead>
                <tbody>
                    <tr style={{textAlign: 'center'}}>
                        <td colSpan={5}>Nenhum acesso de {typeMessage} ainda.</td>
                    </tr>
                </tbody>
            </table>
        </CardBody>
    );

    return (
        <CardBody className='table-responsive' isScrollable={isFluid} style={{ height: '40rem' }}>
            <table className='table table-modern table-responsive'>
                <thead>
                    <tr key={'user-token-tables-names'}>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Ações</TableColumn>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Status</TableColumn>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Usuário</TableColumn>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Data</TableColumn>
                        <TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Tipo de acesso</TableColumn>
                    </tr>
                </thead>
                <tbody>
                    {accessLog &&
                        accessLog.map((accessLog) => {
                            return (
                                <ControlRowAccessLog
                                    key={`accessLog-row-${accessLog.id}`}
                                    accessLog={accessLog}
                                    type={type}
                                    setRefreshTable={setRefreshTable}
                                />
                            );
                        })}
                </tbody>
            </table>
        </CardBody>
    );
};