import '../../../styles/components/inquiry/_card-inquiry.scss';
import Alarm from '../../../components/icon/svg-icons/Alarm.svg';
import New from '../../../components/icon/svg-icons/New.svg';
import Approval from '../../../components/icon/svg-icons/Approval.svg';

interface CardInquiryParams {
    type: string;
    quantity: number;
    getQualifyInfo?: () => Promise<void>;
    getAnalyzeInfo?: () => Promise<void>;
    getSendBPInfo?: () => Promise<void>;
}

export function CardInquiry({ type, quantity, getQualifyInfo, getAnalyzeInfo, getSendBPInfo }: CardInquiryParams) {
    let color = '';
    let title = '';

    switch (type) {
        case 'qualify':
            color = "cardActiveNew";
            title = "Qualificar";
            break;
        case 'analyze':
            color = "cardAnalyze"
            title = "Analisar";
            break;
        case 'approved':
            color = "cardApproved"
            title = "Finalizar";
            break;
        default:
            break;
    };

    function handleClickCard(type: string) {
        switch (type) {
            case "qualify":
                return getQualifyInfo!()
            case "analyze":
                return getAnalyzeInfo!()
            case "approved":
                return getSendBPInfo!()
            default:
                break;
        }
    }

    return (
        <div className={`cardRoot cardBody cardActiveNew ${color}`} onClick={() => handleClickCard(type)}>
            <span className='cardIcon'>
                {
                    type === 'qualify' ?
                        <img src={New} alt="Autorizados" />
                        :
                        type === 'analyze' ?
                            <img src={Alarm} alt="Onboard" />
                            :
                            type === 'approved' ?
                                <img src={Approval} alt="CheckOut" />
                                :
                                <></>
                }
            </span>
            <div className='cardTitle'>
                {title}
            </div>
            <div className='cardQuantity'>
                {quantity}
            </div>
        </div>
    )
}