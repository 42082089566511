import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import _get from 'lodash/get';
import classNames from 'classnames';

import Button, { ButtonGroup } from '../../../bootstrap/Button';
import useDarkMode from '../../../../hooks/useDarkMode';
import Avatar from '../../../Avatar';
import showNotification from '../../../extras/showNotification';
import { updateAccessLogById } from "../../../../services/access-logs/access-logs-service";

import Swal from 'sweetalert2';
import { getUserSelfieById } from '../../../../services/user/user-service';
import DefaultAvatar from '../../../../assets/img/wanna/wanna3.png';
import { TableColumn } from '../../consult-components/table-components/table-column';
import '../../../../styles/components/control/_control-row.scss';
import { AccessLog } from '../../../../type/access-log';
import { getTokenById } from '../../../../services/token/token-service';
import { Token } from '../../../../type/token';
import { UserContext } from '../../../../contexts/user-context';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../bootstrap/Modal';
import { gatGatesByBuildingId } from '../../../../services/gate/gate-service';
import { GateList } from '../../../../type/gate';
import Select from "../../../bootstrap/forms/Select";
import Option from '../../../bootstrap/Option';
import { formatCpf } from '../../../../helpers/helpers';
import Popovers from '../../../bootstrap/Popovers';

async function openAvatarModal(avatar: string) {
	Swal.fire({
		title: 'Selfie do usuário',
		html:
			`<img src="${avatar}" style="display: block; !important; margin-left: auto !important; margin-right: auto !important; max-width: 300px !important; max-height: 500px !important;" />`,
		showDenyButton: false,
		confirmButtonText: 'Ok',
		allowOutsideClick: true,
		backdrop: true
	});
}

interface getAccessTypeTextParams {
	recurrent?: boolean;
	authorizationType: string;
}
const getAccessTypeText = ({ recurrent, authorizationType }: getAccessTypeTextParams) => {
	if (recurrent) return 'Recorrente'

	switch (authorizationType) {
		case 'grant':
			return 'Grant';
		case 'invite':
			return 'Convite';
		case 'register':
			return 'Temporário';
		case 'checkin':
			return 'Check-in';
		default:
			return 'Não identificado';
	}
};

interface ControlRowAccessLogProps {
	accessLog: AccessLog;
	type: string;	
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ControlRowAccessLog: React.FC<ControlRowAccessLogProps> = ({
	accessLog,
	type,
	setRefreshTable
}) => {
	const { darkModeStatus } = useDarkMode();
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const activeUserId = user.id;

	const [tokenData, setTokenData] = useState<Token>();
	const [isOpenModal, setIsOpenModal] = useState(false);
	const [gates, setGates] = useState<GateList | null>(null);
	const [gate, setGate] = useState<number>(0);

	const userIssuedForId = _get(tokenData, 'userRequested.id', '')
	const userIssuedForName = _get(tokenData, 'userRequested.name', '')
	const authorizationType = _get(tokenData, 'authorizationType')
	const recurrent = _get(tokenData, 'recurrent')
	const birthdate = _get(tokenData, 'userRequested.birthdate');
	const document = _get(tokenData, 'userRequested.taxid')
	const date = type === "started" ? _get(accessLog, 'onboardingAt') : _get(accessLog, 'checkoutAt')	
	const humanizedAccess = moment(date).format('DD/MM/YYYY HH:mm:ss');
	const tokenType = authorizationType;
	const statusColor = type === 'started' ? 'borderStarted' : 'borderConcluded'
	const statusText = type === 'started' ? 'Condomínio' : 'Check out'

	const [avatar, setAvatar] = useState<string>(DefaultAvatar);

	useEffect(() => {
		getUserSelfieById(userIssuedForId, { setAvatar })
	}, [userIssuedForId]);

	useEffect(() => {
		const getTokenData = async () => {
			if (accessLog) {
				const token = await getTokenById(accessLog.tokenId);

				setTokenData(token);
			}
		}

		getTokenData()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [accessLog]);

	useEffect(() => {
		const getGatesData = async () => {
			if (tokenData) {
				const gatesData = await gatGatesByBuildingId(tokenData.buildingId);

				setGates(gatesData);
			}
		}

		getGatesData()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [tokenData]);

	const handleChangeGate = (gate: { target: { value: string; }; }) => {
		if (!validateGate(parseInt(gate.target.value))) {
			return showNotification(`Por favor, selecione um Gate para este convidado`, ``, 'danger');
		}
	
		const gateSelected = parseInt(gate.target.value);
		setGate(gateSelected);
	};
	
	function validateGate(idGate: number) {
		if (idGate)
			return true;
	
		return false
	};

	function handleEditCadastro(userId: number) {
		navigate('/cadastro', {
			state: {
				userId: userId
			}
		});
	};

	async function handleNextStep() {	
		if (!validateGate(gate)) {
			return showNotification(`Por favor, selecione um Gate para este convidado`, ``, 'danger');
		};
		
		Swal.fire({
			title: `Avançar etapa`,
			html: `Deseja realmente avançar a etapa do usuário ${userIssuedForName.toUpperCase()}?`,
			customClass: {
				container: 'bg-dark',
				htmlContainer: 'text-left',
				confirmButton: 'bg-info',
			},
			showDenyButton: true,
			confirmButtonText: 'Continuar',
			allowOutsideClick: true,
			denyButtonText: `Cancelar`,
			backdrop: true,
		}).then(async (result) => {
			if (result.isConfirmed) {
				if (!validateGate(gate)) {
					return showNotification(`Por favor, selecione um Gate para este convidado`, ``, 'danger');
				};
	
				const modifiedAt = moment().toISOString(true);
				const modifiedBy = activeUserId;
				const checkoutAt = moment().toISOString(true);
	
				try {
					await updateAccessLogById({
						id: accessLog?.id,
						tokenId: accessLog?.tokenId,
						buildingId: accessLog?.buildingId === null ? 0 : accessLog?.buildingId,
						spotId: accessLog?.spotId === null ? 0 : accessLog?.spotId,
						authorizationChain: tokenData?.authorizationChain.path,
						onboardingGateId: accessLog?.onboardingGateId,
						onboardingAt: accessLog?.onboardingAt,
						conciergeUserId: accessLog?.conciergeUserId,
						checkoutGateId: gate,
						checkoutAt: checkoutAt,
						rejectedAt: accessLog?.rejectedAt === "" ? null : accessLog?.rejectedAt,
						description: accessLog?.description,
						controlStatus: "Concluded",
						status: "Active",
						createdAt: accessLog?.createdAt === "" ? null : accessLog?.createdAt,
						createdBy: accessLog?.createdBy,
						modifiedAt: modifiedAt,
						modifiedBy: modifiedBy,
					});
	
					showNotification(`Etapa avançada com sucesso!`, ``, 'success');
				} catch (error) {
					showNotification(`Desculpe, não foi possível avançar a etapa desse usuário`, ``, 'danger');
				} finally {
					setRefreshTable(true);
				}	
			}
		})
	}

	return (
		<>
			<tr key={accessLog.id}>
				<TableColumn>
					<ButtonGroup>
						<Button
							isDisable={type === "started" ? false : true}
							isOutline={!darkModeStatus}
							color='dark'
							isLight={darkModeStatus}
							className={classNames('text-nowrap', {
								'border-light': !darkModeStatus,
							})}
							icon='Edit'
							style={{ marginRight: '5px' }}
							title={"Editar cadastro"}
							onClick={() => handleEditCadastro(userIssuedForId)}
						/>
						<Button
							isDisable={type === "started" ? false : true}
							isOutline={!darkModeStatus}
							color='dark'
							isLight={darkModeStatus}
							className={classNames('text-nowrap', {
								'border-light': !darkModeStatus,
							})}
							icon='FastForward'
							style={{ marginRight: '5px' }}
							title={"Avançar etapa"}
							onClick={() => setIsOpenModal(true)}
						/>
					</ButtonGroup>
				</TableColumn>
				<TableColumn>
					<small
						className={`border ${statusColor} border-2 fw-bold px-2 py-1 rounded-1`}>
						{statusText}
					</small>
				</TableColumn>
				<TableColumn>
					{userIssuedForId && <Avatar title={"Clique para visualizar a selfie"} style={{ cursor: 'pointer' }} onClick={() => openAvatarModal(avatar)} src={avatar} srcSet={avatar} color={'success'} size={40} />}
					{birthdate === null ?
						<span style={{ color: '#E74C3C', fontWeight: 'bold' }} className='ms-3 d-flex align-items-center text-nowrap'>
							<Popovers trigger='hover' desc='Faltam algumas informações deste usuário. Favor, verificar o quanto antes!'>
								{`${userIssuedForName} | CPF: ${formatCpf(document)}`}
							</Popovers>
						</span>
						:
						<span className='ms-3 d-flex align-items-center text-nowrap'>
							{`${userIssuedForName} | CPF: ${formatCpf(document)}`}
						</span>
					}
				</TableColumn>
				<TableColumn>
					{humanizedAccess}
				</TableColumn>
				<TableColumn>{tokenType && getAccessTypeText({ recurrent, authorizationType: tokenType })}</TableColumn>
			</tr>
			<Modal
				setIsOpen={setIsOpenModal}
				isOpen={isOpenModal}
				fullScreen={'lg'}
				isCentered
				titleId='avancar-etapa-modal'>
				<ModalHeader setIsOpen={setIsOpenModal}>
					<ModalTitle id='avancar-etapa-modal'>Avançar etapa</ModalTitle>
				</ModalHeader>
				<ModalBody className='h-100 d-flex align-items-center justify-content-center flex-column'>
					<div className='row w-100 d-flex align-items-center justify-content-center flex-column'>
						<h6>
							Você está avançando a etapa do usuário {userIssuedForName}
						</h6>
						<p>Por favor, informe um gate!</p>
						<Select
							ariaLabel='Board select'
							placeholder='Selecione o Gate'
							onChange={handleChangeGate}>
							{gates?.map((gate) => (
								<Option key={gate.id} value={gate.id}>
									{gate.name}
								</Option>
							))}
						</Select>
						<Button
							onClick={() => handleNextStep()}
							className="btn btn-primary mt-4">
							Continuar
						</Button>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
};