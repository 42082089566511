import Button from '../../../components/bootstrap/Button';

import './submit-button-create-form.css';

interface SubmitButtonProps {
	onClick: any;
	buttonId: string;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ onClick, buttonId }) => {
	return (
		<span className='submit-button-create-form__container'>
			<Button
				color='info'
				id={buttonId}
				className='submit-button-create-form__button w-100'
				type='submit'
				onClick={onClick}>
				Enviar
			</Button>
		</span>
	);
};
