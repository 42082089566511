import { orderBy } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import { Token } from '../../../../type/token';
import { CardBody } from "../../../bootstrap/Card";
import { TableColumn } from "../../consult-components/table-components/table-column";
import { InquiryNewRowTokens } from './inquiry-new-row';

interface InquiryNewTokensProps {
	items: Token[];
	isFluid: any;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
	setModalCreateOrEditUserVisible: React.Dispatch<React.SetStateAction<boolean>>;
	setUserToEdit: React.Dispatch<React.SetStateAction<number>>;
}

export const InquiryNewTokens: React.FC<InquiryNewTokensProps> = ({
	items,
	isFluid,
	setRefreshTable,
	setModalCreateOrEditUserVisible,
	setUserToEdit
}) => {
	if (_isEmpty(items)) return (
		<CardBody className='table-responsive' isScrollable={isFluid} style={{ height: '40rem' }}>
			<table className='table table-modern table-responsive'>
				<thead>
					<tr key={'user-token-tables-names'}>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Ações</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Motivo</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Selfie</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Usuário</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Destino</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Ativado por</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Documento</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Telefone</TableColumn>
					</tr>
				</thead>
				<tbody>
					<tr style={{ textAlign: 'center' }}>
						<td colSpan={8}>Nenhum novo acesso a ser qualificado ainda.</td>
					</tr>
				</tbody>
			</table>
		</CardBody>
	);

	return (
		<CardBody className='table-responsive' isScrollable={isFluid} style={{ height: '40rem' }}>
			<table className='table table-modern table-responsive'>
				<thead>
					<tr key={'user-token-tables-names'}>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Ações</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Motivo</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Selfie</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Usuário</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Destino</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Ativado por</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Documento</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Telefone</TableColumn>
					</tr>
				</thead>
				<tbody>
					{items &&
						orderBy(items, ['issuedAt'], ['desc']).map((item) => {
							return (
								<InquiryNewRowTokens
									key={`token-row-${item.id}`}
									item={item}
									setRefreshTable={setRefreshTable}
									setUserToEdit={setUserToEdit}
									setModalCreateOrEditUserVisible={setModalCreateOrEditUserVisible}
								/>
							);
						})}
				</tbody>
			</table>
		</CardBody>
	)
}