import { useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';
import { GuestInfoType } from '../../../../type/guest-info';

import '../../../../styles/components/onboarding/_qr-scanner.scss';

import DefaultAvatar from '../../../../assets/img/wanna/wanna3.png';
import { getTokenById } from '../../../../services/token/token-service';
import { getUserById, getUserSelfieById } from '../../../../services/user/user-service';
import { getBuildingById } from '../../../../services/building/building-service';
import { getSpotById } from '../../../../services/spot/spot-service';
import { AccessLog } from '../../../../type/access-log';
import { getTokenAccessLog } from '../../../../services/access-logs/access-logs-service';
import { gatGatesByBuildingId } from '../../../../services/gate/gate-service';
import { GateList } from '../../../../type/gate';

interface props {
    setIsScanQRCodeOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setGuestInfo: React.Dispatch<React.SetStateAction<GuestInfoType | null>>;
    setScanStatus: React.Dispatch<React.SetStateAction<string>>;
    setAccessLog: React.Dispatch<React.SetStateAction<AccessLog | null>>;
    setGates: React.Dispatch<React.SetStateAction<GateList | null>>;
}

export const QRScanner = ({ setIsScanQRCodeOpen, setGuestInfo, setScanStatus, setAccessLog, setGates }: props) => {
    const [linkScanned, setLinkScanned] = useState<string | null>('No result');
    const [tokenId, setTokenId] = useState<string>('');
    const [avatar, setAvatar] = useState<string>("");

    function handleScan(result: string | null) {
        if (linkScanned === 'No result' && result !== null) {
            setLinkScanned(result);
        }
    }

    function handlePathLink(uuidGuest: string | null) {
        let pathName = uuidGuest;
        if (pathName)
            setTokenId(pathName);
    }

    async function handleGetGuestInfo(tokenId: string){      
        try {
            //Search for token to retrieve guest data
            const token = await getTokenById(tokenId);

            //Get those data to display on screen
            const userIssuedByName = token?.userIssued?.name;
            const userIssuedForName = token?.userRequested?.name;
            const userIssuedForId = token?.userRequested?.id;
            const role = token?.role;
            const issuedAt = token?.issuedAt;
            const user = await getUserById(userIssuedForId);
            const userIssuedTaxId = user.taxid;
            const building = token.buildingId ? await getBuildingById(token?.buildingId) : null;
            const buildingName = building ? building?.name : "Condomínio não informado";
            const spot = token.spotId ? await getSpotById(token?.spotId) : null;
            const spotName = spot? spot?.name : "Spot não informado";
            const avatarSrc = await getUserSelfieById(userIssuedForId, { setAvatar });

            const guestInfoData:GuestInfoType = {
                guestName: userIssuedForName,
                guestIssuedAt: issuedAt,
                guestDocument: userIssuedTaxId,
                guestRole: role,
                guestIssuedBy: userIssuedByName,
                guestDestiny: "",
                guestOrigin: "",
                guestSelfie: avatarSrc,
                buildingName: buildingName,
                spotName: spotName,
            }

            //Search for AccessLog with the specified tokenId
            const accessLog = await getTokenAccessLog(tokenId);

            const accessLogData:AccessLog = {
                id: accessLog.id ? accessLog.id : null,
                buildingId: accessLog.buildingId ? accessLog.buildingId : token?.buildingId,
                checkoutAt: accessLog.checkoutAt ? accessLog.checkoutAt : "",
                authorizationChain: accessLog.authorizationChain ? accessLog.authorizationChain : token?.authorizationChain.path,
                checkoutGateId: accessLog.checkoutGateId ? accessLog.checkoutGateId : 0,
                conciergeUserId: accessLog.conciergeUserId ? accessLog.conciergeUserId : 0,
                controlStatus: accessLog.controlStatus ? accessLog.controlStatus : "",
                createdAt: accessLog.createdAt ? accessLog.createdAt : "",
                createdBy: accessLog.createdBy ? accessLog.createdBy : 0,
                description: accessLog.description ? accessLog.description : "",
                onboardingAt: accessLog.onboardingAt ? accessLog.onboardingAt : "",
                onboardingGateId: accessLog.onboardingGateId ? accessLog.onboardingGateId : 0,
                rejectedAt: accessLog.rejectedAt ? accessLog.rejectedAt : "",
                spotId: accessLog.spotId ? accessLog.spotId : token?.spotId,
                status: accessLog.status ? accessLog.status : "",
                tokenId: accessLog.tokenId ? accessLog.tokenId : tokenId,
                modifiedAt: accessLog.modifiedAt ? accessLog.modifiedAt : "",
                modifiedBy: accessLog.modifiedBy ? accessLog.modifiedBy : 0,
            }

            if (token.buildingId) {
                //Search for the Gates
                const gates = await gatGatesByBuildingId(token.buildingId)
                setGates(gates);
            }
            setGuestInfo(guestInfoData);
            setIsScanQRCodeOpen(false)
            setAccessLog(accessLogData);
        } catch (error) {
            setGates(null);
            setGuestInfo(null)
            setAccessLog(null);
            setScanStatus('QRCode inválido!')
            setLinkScanned('No result')
        }
    }

    useEffect(() => {
        if (linkScanned !== 'No result' && linkScanned !== null)
            handlePathLink(linkScanned)
    }, [linkScanned])

    useEffect(() => {
        if (tokenId !== '' && tokenId !== null) 
            handleGetGuestInfo(tokenId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tokenId])

    return (
        <div>
            <QrReader
                delay={100}
                facingMode={'environment'}
                onScan={(link: string | null) => handleScan(link)}
                onError={(err) => err}
                className="scannerContainer"
            />
        </div>
    );
}