import React, { Fragment } from 'react';

import { CardLabel, CardSubTitle, CardTitle } from '../../bootstrap/Card';

interface EmptySpotCardProps {
  id?: number,
  buildingId: number
}
export const EmptySpotCard: React.FC<EmptySpotCardProps> = ({ id, buildingId }) => (
	<Fragment>
		<div className='col-12 d-flex justify-content-between'>
			<div className='col-7'>
				<CardLabel>
					{id && (
            <CardTitle tag='h3' className='h4'>
              {`Spot ${id}`}
            </CardTitle>
          )}
					<CardSubTitle tag='h4' className='h5'>
						{`Building ${buildingId}`}
					</CardSubTitle>
				</CardLabel>
			</div>
		</div>
	</Fragment>
);
