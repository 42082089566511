import React from "react";
import { TableColumn } from "../../../../../../components/entrada-segura/consult-components/table-components/table-column";
import Icon from "../../../../../../components/icon/Icon";

interface TableHeaderProps {
    OrdenarLista: Function;
    listAscOrder: boolean;
}

export const TableHeader = ({ OrdenarLista, listAscOrder }: TableHeaderProps) => {
    return (
        <tr key={'user-token-tables-names'}>
            <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Ações</TableColumn>
            <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Anexos</TableColumn>
            <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Nome</TableColumn>
            <TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Documento</TableColumn>
        </tr>
    )
}