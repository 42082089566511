import { useEffect, useState } from 'react';
import { getTokenAccessLogList } from '../../services/access-logs/access-logs-service';

import { AccessLogList } from '../../type/access-log';

interface UseTokenAccessLogParams {
	tokenId: string;
}

export function useTokenAccessLog({ tokenId }: UseTokenAccessLogParams): AccessLogList | undefined {
	const [accessLog, setAccessLog] = useState<AccessLogList>();

	useEffect(() => {
		const getAccessLog = async () => {
			if (tokenId) {
				const accessLogs = await getTokenAccessLogList(tokenId);
				setAccessLog(accessLogs);
			}
		};

		getAccessLog()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [tokenId]);

	return accessLog;
}
