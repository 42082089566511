import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import Button from '../../../components/bootstrap/Button';

interface InvalidCredentialsAlertProps {
	setShowErrorAlert: Function;
	message: string;
}

export const InvalidCredentialsAlert: React.FC<InvalidCredentialsAlertProps> = ({
	setShowErrorAlert,
	message,
}) => {
	return (
		<Alert
			icon='warning'
			isLight
			color='danger'
			borderWidth={1}
			className='d-flex align-items-center justify-content-center mt-3'
			style={{
				position: 'absolute',
				top: '40%',
				left: '40%',
				width: '200px',
				height: '200px',
			}}>
			<AlertHeading tag='h2' className='h3 text-center'>
				ATENÇÃO!
			</AlertHeading>
			<p className='text-center'>{message}</p>
			<div className={`d-flex align-items-center justify-content-center mt-3`}>
				<Button className={`btn btn-danger w-100`} onClick={() => setShowErrorAlert(false)}>
					Entendi
				</Button>
			</div>
		</Alert>
	);
};
