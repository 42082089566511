import api from '../../api';
import { HTTPMetricResponse } from '../http-metric-response';
import { parseMetric } from '../parsers/metric-parser';

export async function getTokensCountInBuilding(buildingId: number): Promise<number> {
	return api.get(`/api/v1/metrics/tokens/${buildingId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPMetricResponse;

		return parseMetric(responseJson);
	});
}

interface GetTokensCountByBuildingAndRoleParams {
	buildingId: number;
	tokenRole: string;
}
export async function getTokensCountByBuildingAndRole({
	buildingId,
	tokenRole,
}: GetTokensCountByBuildingAndRoleParams): Promise<number> {
	return api.get(`/api/v1/metrics/tokens/${buildingId}/role/${tokenRole}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPMetricResponse;

		return parseMetric(responseJson);
	});
}

interface GetTokensCountByBuildingAndStatusParams {
	buildingId: number;
	tokenStatus: string;
}
export async function getTokensCountByBuildingAndStatus({
	buildingId,
	tokenStatus,
}: GetTokensCountByBuildingAndStatusParams): Promise<number> {
	return api.get(`/api/v1/metrics/tokens/${buildingId}/status/${tokenStatus}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPMetricResponse;

		return parseMetric(responseJson);
	});
}

interface GetTokensCountByBuildingAndAuthTypeParams {
	buildingId: number;
	tokenAuthType: string;
}
export async function getTokensCountByBuildingAndAuthType({
	buildingId,
	tokenAuthType,
}: GetTokensCountByBuildingAndAuthTypeParams): Promise<number> {
	return api.get(`/api/v1/metrics/tokens/${buildingId}/authorization_type/${tokenAuthType}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPMetricResponse;

		return parseMetric(responseJson);
	});
}

interface GetTokensCountByBuildingAndTokenStatusParams {
	buildingId: number;
	tokenStatus: string;
}
export async function getTokensCountByBuildingAndTokenStatus({
	buildingId,
	tokenStatus,
}: GetTokensCountByBuildingAndTokenStatusParams): Promise<number> {
	return api.get(`/api/v1/metrics/tokens/${buildingId}/status/${tokenStatus}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPMetricResponse;

		return parseMetric(responseJson);
	});
}