export const acceptedFileTypes =
  '.jpg,.jpeg,.png,.bmp,.pdf,.txt,.xlsx,.doc,.docx,.csv,.xls';

  

export function toBase64(file) {
  return new Promise(function _(resolve, reject) {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export function getBase64Text(originalFile) {
  if (originalFile) {
    const [, splittedFile] = originalFile.split(',');
    return splittedFile;
  }
  return null;
}

export function isValidFileType(fileType) {
  const acceptedExtensions = [
    'image/jpeg',
    'image/png',
    'image/bmp',
    'application/pdf',
    'text/plain',
    'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'doc',
    'docx',
    'text/csv',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ];

  return acceptedExtensions.includes(fileType);
}

export function isValidFileSize(fileSize, MaxSizeMB = 15) {
  return fileSize / 1024 / 1024 < MaxSizeMB;
}
