import { useContext, useEffect, useState } from "react";
import { useControl } from "../../../hooks/entrada-segura/use-control";
import Card from "../../bootstrap/Card";
import PaginationButtons, { PER_COUNT } from "../../PaginationButtons";
import { CardControl } from "./card-control";
import { ControlTableTokens } from "./table-control/control-token-table";
import '../../../styles/components/control/_control-components.scss';
import { ControlTableAccessLog } from "./table-control/control-accesslog-table";
import { UserContext } from "../../../contexts/user-context";

export const ControlComponents = ({ isFluid = true }) => {
    const { user } = useContext(UserContext);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['10']);
    const [refreshTable, setRefreshTable] = useState<boolean>(false);
    const [isOpenNew, setIsOpenNew] = useState<boolean>(true);
    const [isOpenStarted, setIsOpenStarted] = useState<boolean>(false);
    const [isOpenConcluded, setIsOpenNewConcluded] = useState<boolean>(false);
        
    const { tokens, cardStarted, cardConcluded, cardNew, accessLogsConcluded, accessLogsStarted } = useControl({ page, perPage, refreshTable, superUser: user.isSuperUser ,userId: user.id });

    useEffect(() => {
        setRefreshTable(false);
    }, [refreshTable])

    return (
        <>
            <div className="cardsContainer d-flex align-items-center justify-content-evenly flex-row">
                <CardControl
                    setIsOpenNew={setIsOpenNew}
                    setIsOpenStarted={setIsOpenStarted}
                    setIsOpenConcluded={setIsOpenNewConcluded}
                    quantity={cardNew}
                    type={"active"}
                />
                <CardControl
                    setIsOpenNew={setIsOpenNew}
                    setIsOpenStarted={setIsOpenStarted}
                    setIsOpenConcluded={setIsOpenNewConcluded}
                    quantity={cardStarted}
                    type={"started"}
                />
                <CardControl
                    setIsOpenNew={setIsOpenNew}
                    setIsOpenStarted={setIsOpenStarted}
                    setIsOpenConcluded={setIsOpenNewConcluded}
                    quantity={cardConcluded}
                    type={"concluded"}
                />
            </div>
            <div className='fw-bold fs-5 mt-4'>
                Controle de acessos
            </div>
            <small className=' mt-2'>Clique nos Cards acima para navegar entre os Status de cada Convidado.</small>
            {isOpenNew &&
                <Card stretch={isFluid}>
                    <ControlTableTokens
                        tokens={tokens?.results}
                        isFluid={isFluid}
                        setRefreshTable={setRefreshTable} />
                    <PaginationButtons
                        data={tokens?.results}
                        totalResources={tokens?.totalRecords}
                        label='Tokens'
                        setCurrentPage={setPage}
                        currentPage={page}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                </Card>
            }
            {isOpenStarted &&
                <Card stretch={isFluid}>
                    <ControlTableAccessLog
                        type={'started'}
                        accessLog={accessLogsStarted}
                        setRefreshTable={setRefreshTable} 
                        isFluid={isFluid} />
                </Card>
            }
            {isOpenConcluded &&
                <Card stretch={isFluid}>
                    <ControlTableAccessLog
                        type={'concluded'}
                        setRefreshTable={setRefreshTable} 
                        accessLog={accessLogsConcluded}
                        isFluid={isFluid} />
                </Card>
            }
        </>
    )
}