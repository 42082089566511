import axios from 'axios';
import { API_HOST } from '../../../../services/constants';

//import { API_HOST } from './constants';

const apiUser = axios.create({
  baseURL: API_HOST
})

apiUser.interceptors.request.use(async (config) => {

  const token = localStorage.getItem('active-token');

  if (token) {
    apiUser.defaults.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (err) => {
  return Promise.reject(err);
});

export default apiUser;