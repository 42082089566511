import api from '../../api';
import { HTTPMetricResponse } from '../http-metric-response';
import { parseMetric } from '../parsers/metric-parser';

export async function getGatesCountInBuilding(buildingId: number): Promise<number> {
	return api.get(`/api/v1/metrics/gates/${buildingId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPMetricResponse;

		return parseMetric(responseJson);
	});
}

interface GetGatesCountByStatusInBuildingParams {
	buildingId: number;
	gateStatus: string;
}
export async function getGatesCountByStatusInBuilding({
	buildingId,
	gateStatus,
}: GetGatesCountByStatusInBuildingParams): Promise<number> {
	return api.get(`/api/v1/metrics/gates/${buildingId}/gate_status/${gateStatus}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPMetricResponse;

		return parseMetric(responseJson);
	});
}

interface GetGatesCountByTypeInBuildingParams {
	buildingId: number;
	gateType: string;
}
export async function getGatesCountByTypeInBuilding({
	buildingId,
	gateType,
}: GetGatesCountByTypeInBuildingParams): Promise<number> {
	return api.get(`/api/v1/metrics/gates/${buildingId}/gate_type/${gateType}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPMetricResponse;

		return parseMetric(responseJson);
	});
}