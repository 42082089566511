import { useEffect, useState } from 'react';

import _get from 'lodash/get';

import { getBuildingById } from '../../services/building/building-service';

export interface UseBuildingDataParams {
	buildingId: number | undefined;
}

export function useBuildingName({ buildingId }: UseBuildingDataParams): string | undefined {
	const [buildingName, setBuildingName] = useState<string>();

	useEffect(() => {
		const getBuildingData = async () => {
			if (buildingId) {
				const localStorageBuildingName = localStorage.getItem(`building-${buildingId}-name`);

				localStorageBuildingName && setBuildingName(localStorageBuildingName);

				if (buildingId && !localStorageBuildingName) {
					const building = await getBuildingById(buildingId);
					const name = _get(building, 'name');
					setBuildingName(name);
					name && localStorage.setItem(`building-${buildingId}-name`, name);
				}
			}
		};

		getBuildingData()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [buildingId, buildingName]);

	return buildingName;
}
