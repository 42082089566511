import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../contexts/user-context";
import { IntervalHooks } from "../../../hooks/entrada-segura/use-interval-fetch";
import { getBoardingPassByTokenId } from "../../../services/boarding-pass/boarding-pass-service";
import { getTokensByAuthorizationTypes, getUserGrantTokens } from "../../../services/token/token-service";
import '../../../styles/components/inquiry/_inquiry-components.scss';
import { Token } from "../../../type/token";
import Card from "../../bootstrap/Card";
import { CreateOrEditUserForm } from "../consult-components/edit-user-form";
import { AUTH_TYPES, TOKEN_STATUS } from "../token/token-activation-form/blocks/constants";
import { CardInquiry } from "./card-inquiry";
import { InquiryAnalyzeTokens } from "./table-analyze/inquiry-analyze-table";
import { InquiryNewTokens } from "./table-inquiry/inquiry-new-table";
import { InquirySendBPTokens } from "./table-send-bp/inquiry-send-bp-table";
import { getBuildingId } from "../../../services/building/building-service";

export function InquiryComponents({ isFluid = true }) {
    const { user } = useContext(UserContext)

    const [isOpenNew, setIsOpenNew] = useState<boolean>(true);
    const [isOpenAnalyze, setIsOpenAnalyze] = useState<boolean>(false);
    const [isOpenApproved, setIsOpenApproved] = useState<boolean>(false);
    const [refreshTable, setRefreshTable] = useState<boolean>(false);

    const [qualify, setQualify] = useState<Token[]>({} as Token[]);
    const [analyze, setAnalyze] = useState<Token[]>({} as Token[]);
    const [sendBP, setSendBP] = useState<Token[]>({} as Token[]);

    const [cardQualify, setCardQualify] = useState<number>(0);
    const [cardAnalyze, setCardAnalyze] = useState<number>(0);
    const [cardSendBP, setCardSendBP] = useState<number>(0);

    const [userToEdit, setUserToEdit] = useState<number>(0);
    const [modalCreateOrEditUserVisible, setModalCreateOrEditUserVisible] = useState<boolean>(false);

    const getAllInfo = async () => {
        if (user.isSuperUser) {
            //Qualify
            const buildingId = await getBuildingId(user.id);
            const tokensToQualify = await getTokensByAuthorizationTypes({ authorizationTypes: [AUTH_TYPES.QUALIFY], status: TOKEN_STATUS.ACTIVE, buildingId })
            setQualify(tokensToQualify.results)
            setCardQualify(tokensToQualify.totalRecords)

            //Send boarding pass
            const tokenUsersToSendBP = await getTokensByAuthorizationTypes({ authorizationTypes: [AUTH_TYPES.GRANT, AUTH_TYPES.CHECKIN], status: TOKEN_STATUS.ACTIVE, buildingId })

            const arrayTokens: Token[] = [];

            for await (const token of tokenUsersToSendBP.results) {
                if (token && token.id) {
                    const response = await getBoardingPassByTokenId(token.id);

                    if (response === null) {
                        arrayTokens.push(token);
                    }
                }
            }

            setSendBP(arrayTokens);
            setCardSendBP(arrayTokens.length)
        }

        //Analyze
        const buildingId = await getBuildingId(user.id);
        const tokensToAnalyze = await getTokensByAuthorizationTypes({ authorizationTypes: [AUTH_TYPES.QUALIFY], status: TOKEN_STATUS.PENDING, buildingId })
        setAnalyze(tokensToAnalyze.results)
        setCardAnalyze(tokensToAnalyze.totalRecords)

    }

    const getQualifyInfo = async () => {
        //Qualify
        const buildingId = await getBuildingId(user.id);
        const tokensToQualify = await getTokensByAuthorizationTypes({ authorizationTypes: [AUTH_TYPES.QUALIFY], status: TOKEN_STATUS.ACTIVE, buildingId })

        setQualify(tokensToQualify.results)
        setCardQualify(tokensToQualify.totalRecords)
        setIsOpenNew(true)
        setIsOpenAnalyze(false)
        setIsOpenApproved(false)
    }

    const getAnalyzeInfo = async () => {
        //Analyze
        const buildingId = await getBuildingId(user.id);

        const tokensToAnalyze = await getTokensByAuthorizationTypes({ authorizationTypes: [AUTH_TYPES.QUALIFY], status: TOKEN_STATUS.PENDING, buildingId })

        setAnalyze(tokensToAnalyze.results)
        setCardAnalyze(tokensToAnalyze.totalRecords)
        setIsOpenNew(false)
        setIsOpenAnalyze(true)
        setIsOpenApproved(false)
    }

    const getSendBPInfo = async () => {
        //Send boarding pass
        const buildingId = await getBuildingId(user.id);

        const tokenUsersToSendBP = await getTokensByAuthorizationTypes({ authorizationTypes: [AUTH_TYPES.GRANT, AUTH_TYPES.CHECKIN], status: TOKEN_STATUS.ACTIVE, buildingId })

        const arrayTokens: Token[] = [];

        for await (const token of tokenUsersToSendBP.results) {
            if (token && token.id) {
                const response = await getBoardingPassByTokenId(token.id);

                if (response === null) {
                    arrayTokens.push(token);
                }
            }
        }

        setSendBP(arrayTokens);
        setCardSendBP(arrayTokens.length)
        setIsOpenNew(false)
        setIsOpenAnalyze(false)
        setIsOpenApproved(true)
    }

    useEffect(() => {
        getAllInfo()
    }, [])

    IntervalHooks.useDispatch(() => { getAllInfo(); }, 300000);

    useEffect(() => {
        if (refreshTable === true) {
            getAllInfo()
        }

        setRefreshTable(false);
    }, [refreshTable])

    useEffect(() => {
        if (user.isSuperUser === false) {
            setIsOpenNew(false)
            setIsOpenAnalyze(true)
            setIsOpenApproved(false)
        }
    }, [])

    return (
        <>
            <div className="cardsContainer d-flex align-items-center justify-content-evenly flex-row">
                {user.isSuperUser &&
                    <CardInquiry
                        quantity={cardQualify}
                        type={"qualify"}
                        getQualifyInfo={getQualifyInfo}
                    />
                }

                <CardInquiry
                    quantity={cardAnalyze}
                    type={"analyze"}
                    getAnalyzeInfo={getAnalyzeInfo}
                />

                {user.isSuperUser &&
                    <CardInquiry
                        quantity={cardSendBP}
                        type={"approved"}
                        getSendBPInfo={getSendBPInfo}
                    />
                }
            </div>


            {isOpenNew && user.isSuperUser &&
                <>
                    <div className='fw-bold fs-5 mt-4 rounded p-3 borderQualify'>
                        Qualificar Acessos
                    </div>
                    <div className='fw-bold fs-6 py-0 px-3'>Clique nos Cards acima para navegar entre os Status de cada Convidado.</div>
                    <Card stretch={isFluid}>
                        <InquiryNewTokens
                            items={qualify}
                            isFluid={isFluid}
                            setRefreshTable={setRefreshTable}
                            setUserToEdit={setUserToEdit}
                            setModalCreateOrEditUserVisible={setModalCreateOrEditUserVisible}
                        />
                    </Card>
                </>
            }

            {isOpenAnalyze &&
                <>
                    <div className='fw-bold fs-5 mt-4 rounded p-3 borderAnalyze'>
                        Analisar Acessos
                    </div>
                    {user.isSuperUser &&
                        <div className='fw-bold fs-6 py-0 px-3'>Clique nos Cards acima para navegar entre os Status de cada Convidado.</div>
                    }
                    <Card stretch={isFluid}>
                        <InquiryAnalyzeTokens
                            items={analyze}
                            isFluid={isFluid}
                            setRefreshTable={setRefreshTable}
                            setUserToEdit={setUserToEdit}
                            setModalCreateOrEditUserVisible={setModalCreateOrEditUserVisible}
                        />
                    </Card>
                </>
            }

            {isOpenApproved && user.isSuperUser &&
                <>
                    <div className='fw-bold fs-5 mt-4 rounded p-3 borderReady'>
                        Enviar Boarding Pass
                    </div>
                    <small className='fw-bold fs-6 py-0 px-3'>Clique nos Cards acima para navegar entre os Status de cada Convidado.</small>
                    <Card stretch={isFluid}>
                        <InquirySendBPTokens
                            items={sendBP}
                            isFluid={isFluid}
                            setRefreshTable={setRefreshTable}
                        />
                    </Card>
                </>
            }

            <CreateOrEditUserForm userId={userToEdit} setShouldOpenEditUserForm={setModalCreateOrEditUserVisible} shouldOpenEditUserForm={modalCreateOrEditUserVisible} setRefreshTable={setRefreshTable} />
        </>
    )
}