import { AccessLog, AccessLogList, AuthorzationChain } from '../../type/access-log';
import api from '../api';

import { HTTPAccessLogListResponse, HTTPAccessLogResponse } from './http-access-log-response';
import { parseAccessLog } from './parsers/access-log-parser';

export async function getTokenAccessLogList(tokenId: string): Promise<AccessLogList> {
	return api.get(`/api/v1/accesslogs/token_id/${tokenId}`, {
		headers: { 
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {	
		const responseJson = response.data as HTTPAccessLogListResponse;
		const returnValue = responseJson?.results.map(parseAccessLog);

		return returnValue;
	});
}

export async function getTokenAccessLog(tokenId: string): Promise<AccessLog> {
	return api.get(`/api/v1/accesslogs/token_id/${tokenId}`, {
		headers: { 
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {	
		const responseJson = response.data as HTTPAccessLogListResponse;
		const findValue = responseJson.results
			.find(item => item.status === "Active" && item.control_status === "Started");

		const returnValue = parseAccessLog(findValue);

		return returnValue;
	});
}

export async function getTokenAccessLogsCards(): Promise<AccessLogList> {
	return api.get(`/api/v1/accesslogs/`, {
		headers: { 
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {	
		const responseJson = response.data as HTTPAccessLogListResponse;
		const returnValue = responseJson?.results.map(parseAccessLog);

		return returnValue;
	});
}

export async function getTokenAccessLogsCardsByBuildingId(buildingId: number): Promise<AccessLogList> {
	return api.get(`/api/v1/accesslogs/building_id/${buildingId}`, {
		headers: { 
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {	
		const responseJson = response.data as HTTPAccessLogListResponse;
		const returnValue = responseJson?.results.map(parseAccessLog);

		return returnValue;
	});
}

interface CreateAccessLogInterface {
	tokenId: string | undefined
	buildingId: number | undefined
	spotId: number | undefined
	authorizationChain?: AuthorzationChain | string
	onboardingGateId: number | undefined
	onboardingAt: string | undefined
	conciergeUserId: number | undefined
	checkoutGateId: number | undefined | null
	checkoutAt: string | null | undefined
	rejectedAt: string | null | undefined
	description: string | null | undefined
	controlStatus: string | undefined
	status: string | undefined
	createdAt: string | undefined
	createdBy: number | undefined
	modifiedAt: string | null | undefined
	modifiedBy: number | undefined
}

export async function createAccessLog({
	tokenId,
	buildingId,
	spotId,
	authorizationChain,
	onboardingGateId,
	onboardingAt,
	conciergeUserId,
	checkoutGateId,
	checkoutAt,
	rejectedAt,
	description,
	controlStatus,
	status,
	createdAt,
	createdBy,
	modifiedAt,
	modifiedBy,
}: CreateAccessLogInterface): Promise<AccessLog> {
	const body = {
		token_id: tokenId,
		building_id: buildingId,
		spot_id: spotId,
		authorization_chain: authorizationChain,
		onboarding_gate_id: onboardingGateId,
		onboarding_at: onboardingAt,
		concierge_user_id: conciergeUserId,
		checkout_gate_id: checkoutGateId,
		checkout_at: checkoutAt,
		rejected_at: rejectedAt,
		description,
		control_status: controlStatus,
		status,
		created_at: createdAt,
		created_by: createdBy,
		modified_at: modifiedAt,
		modified_by: modifiedBy,
	};

	return api.post(`/api/v1/accesslogs/`, JSON.stringify(body), {
		headers: { 
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {	
		const responseJson = response.data as HTTPAccessLogResponse;

		return parseAccessLog(responseJson);
	});	
}

interface UpdateAccessLogByIdParams {
	id: number | null | undefined
	tokenId: string | undefined
	buildingId: number | undefined
	spotId: number | undefined
	authorizationChain: AuthorzationChain | string | undefined
	onboardingGateId: number | undefined
	onboardingAt: string | undefined
	conciergeUserId: number | undefined
	checkoutGateId: number | undefined
	checkoutAt: string | null | undefined
	rejectedAt: string | null | undefined
	description: string | null | undefined
	controlStatus: string | undefined
	status: string | undefined
	createdAt: string | undefined | null
	createdBy: number | undefined
	modifiedAt: string | undefined
	modifiedBy: number | undefined
}

export async function updateAccessLogById({
	id,
	tokenId,
	buildingId,
	spotId,
	authorizationChain,
	onboardingGateId,
	onboardingAt,
	conciergeUserId,
	checkoutGateId,
	checkoutAt,
	rejectedAt,
	description,
	controlStatus,
	status,
	createdAt,
	createdBy,
	modifiedAt,
	modifiedBy,
}: UpdateAccessLogByIdParams): Promise<AccessLog>{
	const body = {
		id,
		token_id: tokenId,
		building_id: buildingId,
		spot_id: spotId,
		authorization_chain: authorizationChain,
		onboarding_gate_id: onboardingGateId,
		onboarding_at: onboardingAt,
		concierge_user_id: conciergeUserId,
		checkout_gate_id: checkoutGateId,
		checkout_at: checkoutAt,
		rejected_at: rejectedAt,
		description,
		control_status: controlStatus,
		status,
		created_at: createdAt,
		created_by: createdBy,
		modified_at: modifiedAt,
		modified_by: modifiedBy,
	};

	return api.put(`/api/v1/accesslogs`, JSON.stringify(body), {
		headers: { 
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {	
		const responseJson = response.data as HTTPAccessLogResponse;

		return parseAccessLog(responseJson);
	});
}