import { Fragment, useContext } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import _get from 'lodash/get';

import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import showNotification from '../../../../components/extras/showNotification';
import { createSpot } from '../../../../services/spot/spot-service';

import { SubmitButton } from '../submit-button-create-form';
import Select from '../../../../components/bootstrap/forms/Select';
import Option from '../../../../components/bootstrap/Option';
import { UserContext } from '../../../../contexts/user-context';
import { RequiredFieldMessage } from '../required-field-message';
import { BuildingList } from '../../../../type/building';

interface SpotFormProps {
  userBuildings: BuildingList | undefined
}
export const SpotForm: React.FC<SpotFormProps> = ({ userBuildings }) => {
	const { user } = useContext(UserContext);
	const activeUserId = user.id;

	const formik = useFormik({
		initialValues: {
			spotName: '',
			spotBuildingId: '',
			spotType: 'House',
			spotLogo: '',
			spotStatus: '',
		},
		validationSchema: Yup.object({
			spotName: Yup.string().label('Nome').required('Nome é um campo obrigatório'),
			spotBuildingId: Yup.number()
				.label('ID do condomínio')
				.required('ID do condomínio é um campo obrigatório'),
			spotType: Yup.number()
				.label('Tipo do local')
				.required('Tipo do local é um campo obrigatório'),
		}),

		onSubmit: async (values) => {
			const createdBy = activeUserId;
			const createdAt = moment().toISOString();
			const buildingId = values.spotBuildingId ? parseInt(values.spotBuildingId, 10) : 0;

			try {
				await createSpot({
					name: values.spotName,
					buildingId,
					spotLogo: values.spotLogo,
					spotType: values.spotType,
					status: values.spotStatus,
					createdAt,
					createdBy,
				});

				showNotification(`Spot criado com sucesso!`, ``, 'success');
				formik.resetForm();
			} catch (error) {
				console.error('Error creating spot: ', error);
				showNotification(`Erro ao criar spot. Por favor, tente novamente.`, ``, 'danger');
			}
		},
	});

	return (
		<Fragment>
			<div key={'spot-form'} className={'col-12'}>
				<Card className='table-responsive' borderSize={3}>
					<CardHeader borderSize={2}>
						<CardLabel icon='DoorFront' iconColor='success'>
							<CardTitle>Criar Spot</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody>
						<div className='row g-3'>
							<form className='row g-3' onSubmit={formik.handleSubmit} style={{ fontSize: '15px' }}>
								<div className='col-12 d-flex flex-column justify-content-center'>
									<FormGroup id='spotName' label='Nome: ' className='mt-2'>
										<Input
                      id='spotName'
											onChange={formik.handleChange}
											value={formik.values.spotName}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.spotName')} />
									</FormGroup>

                  <FormGroup className='mt-12' id='spotBuildingId' label='Condomínio associado:'>
										<Select
                      id='spotBuildingId'
											ariaLabel='Condomínio associado'
											placeholder='Selecione'
											onChange={formik.handleChange}
											value={formik.values.spotBuildingId}>
                        {userBuildings && userBuildings.map((building) => {
                          return (
                            <Option key={`building-name-option-${building.name}`} value={building.id}>
												      {building.name}
											      </Option>
                          )
                        })}
										</Select>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.spotBuildingId')} />
									</FormGroup>

									<FormGroup className='mt-12' id='spotType' label='Tipo de spot'>
										<Select
                      id='spotType'
											ariaLabel='Tipo de spot'
											placeholder='Selecione'
											onChange={formik.handleChange}
											value={formik.values.spotType}>
											<Option key={'spot-type-house'} value={'House'}>
												Casa
											</Option>
											<Option key={'spot-type-apartment'} value={'Apartment'}>
												Apartamento
											</Option>
											<Option key={'spot-type-company'} value={'Company'}>
												Empresa
											</Option>
										</Select>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.spotType')} />
									</FormGroup>

									<FormGroup id='spotLogo' label='Logo: ' className='mt-2'>
										<Input
                      id='spotLogo'
											onChange={formik.handleChange}
											value={formik.values.spotLogo}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
									</FormGroup>

									<FormGroup className='mt-12' id='spotStatus' label='Status:'>
										<Select
                      id='spotStatus'
											ariaLabel='Status'
											placeholder='Selecione'
											onChange={formik.handleChange}
											value={formik.values.spotStatus}>
											<Option key={'spot-type-active'} value={'Active'}>
												Ativo
											</Option>
											<Option key={'spot-type-inactive'} value={'Inactive'}>
												Inativo
											</Option>
										</Select>
									</FormGroup>
								</div>
							</form>
						</div>
						<SubmitButton onClick={formik.handleSubmit} buttonId={'spot-form-button'} />
					</CardBody>
				</Card>
			</div>
		</Fragment>
	);
};
