import _isEmpty from 'lodash/isEmpty';

import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../contexts/user-context';
import { getUserGrantTokens } from '../../services/token/token-service';

interface useIsUserManagerParams {
	userId: number;
  role?: string;
}

export function useIsUserManager({
  userId,
  role
}: useIsUserManagerParams): boolean {
	const [isUserAManager, setIsUserAManager] = useState<boolean>(false);
  const { user } = useContext(UserContext)

 

	useEffect(() => {
		const getUserInfo = async () => {
      if (userId !== 0 && user.isSuperUser) {
        setIsUserAManager(true)
      }
			else if (userId !== 0) {
				const grantTokens = await getUserGrantTokens(userId);

        if (!role) {
          setIsUserAManager(!_isEmpty(grantTokens));
        } else {
          const filteredTokensByRole = grantTokens.results.filter((token) => token.role === role)
          setIsUserAManager(!_isEmpty(filteredTokensByRole));
        }
			}
		};

		getUserInfo()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [userId, user.isSuperUser, role]);

	return isUserAManager;
}
