import React, { Fragment, useEffect, useState } from 'react';
import _get from 'lodash/get';

import { CardLabel, CardSubTitle, CardTitle } from '../../bootstrap/Card';

import Avatar from '../../Avatar';

import { Spot } from '../../../type/spot';
import { getSpotById } from '../../../services/spot/spot-service';
import { EmptySpotCard } from './empty-spot-card';
import { Building } from '../../../type/building';
import { getBuildingById } from '../../../services/building/building-service';

import DefaultLogo from '../../../assets/img/utilities/logo_icon.png';
interface TokenManagerHeadlineProps {
	id?: number,
	buildingId: number
}
export const TokenManagerHeadline: React.FC<TokenManagerHeadlineProps> = ({ id, buildingId }) => {
	const [spotData, setSpotData] = useState<Spot>();
	const [buildingData, setBuildingData] = useState<Building>();
	const [buildingLogoUrl, setBuildingLogoUrl] = useState<string>(DefaultLogo);

	useEffect(() => {
		const getSpotData = async () => {
			if (id) {
				const spot = await getSpotById(id);
				setSpotData(spot);
			}
			if (buildingId) {
				const building = await getBuildingById(buildingId);
				setBuildingData(building);

				if (building) {
					const logoSrc = building.logo
					if (logoSrc) {
						setBuildingLogoUrl(logoSrc)
					}
				}
			}
		};

		getSpotData()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [id, buildingId]);

	if (!spotData && !buildingData) {
		return <EmptySpotCard id={id} buildingId={buildingId} />;
	}

	const spotName = _get(spotData, 'name');

	const buildingName = _get(buildingData, 'name');
	const buildingAddress = _get(buildingData, 'address');
	const buildingNumber = _get(buildingData, 'addressNumber');
	const buildingAddressComplement = _get(buildingData, 'adressComplement');

	return (
		<Fragment>
			<div className='d-flex flex-column align-items-center'>
				<div className='mb-4'>
					<Avatar
						srcSet={buildingLogoUrl}
						src={buildingLogoUrl}
						rounded={3}
						size={100}
					/>
				</div>
				<div className='d-flex justify-content-center  align-items-center flex-column'>
					<CardLabel className='justify-content-center'>
						{buildingName && (
							<CardTitle tag='h3' className='h4'>
								{buildingName}
							</CardTitle>
						)}
						{spotName && (
							<CardSubTitle tag='h4' className='h5 align-self-center'>
								{spotName}
							</CardSubTitle>
						)}
					</CardLabel>
					{buildingAddress && (
						<CardSubTitle tag='h5' className='h6 text-muted mt-2 align-self-center'>
							{buildingAddress} - {buildingNumber}
						</CardSubTitle>
					)}
					{buildingAddressComplement && (
						<CardSubTitle tag='h5' className='h6 text-muted mt-1 justify-content-center'>
							{buildingAddressComplement}
						</CardSubTitle>
					)}
				</div>
			</div>
		</Fragment>
	);
};
