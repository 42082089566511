import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoute } from './routes';

export const LoginRedirect: React.FC = () => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate(AppRoute.Login);
	});

	return null;
};
