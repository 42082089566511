import { orderBy } from 'lodash';
import _isEmpty from 'lodash/isEmpty';
import { Token } from '../../../../type/token';
import { CardBody } from "../../../bootstrap/Card";
import { TableColumn } from "../../consult-components/table-components/table-column";
import { InquirySendBPRowTokens } from './inquiry-send-bp-row';

interface InquirySendBPTokensProps {
	items: Array<Token> | undefined;
	isFluid: any;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InquirySendBPTokens: React.FC<InquirySendBPTokensProps> = ({
	items,
	isFluid,
	setRefreshTable
}) => {
	if (_isEmpty(items)) return (
		<CardBody className='table-responsive' isScrollable={isFluid} style={{ height: '40rem' }}>
			<table className='table table-modern table-responsive'>
				<thead>
					<tr key={'user-token-tables-names'}>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Ações</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Motivo</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Selfie</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Usuário</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Destino</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Ativado por</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Documento</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Telefone</TableColumn>
					</tr>
				</thead>
				<tbody>
					<tr style={{ textAlign: 'center' }}>
						<td colSpan={8}>Nenhum novo acesso para enviar BP ainda.</td>
					</tr>
				</tbody>
			</table>
		</CardBody>
	);

	return (
		<CardBody className='table-responsive' isScrollable={isFluid} style={{ height: '40rem' }}>
			<table className='table table-modern table-responsive'>
				<thead>
					<tr key={'user-token-tables-names'}>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Ações</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Motivo</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Selfie</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Usuário</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Destino</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Ativado por</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Documento</TableColumn>
						<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Telefone</TableColumn>
					</tr>
				</thead>
				<tbody>
					{items &&
						orderBy(items, ['issuedAt'], ['desc']).map((item) => {
							return (
								<InquirySendBPRowTokens
									key={`token-row-${item.id}`}
									item={item}
									setRefreshTable={setRefreshTable}
								/>
							);
						})}
				</tbody>
			</table>
		</CardBody>
	)
}