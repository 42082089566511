import api from "../../../../services/api";
import { HTTPUserListResponse } from "../../../../services/user/http-users-response";
import { parseUser } from "../../../../services/user/parsers/user-parser";
import { User } from "../../../../type/user";

async function getUserList() {
    const response = await api.get(`/api/v1/users/?max_results=200`);
    const responseJson = response.data as HTTPUserListResponse;

    return responseJson.results.map(parseUser);
}
async function getUserListByBuildingId(user_id: number): Promise<User[]> {
    const response = await api.get(`/api/v1/users/users-by-same-building/` + user_id)

    let x = response.data.map(parseUser);
    return x;
}

async function getUserListFromSearchStringService(searchString: string) {
    const response = await api.get(`/api/v1/users/?keyword=` + searchString + `&max_results=200`);
    const responseJson = response.data as HTTPUserListResponse;

    return responseJson.results.map(parseUser);
}

async function getDocument(userdocument_user_id: any) {
    const response = await api.get(`/api/v1/userdocuments/user_id/` + userdocument_user_id);

    return response.data;
}

async function deleteDocument(userdocument_id: any) {
    const response = await api.delete(`/api/v1/userdocuments/` + userdocument_id);
    return response.data;
}

async function createDocument(params: any) {
    const response = await api.post(`/api/v1/userdocuments`, JSON.stringify(params), {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    return response.data;
}

async function createSelfie(params: any) {
    const response = await api.put(`/api/v1/users/selfie/`, JSON.stringify(params), {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    return response.data;
}

async function createAnexo(params: any) {
    const response = await api.post(`/api/v1/userdocuments`, JSON.stringify(params), {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    });
    return response.data;
}
export { getUserList, getUserListFromSearchStringService, createDocument, createSelfie, getDocument, createAnexo, deleteDocument, getUserListByBuildingId };