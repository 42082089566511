import { LoggedUser } from '../../type/logged-user';
import { getUserByTaxid } from '../user/user-service';
import { HTTPLoginResponse, HTTPResetPasswordResponse } from './http-login-response';
import api from '../api';
import apiUser from '../../components/entrada-segura/consult-components/services/api-user.service';
import { getHighestRoleByUserId } from '../token/token-service';

interface LoginParams {
	username: string;
	password: string;
}
export const login = async ({ username, password }: LoginParams) => {
	const body = {
		username,
		password,
	};

	return api.post('/api/v1/auth/login', body, {
		headers: {
			'Content-type': 'application/json',
			accept: 'application/json'
		}
	}).then(async (response) => {
		const loginResponse = response.data as HTTPLoginResponse;
		localStorage.setItem('active-token', loginResponse.token);
		localStorage.setItem('logged-user', loginResponse.userInfo.name);
		localStorage.setItem('active-username', username);
		api.defaults.headers.Authorization = `Bearer ${loginResponse.token}`;
		apiUser.defaults.headers.Authorization = `Bearer ${loginResponse.token}`;

		const highestRole = await getHighestRoleByUserId(loginResponse.userInfo.id)
		localStorage.setItem('highest-role', highestRole !== "" ? highestRole : loginResponse.userInfo.role);
	})
};

export const logout = () => {
	localStorage.removeItem('active-token');
	localStorage.removeItem('active-username');
	window.location.assign('/login');
};

export const restoreSession = async (setUser: (loggedUser: LoggedUser) => void) => {
	const username = localStorage.getItem('active-username') || '';
	const user = await getUserByTaxid(username);

	if (user) {
		setUser({
			id: user.id,
			isSuperUser: user.isSuperuser || false,
		});

		return user.id
	}
	throw new Error('Error restoring session');
};

interface ResetPasswordProps {
	username: string;
}
export async function resetPassword({ username }: ResetPasswordProps): Promise<string> {
	try {
		const response = await api.post(`/api/v1/auth/reset-password?username=${username}`, null, {
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
			}
		});
		const responseJson = response.data as HTTPResetPasswordResponse;
		return responseJson.password;
	} catch (error) {
		console.error("Error in resetPassword:", error);
		throw error;
	}
}