import { useEffect, useState } from "react"
import { getTokensBySpotId } from "../../services/token/token-service";
import { TokenList } from "../../type/token";

interface useSpotTokensProps {
    spotId: number,
    perPage: number,
    page: number,
    refreshTable: boolean
}

export function useSpotTokens({ spotId, perPage, page, refreshTable }: useSpotTokensProps) {
    const [tokens, setTokens] = useState<TokenList>();
    const authorizationTypes = ["grant", "checkin"];

    useEffect(() => {
        const getTokens = async () => {
            const data = await getTokensBySpotId({ spotId, perPage, page, authorizationTypes });

            setTokens(data)
        }

        getTokens()
        .then((data) => {
            return data;
        })
        .catch(console.error);
    }, [spotId, perPage, page, refreshTable])

    return tokens
}