export const requiredBuildingFormFields = [
	'name',
	'taxid',
	'businessName',
	'address',
	'neighborhood',
	'city',
	'state',
	'zipcode',
];

export const USER_ROLES = {
	GUEST: 'Guest',
	CONCIERGE: 'Concierge',
	CONTRACT_MANAGER: 'Contract manager',
	ACCESS_MANAGER: 'Access manager',
	SPOT_MANAGER: 'Spot manager',
	AGENT: 'Agent',
	SYSADMIN: 'Sysadmin',
};
