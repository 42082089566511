import { useEffect, useState } from 'react';
import {
	getSpotsCountByStatusInBuilding,
	getSpotsCountByTypeInBuilding,
	getSpotsCountInBuilding,
} from '../../../services/metrics/spots/spots-metrics-service';

interface UseSpotCountByBuildingIdParams {
	buildingId: number | undefined;
}

export function useSpotCountByBuildingId({
	buildingId,
}: UseSpotCountByBuildingIdParams): number | undefined {
	const [count, setCount] = useState<number>();

	useEffect(() => {
		if (buildingId) {
			const getBuildingsData = async () => {
				const data = await getSpotsCountInBuilding(buildingId);
				setCount(data);
			};

			getBuildingsData()
				.then((data) => {
					return data;
				})
				.catch(console.error);
		}
	}, [buildingId]);

	return count;
}

interface UseSpotCountByBuildingIdAndSpotTypeParams {
	buildingId: number | undefined;
	spotType: string | undefined;
}

export function useSpotCountByBuildingIdAndSpotType({
	buildingId,
	spotType,
}: UseSpotCountByBuildingIdAndSpotTypeParams): number | undefined {
	const [count, setCount] = useState<number>();

	useEffect(() => {
		if (buildingId && spotType) {
			const getBuildingsData = async () => {
				const data = await getSpotsCountByTypeInBuilding({ buildingId, spotType });
				setCount(data);
			};

			getBuildingsData()
				.then((data) => {
					return data;
				})
				.catch(console.error);
		}
	}, [buildingId, spotType]);

	return count;
}

interface UseSpotCountByBuildingIdAndSpotStatusParams {
	buildingId: number | undefined;
	spotStatus: string | undefined;
}

export function useSpotCountByBuildingIdAndSpotStatus({
	buildingId,
	spotStatus,
}: UseSpotCountByBuildingIdAndSpotStatusParams): number | undefined {
	const [count, setCount] = useState<number>();

	useEffect(() => {
		if (buildingId && spotStatus) {
			const getBuildingsData = async () => {
				const data = await getSpotsCountByStatusInBuilding({ buildingId, spotStatus });
				setCount(data);
			};

			getBuildingsData()
				.then((data) => {
					return data;
				})
				.catch(console.error);
		}
	}, [buildingId, spotStatus]);

	return count;
}
