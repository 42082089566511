import { useEffect, useState } from "react";
import { getSpotByType } from "../../../../services/spot/spot-service";
import { SpotList } from "../../../../type/spot";
import Card, { CardBody } from "../../../bootstrap/Card";
import Select from "../../../bootstrap/forms/Select";
import Option from '../../../bootstrap/Option';

interface TargetSpotTokenActivationProps {
    buildingId: number;
    setTargetSpot: React.Dispatch<React.SetStateAction<string[]>>;
    targetSpot: string[];
}

export function TargetSpotTokenActivationForSeasonalService({ buildingId, setTargetSpot, targetSpot }: TargetSpotTokenActivationProps) {
    const [spots, setSpots] = useState<SpotList>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getSpotByType('Season', buildingId).then((spots) => {
            setSpots(spots)
            setIsLoading(false)
        })
    }, [buildingId])

    const handleMultiSetTargetSpot = (e: { target: { value: string; }; }) => {
        const selectedSpotId = e.target.value
        const index = targetSpot.indexOf(selectedSpotId)

        if (index !== -1) {
            const newArray = [...targetSpot];
            newArray.splice(index, 1);
            setTargetSpot(newArray);
        } else {
            setTargetSpot([...targetSpot, selectedSpotId]);
        }
    }

    return (
        <Card>
            <CardBody>
                <div className='row'>
                    {spots.length > 0 &&
                        <>
                            <div className='fw-bold fs-5 me-2 d-flex justify-content-center'>
                                Qual a Unidade?
                            </div>
                            <div className='d-flex justify-content-center mt-3'>
                                <Select
                                    value={targetSpot}
                                    multiple={true}
                                    ariaLabel='Board select'
                                    placeholder='Selecione a Unidade'
                                    onChange={handleMultiSetTargetSpot}
                                >
                                    {spots?.map((spot) => (
                                        <Option key={spot.id} value={spot.id}>
                                            {spot.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                        </>
                    }

                    {spots.length <= 0 && !isLoading &&
                        <>
                            <div className='fw-bold fs-5 me-2 d-flex justify-content-center text-center'>
                                Nenhum Lote disponível para utilizar. Entre em contato com o suporte Entrada Segura.
                            </div>
                        </>
                    }

                    {isLoading &&
                        <>
                            <div className='fw-bold fs-5 me-2 d-flex justify-content-center text-center'>
                                Carregando lotes disponíveis...
                            </div>
                        </>
                    }
                </div>
            </CardBody>
        </Card>
    )
}