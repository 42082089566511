import React, { createContext, ReactNode, useEffect, useState } from 'react';
import api from '../services/api';
import { LoggedUser } from '../type/logged-user';

type PropsUserContext = {
	user: LoggedUser;
	setUser: (user: LoggedUser) => void;
};

const DEFAULT_VALUE = {
	user: {
		id: 0,
		isSuperUser: false,
	},
	setUser: () => {},
};

export const UserContext = createContext<PropsUserContext>(DEFAULT_VALUE);

interface UserContextProviderProps {
	children: ReactNode;
}
export const UserContextProvider: React.FC<UserContextProviderProps> = ({ children }) => {
	const [user, setUser] = useState(DEFAULT_VALUE.user);
	
	return (
		<UserContext.Provider
			value={{
				user,
				setUser,
			}}>
			{children}
		</UserContext.Provider>
	);
};
