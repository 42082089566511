import { useEffect, useState } from 'react';

import _isEmpty from 'lodash/isEmpty'
import { getBuildingById, getBuildingList } from '../../services/building/building-service';
import { getBuildingsTokensByUser } from '../../services/token/token-service';
import { USER_ROLES_LOWER, USER_ROLES_BR } from '../../components/entrada-segura/token/token-activation-form/blocks/constants';

interface useUserBuildingsParams {
	userId: number;
  isSuperUser?: boolean
}

type BuildingRole = {
  buildingName: string;
  role: string;
}

const humanizeRole = (role: string | null) => {
  switch(role) {
    case USER_ROLES_LOWER.SYSADMIN:
      return USER_ROLES_BR.SYSADMIN;
    case USER_ROLES_LOWER.CONTRACT_MANAGER:
      return USER_ROLES_BR.CONTRACT_MANAGER;
    case USER_ROLES_LOWER.STAFF:
      return USER_ROLES_BR.STAFF;
    case USER_ROLES_LOWER.SPOT_MANAGER:
      return USER_ROLES_BR.SPOT_MANAGER;
    case USER_ROLES_LOWER.ACCESS_MANAGER:
      return USER_ROLES_BR.ACCESS_MANAGER;
    case USER_ROLES_LOWER.GUEST:
      return USER_ROLES_BR.GUEST;
    case USER_ROLES_LOWER.CONCIERGE:
      return USER_ROLES_BR.CONCIERGE;
    default:
      return ''
  }
}
export function useUserBuildingsWithRoles({ userId, isSuperUser }: useUserBuildingsParams): Array<BuildingRole> {
	const [userData, setUserData] = useState<Array<BuildingRole>>([]);

	useEffect(() => {
		const getUserInfo = async () => {
			if (isSuperUser) {
        const buildings = await getBuildingList();

        const buildingRoles = buildings.map((building) => ({
          buildingName: building.name,
          role: USER_ROLES_BR.SYSADMIN
        }))

				setUserData(buildingRoles);
      } else if (userId !== 0) {
				const tokens = await getBuildingsTokensByUser(userId);
        
        if (_isEmpty(tokens)) {
          setUserData([]);
        } else {
          const filteredTokens = tokens.filter((token) => !!token.buildingId)

          const buildingRoles = await Promise.all(
            filteredTokens.map(async (token) =>  {
              const buildingData = await getBuildingById(token.buildingId)
  
              return {
                buildingName: buildingData.name,
                role: humanizeRole(token.role)
              } as BuildingRole;
          }));
  
          setUserData(buildingRoles);
        }
			}
		};

		getUserInfo()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [userId, isSuperUser]);

	return userData;
}
