import { CardBody, CardTitle } from '../../../../components/bootstrap/Card';
import {
	useSpotCountByBuildingIdAndSpotStatus,
	useSpotCountByBuildingIdAndSpotType,
} from '../../../../hooks/entrada-segura/metrics/use-spots-count';
import { useBuildingName } from '../../../../hooks/entrada-segura/use-building-name';
import { ItemHeadline } from './item-headline';

type NumberOrUndefined = number | undefined;
interface BuildingSpotMetricsProps {
	buildingIds: Array<NumberOrUndefined>;
}

export const BuildingSpotMetrics: React.FC<BuildingSpotMetricsProps> = ({ buildingIds }) => {
	return (
		<>
			<CardBody style={{ maxWidth: '1000px' }}>
				<CardTitle>Locais</CardTitle>
				<table className='table-responsive table-modern table-hover'>
					<thead>
						<tr>
							<ItemHeadline>Condomínio</ItemHeadline>
							<ItemHeadline># Residenciais</ItemHeadline>
							<ItemHeadline># Comerciais </ItemHeadline>
							<ItemHeadline># Ativos </ItemHeadline>
							<ItemHeadline># Inativos</ItemHeadline>
						</tr>
					</thead>
					<tbody>
						{buildingIds &&
							buildingIds.map((buildingId) => {
								return <BuildingSpotsMetricsRow key={`building-spot-metrics-${buildingId}`} buildingId={buildingId} />;
							})}
					</tbody>
				</table>
			</CardBody>
		</>
	);
};

interface BuildingSpotsMetricsRowProps {
	buildingId: NumberOrUndefined;
}
const BuildingSpotsMetricsRow = ({ buildingId }: BuildingSpotsMetricsRowProps) => {
	const buildingName = useBuildingName({ buildingId });
	const residentialSpotsCount = useSpotCountByBuildingIdAndSpotType({
		buildingId,
		spotType: 'residential',
	});
	const comercialSpotsCount = useSpotCountByBuildingIdAndSpotType({
		buildingId,
		spotType: 'comercial',
	});
	const activeSpotsCount = useSpotCountByBuildingIdAndSpotStatus({
		buildingId,
		spotStatus: 'active',
	});
	const inactiveSpotsCount = useSpotCountByBuildingIdAndSpotStatus({
		buildingId,
		spotStatus: 'inactive',
	});

	return (
		<tr key={`buildind-spots-metrics-${buildingId}`}>
			<td>{buildingName}</td>
			<td>{residentialSpotsCount}</td>
			<td>{comercialSpotsCount}</td>
			<td>{activeSpotsCount}</td>
			<td>{inactiveSpotsCount}</td>
		</tr>
	);
};
