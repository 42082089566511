import { Spot } from '../../../type/spot';
import { HTTPSpotResponse } from '../http-spot-response';

export function parseSpot(httpSpot: HTTPSpotResponse): Spot {
	return {
		id: httpSpot.id,
		name: httpSpot.name,
		buildingId: httpSpot.building_id,
		spotType: httpSpot.spot_type,
		spotLogo: httpSpot.spot_logo,
		status: httpSpot.status,
	};
}
