import { useEffect, useState } from "react";
import { getBoardingPassByTokenId } from "../../services/boarding-pass/boarding-pass-service";

interface UseBoardingPassByTokenIdParams {
	tokenId: string;
}

export function useBoardingPassByTokenId({ tokenId }: UseBoardingPassByTokenIdParams): boolean {
    const [tokenHasBoardingPass, setTokenHasBoardingPass] = useState<boolean>(false);

	useEffect(() => {
		const getBuildingsData = async () => {
			const data = await getBoardingPassByTokenId(tokenId);
            if (data === null) {
                setTokenHasBoardingPass(false)
            }
			else {
				setTokenHasBoardingPass(true)
			}

		};

		getBuildingsData()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [tokenId]);

	return tokenHasBoardingPass;
}