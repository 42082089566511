import { Fragment, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _get from 'lodash/get';

import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import { SubmitButton } from '../submit-button-create-form';
import showNotification from '../../../../components/extras/showNotification';
import moment from 'moment';
import Select from '../../../../components/bootstrap/forms/Select';
import Option from '../../../../components/bootstrap/Option';
import Checks from '../../../../components/bootstrap/forms/Checks';
import { createGate } from '../../../../services/gate/gate-service';
import Popovers from '../../../../components/bootstrap/Popovers';
import Icon from '../../../../components/icon/Icon';
import { UserContext } from '../../../../contexts/user-context';
import { RequiredFieldMessage } from '../required-field-message';

export const GateForm = () => {
	const { user } = useContext(UserContext);
	const activeUserId = user.id;

	const formik = useFormik({
		initialValues: {
			gateName: '',
			gateBuildingId: '',
			gateType: '',
			isIOT: true,
			hasIntegration: true,
			iotManufacturer: '',
			iotModel: '',
			apiEndpoint: '',
			apiUser: '',
			apiPassword: '',
			apiToken: '',
			gateStatus: '',
		},

		validationSchema: Yup.object({
			gateName: Yup.string().label('Nome').required('Nome é um campo obrigatório'),
			gateBuildingId: Yup.number()
				.label('ID do condomínio')
				.required('ID do condomínio é um campo obrigatório'),
		}),

		onSubmit: async (values) => {
			const createdBy = activeUserId;
			const createdAt = moment().toISOString();
			const buildingId = values.gateBuildingId ? parseInt(values.gateBuildingId, 10) : 0;

			try {
				await createGate({
					name: values.gateName,
					buildingId,
					gateType: values.gateType,
					isOT: values.isIOT,
					hasIntegration: values.hasIntegration,
					iotManufacturer: values.iotManufacturer,
					iotModel: values.iotModel,
					apiEndpoint: values.apiEndpoint,
					apiUser: values.apiUser,
					apiPassword: values.apiPassword,
					apiToken: values.apiToken,
					status: values.gateStatus,
					createdBy,
					createdAt,
				});

				showNotification(`Gate criado com sucesso!`, ``, 'success');
				formik.resetForm();
			} catch (error) {
				console.error('Error creating gate: ', error);
				showNotification(`Erro ao criar gate. Por favor, tente novamente.`, ``, 'danger');
			}
		},
	});

	return (
		<Fragment>
			<div key={'gate-form'} className={'col-12'}>
				<Card className='table-responsive' borderSize={3}>
					<CardHeader borderSize={2}>
						<CardLabel icon='Lock' iconColor='danger'>
							<CardTitle>Criar Portaria</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody>
						<div className='row g-3'>
							<form className='row g-3' onSubmit={formik.handleSubmit} style={{ fontSize: '15px' }}>
								<div className='col-12 d-flex flex-column justify-content-center'>
									<FormGroup id='gateName' label='Nome: ' className='mt-2'>
										<Input
                      id='gateName'
											onChange={formik.handleChange}
											value={formik.values.gateName}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.gateName')} />
									</FormGroup>

									<FormGroup
										id='gateBuildingId'
										label='ID do condomínio associado: '
										className='mt-2'>
										<Input
                      id='gateBuildingId'
											onChange={formik.handleChange}
											value={formik.values.gateBuildingId}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.gateBuildingId')} />
									</FormGroup>

									<FormGroup className='mt-12' id='gateType' label='Tipo de gate'>
										<Select
                      id='gateType'
											ariaLabel='Tipo de gate'
											placeholder='Selecione'
											onChange={formik.handleChange}
											value={formik.values.gateType}>
											<Option key={'gate-type-gatehouse'} value={'Gatehouse'}>
												Gatehouse
											</Option>
											<Option key={'gate-type-gate'} value={'Gate'}>
												Gate
											</Option>
											<Option key={'gate-type-standalone'} value={'Standalone totem'}>
												Standalone totem
											</Option>
										</Select>
									</FormGroup>
									<FormGroup id='isIOT' style={{ marginTop: '15px' }}>
										<Checks
											id='isIOT'
											type='switch'
											label={
												<>
													É IOT
													<Popovers trigger='hover' desc='Indica se o portão é IOT'>
														<Icon icon='Help' size='lg' className='ms-1 cursor-help' />
													</Popovers>
												</>
											}
											checked={formik.values.isIOT}
											onChange={formik.handleChange}
										/>
									</FormGroup>
									<FormGroup
										id='hasIntegration'
										style={{ marginTop: '15px', marginBottom: '15px' }}>
										<Checks
											id='hasIntegration'
											type='switch'
											label={
												<>
													Possui integração
													<Popovers trigger='hover' desc='Possui integração'>
														<Icon icon='Help' size='lg' className='ms-1 cursor-help' />
													</Popovers>
												</>
											}
											onChange={formik.handleChange}
											checked={formik.values.hasIntegration}
										/>
									</FormGroup>

                  <FormGroup id='iotManufacturer' label='Fabricante IOT: ' className='mt-2'>
										<Select
                      id='iotManufacturer'
											ariaLabel='Fabricante IOT'
											placeholder='Selecione'
											onChange={formik.handleChange}
											value={formik.values.iotManufacturer}>
											<Option key={'spot-type-house'} value={'Intelbras'}>
                        Intelbras
											</Option>
											<Option key={'spot-type-apartment'} value={'Hikvision'}>
                        Hikvision
											</Option>
										</Select>
									</FormGroup>

									<FormGroup id='apiUser' label='Usuário API: ' className='mt-2'>
										<Input
                      id='apiUser'
											onChange={formik.handleChange}
											value={formik.values.apiUser}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
									</FormGroup>
									<FormGroup id='apiPassword' label='Senha API: ' className='mt-2'>
										<Input
                      id='apiPassword'
											onChange={formik.handleChange}
											value={formik.values.apiPassword}
											type='password'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
									</FormGroup>
									<FormGroup id='apiToken' label='Token da API: ' className='mt-2'>
										<Input
                      id='apiToken'
											onChange={formik.handleChange}
											value={formik.values.apiToken}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
									</FormGroup>

									<FormGroup className='mt-12' id='gateStatus' label='Status:'>
										<Select
                      id='gateStatus'
											ariaLabel='Status'
											placeholder='Selecione'
											onChange={formik.handleChange}
											value={formik.values.gateStatus}>
											<Option key={'building-type-active'} value={'Active'}>
												Ativo
											</Option>
											<Option key={'building-type-inactive'} value={'Inactive'}>
												Inativo
											</Option>
										</Select>
									</FormGroup>
								</div>
							</form>
						</div>
						<SubmitButton onClick={formik.handleSubmit} buttonId={'gate-form-button'} />
					</CardBody>
				</Card>
			</div>
		</Fragment>
	);
};
