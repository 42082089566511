import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import _get from 'lodash/get';

import { CardBody } from '../../../../components/bootstrap/Card';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Select from '../../../../components/bootstrap/forms/Select';
import Option from '../../../../components/bootstrap/Option';
import { createUser } from '../../../../services/user/user-service';
import showNotification from '../../../../components/extras/showNotification';

import { USER_ROLES } from '../business-rules/business-rules';
import { SubmitButton } from '../submit-button-create-form';
import { useContext } from 'react';
import { UserContext } from '../../../../contexts/user-context';
import { RequiredFieldMessage } from '../required-field-message';


export const UserForm = () => {
	const { user } = useContext(UserContext);
	const activeUserId = user.id;

	const formik = useFormik({
		initialValues: {
      userName: '',
			userTaxid: '',
			telephone: '',
			email: '',
			selfie: '',
			userRole: 'guest',
			birthdate: '',
			fatherName: '',
			motherName: '',
			userDocumentId: '',
			userStatus: 'active',
			isSuperUser: false,
			inquiry_status: 'New',
			observation: '',
    },

		validationSchema: Yup.object({
			userName: Yup.string()
				.label('Nome')
				.required('Nome é um campo obrigatório')
				.test('is-full-name', 'Por favor, insira o nome e o sobrenome', function (value) {
					const nameArr = value?.split(' ');
					return !!(nameArr && nameArr.length >= 2);
				}),
			userTaxid: Yup.string()
				.label('CPF')
				.required('CPF é um campo obrigatório')
				.test('is-valid-taxid', 'Insira o CPF com apenas números', function (value) {
					const hasDot = value?.includes('.');
					const hasDash = value?.includes('-');
					const isValid = !hasDot && !hasDash;
					return isValid;
				}),
			userDocumentId: Yup.string()
				.label('RG')
				.required('RG é um campo obrigatório')
				.test('is-valid-document', 'Insira o RG com apenas números', function (value) {
					const hasDot = value?.includes('.');
					const hasDash = value?.includes('-');
					const isValid = !hasDot && !hasDash;
					return isValid;
				}),
			telephone: Yup.string()
				.label('Telefone')
				.required('Telefone é um campo obrigatório')
				.test('is-valid-telephone', 'Insira o telefone com apenas números', function (value) {
					const hasDot = value?.includes('.');
					const hasDash = value?.includes('-');
					const isValid = !hasDot && !hasDash;
					return isValid;
				}),
			email: Yup.string().email().required(),
			selfie: Yup.string().url(),
			birthdate: Yup.date(),
		}),

		onSubmit: async (values) => {
			const createdBy = activeUserId;
			const createdAt = moment().toISOString();
			const isSuperuser = !!(values.userRole === USER_ROLES.SYSADMIN);

			try {
				await createUser({
					name: values.userName,
					taxid: values.userTaxid,
					telephone: values.telephone,
					email: values.email,
					selfie: values.selfie,
					role: values.userRole,
					birthdate: values.birthdate,
					fatherName: values.fatherName,
					motherName: values.motherName,
					documentId: values.userDocumentId,
					status: values.userStatus,
					isSuperuser,
					createdBy,
					createdAt,
					inquiry_status: values.inquiry_status,
					observation: values.observation,
				});

				showNotification(`Usuário criado com sucesso!`, ``, 'success');
				formik.resetForm();
			} catch (error) {
				console.error('Error creating user: ', error);
				showNotification(`Erro ao criar usuário. Por favor, tente novamente.`, ``, 'danger');
			}
		},
	});

	return (
		<CardBody>
			<div className='row g-3'>
				<form className='row g-3' onSubmit={formik.handleSubmit} style={{ fontSize: '15px' }}>
					<div className='col-12 d-flex flex-column justify-content-center'>
						<FormGroup id='userName' label='Nome: ' className='mt-2'>
							<Input
                id='userName'
								onChange={formik.handleChange}
								value={formik.values.userName}
								type='text'
								style={{
									backgroundColor: 'transparent!important',
									borderColor: 'transparent!important',
								}}
							/>
							<RequiredFieldMessage fieldError={_get(formik, 'errors.userName')} />
						</FormGroup>

						<FormGroup id='userTaxid' label='CPF: ' className='mt-2'>
							<Input
                id='userTaxid'
								onChange={formik.handleChange}
								value={formik.values.userTaxid}
								type='text'
								style={{
									backgroundColor: 'transparent!important',
									borderColor: 'transparent!important',
								}}
							/>
							<RequiredFieldMessage fieldError={_get(formik, 'errors.userTaxid')} />
						</FormGroup>

						<FormGroup id='userDocumentId' label='RG: ' className='mt-2'>
							<Input
                id='userDocumentId'
								onChange={formik.handleChange}
								value={formik.values.userDocumentId}
								type='text'
								style={{
									backgroundColor: 'transparent!important',
									borderColor: 'transparent!important',
								}}
							/>
							<RequiredFieldMessage fieldError={_get(formik, 'errors.userDocumentId')} />
						</FormGroup>

						<FormGroup id='telephone' label='Telefone: ' className='mt-2'>
							<Input
                id='telephone'
								onChange={formik.handleChange}
								value={formik.values.telephone}
								type='text'
								style={{
									backgroundColor: 'transparent!important',
									borderColor: 'transparent!important',
								}}
							/>
							<RequiredFieldMessage fieldError={_get(formik, 'errors.telephone')} />
						</FormGroup>

						<FormGroup id='email' label='E-mail: ' className='mt-2'>
							<Input
                id='email'
								onChange={formik.handleChange}
								value={formik.values.email}
								type='email'
								style={{
									backgroundColor: 'transparent!important',
									borderColor: 'transparent!important',
								}}
							/>
						</FormGroup>

						<FormGroup id='selfie' label='Selfie: ' className='mt-2'>
							<Input
                id='selfie'
								onChange={formik.handleChange}
								value={formik.values.selfie}
								type='text'
								style={{
									backgroundColor: 'transparent!important',
									borderColor: 'transparent!important',
								}}
							/>
						</FormGroup>

						<FormGroup className='mt-12' id='userRole' label='Papel'>
							<Select
                id='userRole'
								ariaLabel='Papel do usuário'
								placeholder='Selecione'
								onChange={formik.handleChange}
								value={formik.values.userRole}>
								<Option key={'user-role-guest'} value={USER_ROLES.GUEST}>
									Convidado
								</Option>
								<Option key={'user-role-concierge'} value={USER_ROLES.CONCIERGE}>
									Porteiro
								</Option>
								<Option key={'user-role-agent'} value={USER_ROLES.AGENT}>
									Agente
								</Option>
								<Option key={'user-role-sysadmin'} value={USER_ROLES.SYSADMIN}>
									Administrador do sistema
								</Option>
							</Select>
						</FormGroup>

						<FormGroup id='birthdate' label='Data de nascimento: ' className='mt-2'>
							<Input
                id='birthdate'
								onChange={formik.handleChange}
								value={formik.values.birthdate}
								type='date'
								style={{
									backgroundColor: 'transparent!important',
									borderColor: 'transparent!important',
								}}
							/>
						</FormGroup>

						<FormGroup id='motherName' label='Nome da mãe: ' className='mt-2'>
							<Input
                id='motherName'
								onChange={formik.handleChange}
								value={formik.values.motherName}
								type='text'
								style={{
									backgroundColor: 'transparent!important',
									borderColor: 'transparent!important',
								}}
							/>
						</FormGroup>

						<FormGroup id='fatherName' label='Nome do pai: ' className='mt-2'>
							<Input
                id='fatherName'
								onChange={formik.handleChange}
								value={formik.values.fatherName}
								type='text'
								style={{
									backgroundColor: 'transparent!important',
									borderColor: 'transparent!important',
								}}
							/>
						</FormGroup>

						<FormGroup className='mt-12' id='userStatus' label='Status:'>
							<Select
                id='userStatus'
								ariaLabel='Status'
								placeholder='Selecione'
								onChange={formik.handleChange}
								value={formik.values.userStatus}>
								<Option key={'user-type-active'} value={'Active'}>
									Ativo
								</Option>
								<Option key={'user-type-inactive'} value={'Inactive'}>
									Inativo
								</Option>
							</Select>
						</FormGroup>
					</div>
				</form>
			</div>
			<SubmitButton onClick={formik.handleSubmit} buttonId={'user-form-button'} />
		</CardBody>
	);
};
