import Button from "../../../bootstrap/Button"

import { GuestInfoType } from '../../../../type/guest-info';
import '../../../../styles/components/onboarding/_guest-info.scss';
import { formatCpf, formatDate } from "../../../../helpers/helpers";
import { useContext, useState } from "react";
import { UserContext } from "../../../../contexts/user-context";
import { createAccessLog, updateAccessLogById } from "../../../../services/access-logs/access-logs-service";
import showNotification from "../../../extras/showNotification";
import moment from "moment";
import 'moment/locale/pt-br';
import { AccessLog } from "../../../../type/access-log";
import Select from "../../../bootstrap/forms/Select";
import Option from '../../../bootstrap/Option';
import { GateList } from "../../../../type/gate";
import Modal, { ModalBody, ModalHeader, ModalTitle } from "../../../bootstrap/Modal";
import Input from "../../../bootstrap/forms/Input";

interface props {
    guestInfo: GuestInfoType | null;
    accessLog: AccessLog | null;
    gates: GateList | null;
}

export const GuestInfo = ({ guestInfo, accessLog, gates }: props) => {
    const { user } = useContext(UserContext);
    const [gate, setGate] = useState<number>(0);
    const [justificativa, setJustificativa] = useState<string | undefined>('');
    const activeUserId = user.id;
    const [isJustificativaOpen, setIsJustificativaOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [isConfirmLiberarOpen, setIsConfirmLiberarOpen] = useState(false);

    const handleChangeGate = (e: { target: { value: string; }; }) => {
        if (!validateGate(parseInt(e.target.value))) {
            return showNotification(`Por favor, selecione um Gate para este convidado`, ``, 'danger');
        }

        const gateSelected = parseInt(e.target.value);
        setGate(gateSelected);
    }

    function validateGate(idGate: number) {
        if (idGate)
            return true;

        return false
    }

    const handleChangeJustificativa = (e: { target: { value: any; }; }) => {
        const justificativaText = e.target.value;
        setJustificativa(justificativaText);
    }

    const confirmJustificativa = () => {
        setIsJustificativaOpen(false);
        rejectGuest();
    }

    const handleOpenConfirmLiberar = () => {
        setIsConfirmLiberarOpen(true);
    }

    const cancelLiberar = () => {
        setIsConfirmLiberarOpen(false);
        showNotification(`Liberação cancelada pelo usuário!`, ``, 'danger');
    }

    const confirmLiberar = () => {
        setIsConfirmLiberarOpen(false);
        approveGuest();
    }

    function requireJustificativa() {
        setIsJustificativaOpen(true);
    }

    async function approveGuest() {
        if (!gate) {
            return showNotification(`Por favor, selecione um Gate para este convidado`, ``, 'danger');
        }

        const createdBy = activeUserId;
        const createdAt = moment().toISOString(true);
        const onboardingAt = moment().toISOString(true);
        const modifiedAt = moment().toISOString(true);
        const modifiedBy = activeUserId;
        const checkoutAt = moment().toISOString(true);

        try {
            //If not exist, create a new one
            if (accessLog?.id === null) {
                await createAccessLog({
                    tokenId: accessLog?.tokenId,
                    buildingId: accessLog?.buildingId === null ? 0 : accessLog?.buildingId,
                    spotId: accessLog?.spotId === null ? 0 : accessLog?.spotId,
                    authorizationChain: accessLog?.authorizationChain,
                    onboardingGateId: gate,
                    onboardingAt: onboardingAt,
                    conciergeUserId: createdBy,
                    checkoutGateId: null,
                    checkoutAt: null,
                    rejectedAt: null,
                    description: "",
                    controlStatus: "Started",
                    status: "Active",
                    createdAt: createdAt,
                    createdBy: createdBy,
                    modifiedAt: null,
                    modifiedBy: 0,
                });
            }
            //Otherwise, update it
            else {
                await updateAccessLogById({
                    id: accessLog?.id,
                    tokenId: accessLog?.tokenId,
                    buildingId: accessLog?.buildingId === null ? 0 : accessLog?.buildingId,
                    spotId: accessLog?.spotId === null ? 0 : accessLog?.spotId,
                    authorizationChain: accessLog?.authorizationChain,
                    onboardingGateId: accessLog?.onboardingGateId,
                    onboardingAt: accessLog?.onboardingAt,
                    conciergeUserId: accessLog?.conciergeUserId,
                    checkoutGateId: gate,
                    checkoutAt: checkoutAt,
                    rejectedAt: accessLog?.rejectedAt === "" ? null : accessLog?.rejectedAt,
                    description: accessLog?.description,
                    controlStatus: "Concluded",
                    status: "Active",
                    createdAt: accessLog?.createdAt === "" ? null : accessLog?.createdAt,
                    createdBy: accessLog?.createdBy,
                    modifiedAt: modifiedAt,
                    modifiedBy: modifiedBy,
                });
            }

            showNotification(`Convidado aprovado!`, ``, 'success');
            setSuccess(true);
            setSuccessMessage("Usuário liberado!")
            setTimeout(function () {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error creating user: ', error);
            showNotification(`Erro ao aprovar o convidado. Por favor, tente novamente.`, ``, 'danger');
            setSuccess(false);
        }
    }

    async function rejectGuest() {
        if (!gate) {
            return showNotification(`Por favor, selecione um Gate para este convidado`, ``, 'danger');
        }

        const createdBy = activeUserId;
        const createdAt = moment().toISOString(true);
        const onboardingAt = moment().toISOString(true);
        const rejectedAt = moment().toISOString(true);
        const modifiedAt = moment().toISOString(true);
        const modifiedBy = activeUserId;
        const checkoutAt = moment().toISOString(true);

        try {
            //If not exist, create a new one
            if (accessLog?.id === null) {
                await createAccessLog({
                    tokenId: accessLog?.tokenId,
                    buildingId: accessLog?.buildingId === null ? 0 : accessLog?.buildingId,
                    spotId: accessLog?.spotId === null ? 0 : accessLog?.spotId,
                    authorizationChain: accessLog?.authorizationChain,
                    onboardingGateId: gate,
                    onboardingAt: onboardingAt,
                    conciergeUserId: createdBy,
                    checkoutGateId: null,
                    checkoutAt: null,
                    rejectedAt: rejectedAt,
                    description: justificativa,
                    controlStatus: "Rejected by Gate Device",
                    status: "Active",
                    createdAt: createdAt,
                    createdBy: createdBy,
                    modifiedAt: null,
                    modifiedBy: 0,
                });
            }
            //Otherwise, update it
            else {
                await updateAccessLogById({
                    id: accessLog?.id,
                    tokenId: accessLog?.tokenId,
                    buildingId: accessLog?.buildingId === null ? 0 : accessLog?.buildingId,
                    spotId: accessLog?.spotId === null ? 0 : accessLog?.spotId,
                    authorizationChain: accessLog?.authorizationChain,
                    onboardingGateId: accessLog?.onboardingGateId,
                    onboardingAt: accessLog?.onboardingAt,
                    conciergeUserId: accessLog?.conciergeUserId,
                    checkoutGateId: gate,
                    checkoutAt: checkoutAt,
                    rejectedAt: accessLog?.rejectedAt === "" ? null : accessLog?.rejectedAt,
                    description: justificativa,
                    controlStatus: "Rejected by Gate Device",
                    status: "Active",
                    createdAt: accessLog?.createdAt === "" ? null : accessLog?.createdAt,
                    createdBy: accessLog?.createdBy,
                    modifiedAt: modifiedAt,
                    modifiedBy: modifiedBy,
                });
            }

            showNotification(`Convidado rejeitado!`, ``, 'success');
            setSuccess(true);
            setSuccessMessage("Usuário rejeitado!")
            setTimeout(function () {
                window.location.reload();
            }, 3000);
        } catch (error) {
            console.error('Error creating user: ', error);
            showNotification(`Erro ao rejeitar o convidado. Por favor, tente novamente.`, ``, 'danger');
            setSuccess(false);
        }
    }

    return (
        <>
            {guestInfo !== null &&
                <div className='row d-flex flex-row justify-content-start align-items-center'>
                    <div id="guest-identification" className='w-auto d-flex flex-column justify-content-start align-items-start gap-2 col-xl-6 px-30'>
                        <h2 style={{textAlign: 'center'}}>{guestInfo?.guestName}</h2>
                        <h4>{guestInfo?.guestRole?.toUpperCase()}</h4>
                        <h6>CPF: {formatCpf(guestInfo?.guestDocument)}</h6>
                        <img
                            className='rounded-circle bg-lo25-success fit-image guest-selfie'
                            src={guestInfo?.guestSelfie}
                            alt="Selfie do Convidado"
                            srcSet={guestInfo?.guestSelfie}
                        />
                    </div>
                    <div id="guest-details" className='w-auto d-flex flex-column justify-content-start align-items-start gap-2 col-xl-6 px-30'>
                        <h2>Autorização de acesso</h2>
                        <p>Autorizado por: {`${guestInfo?.guestIssuedBy} - ${formatDate(guestInfo?.guestIssuedAt)}`}</p>
                        <h2>Detalhes de entrada</h2>
                        <h3><strong>Destino</strong></h3>
                        <p>{guestInfo?.buildingName}</p>
                        <p>{guestInfo?.spotName}</p>
                        {success === false ?
                            <div>
                                <h3>Informe o Gate do Convidado</h3>
                                <Select
                                    ariaLabel='Board select'
                                    placeholder='Selecione o Gate'
                                    onChange={handleChangeGate}>
                                    {gates?.map((gate) => (
                                        <Option key={gate.id} value={gate.id}>
                                            {gate.name}
                                        </Option>
                                    ))}
                                </Select>
                                <div className='d-flex flex-row gap-3 mt-2'>
                                    <Button
                                        onClick={() => handleOpenConfirmLiberar()}
                                        className="btn btn-primary">
                                        Liberar
                                    </Button>
                                    <Button
                                        onClick={() => requireJustificativa()}
                                        className="btn btn-secondary">
                                        Rejeitar
                                    </Button>
                                </div>
                            </div>
                            :
                            <div>
                                <h3>{successMessage}</h3>
                            </div>
                        }
                    </div>
                    <Modal
                        setIsOpen={setIsJustificativaOpen}
                        isOpen={isJustificativaOpen}
                        fullScreen={'lg'}
                        isCentered
                        titleId='justificativa-modal'>
                        <ModalHeader setIsOpen={setIsJustificativaOpen}>
                            <ModalTitle id='justificativa-modal'>Justificativa</ModalTitle>
                        </ModalHeader>
                        <ModalBody className='h-100 d-flex align-items-center justify-content-center flex-column'>
                            <div className='row w-100 d-flex align-items-center justify-content-center flex-column'>
                                <h6>
                                    Você está <strong style={{color: '#C0392B'}}>rejeitando</strong> o acesso de {guestInfo?.guestName}
                                </h6>
                                <p>Por favor, informe uma justificativa!</p>
                                <Input
                                    id='justificativaInput'
                                    type='text'
                                    onChange={(e) => handleChangeJustificativa(e as any)}
                                    autoComplete='off'
                                    placeholder='Justificativa'
                                    className="mt-4"
                                    value={justificativa}
                                />
                                <Button
                                    onClick={confirmJustificativa}
                                    className="btn btn-primary mt-4">
                                    Continuar
                                </Button>
                            </div>
                        </ModalBody>
                    </Modal>
                    <Modal
                        setIsOpen={setIsConfirmLiberarOpen}
                        isOpen={isConfirmLiberarOpen}
                        fullScreen={'lg'}
                        isCentered
                        titleId='confirm-modal'>
                        <ModalHeader setIsOpen={setIsConfirmLiberarOpen}>
                            <ModalTitle id='confirm-modal'>Confirmar liberação</ModalTitle>
                        </ModalHeader>
                        <ModalBody className='h-100 d-flex align-items-center justify-content-center flex-column'>
                            <div className='row w-100 d-flex align-items-center justify-content-center flex-column'>
                                <h6>
                                    Você está <strong style={{color: '#27AE60'}}>liberando</strong> o acesso de {guestInfo?.guestName}
                                </h6>
                                <p>Deseja continuar?</p>
                                <div className='d-flex flex-row gap-3 mt-2'>
                                    <Button
                                        onClick={confirmLiberar}
                                        className="btn btn-primary">
                                        Continuar
                                    </Button>
                                    <Button
                                        onClick={cancelLiberar}
                                        className="btn btn-secondary">
                                        Cancelar
                                    </Button>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            }
        </>
    )
}