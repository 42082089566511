import { useContext, useEffect, useState } from 'react';

import _isEmpty from 'lodash/isEmpty';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { CardHeader } from '../../../components/bootstrap/Card';

import { getHighestTagByUserId, getTokensActivate, getUserGrantTokens } from '../../../services/token/token-service';
import { Token } from '../../../type/token';
import Page from '../../../layout/Page/Page';
import './activate.css';
import { UserContext } from '../../../contexts/user-context';
import { useUserHighestRole } from '../../../hooks/entrada-segura/use-user-highest-role';
import { TokenToActivateSpotManager } from '../../../components/entrada-segura/activate/spot-manager/token-to-activate-spot-manager';
import Modal, { ModalBody, ModalHeader } from '../../../components/bootstrap/Modal';
import moment from 'moment';
import { TokenToActivate } from '../../../components/entrada-segura/activate/token-to-activate';
import { TokenToActivateContractManager } from '../../../components/entrada-segura/activate/contract-manager/tokens-to-activate-contract-manager';

export const ActivateView = () => {
	const { user } = useContext(UserContext);

	const activeUserId = user.id;
	const userHighestRole = useUserHighestRole({ userId: activeUserId })

	const [tokensToActivate, setTokensToActivate] = useState<Array<Token>>([]);
	const [refreshList, setRefreshList] = useState<boolean>(false);
	const [shouldShowErrorAlert, setShouldShowErrorAlert] = useState<boolean>(false);
	const [errorAlertMessage, setErrorAlertMessage] = useState<string>("");
	const [errorAlertTitle, setErrorAlertTitle] = useState<string>("Erro!");
	const [loggedUserTagToken, setLoggedUserTagToken] = useState<string | undefined>("");
	const [issuedAtLoggedUser, setIssuedAtLoggedUser] = useState<string | null>("");
	const [expiresAtLoggedUser, setExpiresAtLoggedUser] = useState<string | null>("");

	useEffect(() => {
		const getUserTokens = async () => {
			if (activeUserId) {
				const tokens = await getTokensActivate({ issuedBy: activeUserId });
				setTokensToActivate(tokens.results);
			}
		};

		getUserTokens()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [activeUserId, refreshList]);

	useEffect(() => {
		const getUserGrantTokenType = async () => {
			if (activeUserId) {
				const tokensUserLogged = await getUserGrantTokens(activeUserId);
				if (tokensUserLogged) {
					const activeTokenGrantLoggedUser = tokensUserLogged.results.find((token) => token.status === "active");

					if (activeTokenGrantLoggedUser) {
						setIssuedAtLoggedUser(moment(activeTokenGrantLoggedUser.issuedAt).startOf('day').format('YYYY-MM-DD'));
						setExpiresAtLoggedUser(moment(activeTokenGrantLoggedUser.expiresAt).endOf('day').format('YYYY-MM-DD'));
					}
				}

				const highestTag = await getHighestTagByUserId(activeUserId);

				setLoggedUserTagToken(highestTag);

				console.log(`highestTag: ${highestTag} - HighestRole: ${userHighestRole}`)
			}
		}

		getUserGrantTokenType()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [])

	if (!tokensToActivate || _isEmpty(tokensToActivate)) {
		return (
			<>
				<PageWrapper title={'Ativar tokens'}>
					<Page container='fluid'>
						<CardHeader style={{ fontWeight: 'bold' }}>Não há tokens para ativar</CardHeader>
					</Page>
				</PageWrapper>
			</>
		);
	}

	return (
		<>
			<PageWrapper className='activate-view' title={'Ativar tokens'}>
				{tokensToActivate.map((token) => {
					return (
						<TokenToActivate
							key={`token-to-activate-${token.id}`}
							token={token}
							setShouldShowErrorAlert={setShouldShowErrorAlert}
							setErrorAlertMessage={setErrorAlertMessage}
							setErrorAlertTitle={setErrorAlertTitle}
							setRefreshList={setRefreshList}
							refreshList={refreshList}
							issuedAtLoggedUser={issuedAtLoggedUser}
							loggedUserTagToken={loggedUserTagToken}
							expiresAtLoggedUser={expiresAtLoggedUser}
						/>
					)
				})}
			</PageWrapper>

			<Modal
				setIsOpen={setShouldShowErrorAlert}
				isOpen={shouldShowErrorAlert}
				fullScreen={false}
				isCentered
				titleId='transfer-modal'>
				<ModalHeader className='w-100 d-flex justify-content-center align-items-center' setIsOpen={setShouldShowErrorAlert}>
					<></>
				</ModalHeader>
				<ModalBody className='h-100 d-flex align-items-center justify-content-center'>
					<div className='row w-100'>
						<div className='d-flex justify-content-center align-items-center flex-column gap-3'>
							<h4 className="fw-bold fs-3 text-danger">{errorAlertTitle}</h4>
							<div className='text-center'>
								<p className="fw-bold fs-5">{errorAlertMessage}</p>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
};