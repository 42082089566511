import moment from 'moment';

import { BOT_HOST } from '../constants';

import { BoardingPass } from '../../type/boarding-pass';
import { HTTPBoardingPassResponse } from './http-boarding-pass-response';
import { parseBoardingPass } from './parsers/boarding-pass-parser';
import api from '../api';

interface CreateBoardingPassParams {
	tokenId: string;
	createdBy: number;
}
export async function createBoardingpass({
	tokenId,
	createdBy,
}: CreateBoardingPassParams): Promise<BoardingPass> {
	const createdAt = moment().toISOString();
	const body = {
		token_id: tokenId,
		background_color: '#00ABBB',
		qr_code_background_color: '#FFFFFF',
		qr_code_fill_color: '#000',
		qr_code_scale: 5,
		created_at: createdAt,
		created_by: createdBy,
	};

	return api.post(`/api/v1/boardingpass/`, JSON.stringify(body), {
		headers: { 
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {	
		const responseJson = response.data as HTTPBoardingPassResponse;

		return parseBoardingPass(responseJson);
	});
}

export async function getBoardingPassByTokenId(tokenId: string): Promise<BoardingPass | null> {
	return api.get(`/api/v1/boardingpass/${tokenId}`, {
		headers: { 
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {	
		if (response) {
			const responseJson = response.data as HTTPBoardingPassResponse;
	
			return parseBoardingPass(responseJson);
		}

		return null;
	});
}

export async function sendBoardingPass(userId: number, tokenId: string): Promise<BoardingPass> {
	const response = await fetch(
		`${BOT_HOST}/api/v1/user-messages/${userId}/send-boardingpass-to-whatsapp/${tokenId}`,
		{
			method: 'POST',
			headers: { 'Content-type': 'application/json; charset=UTF-8' },
		},
	);

	if (response.ok) {
		const responseJson = (await response.json()) as HTTPBoardingPassResponse;

		return parseBoardingPass(responseJson);
	}

	throw new Error(response.statusText);
}