import '../../../styles/components/control/_card-control.scss';
import ActiveIcon from '../../../components/icon/svg-icons/Approval.svg';
import OnboardIcon from '../../../components/icon/svg-icons/Company.svg';
import CheckOutIcon from '../../../components/icon/svg-icons/EmergencyExit.svg';
import Popovers from '../../bootstrap/Popovers';

interface HandleOpenTableParams {
    type?: string;
    setIsOpenNew: React.Dispatch<React.SetStateAction<boolean>>;
    setIsOpenStarted: React.Dispatch<React.SetStateAction<boolean>>;
    setIsOpenConcluded: React.Dispatch<React.SetStateAction<boolean>>;
}
const handleOpenTable = async ({ setIsOpenNew, setIsOpenConcluded, type, setIsOpenStarted }: HandleOpenTableParams) => {
	switch (type) {
        case 'active':
            setIsOpenNew(true);
            setIsOpenStarted(false);
            setIsOpenConcluded(false);
            break;
        case 'started':
            setIsOpenNew(false);
            setIsOpenStarted(true);
            setIsOpenConcluded(false);
            break;
        case 'concluded':
            setIsOpenNew(false);
            setIsOpenStarted(false);
            setIsOpenConcluded(true);
            break;
        default:
            break;
    }
}

interface CardControlParams {
    type?: string;
    quantity?: number;
    setIsOpenNew: React.Dispatch<React.SetStateAction<boolean>>;
    setIsOpenStarted: React.Dispatch<React.SetStateAction<boolean>>;
    setIsOpenConcluded: React.Dispatch<React.SetStateAction<boolean>>;
}
export function CardControl(props: CardControlParams) {
    let color = '';
    let title = '';

    switch (props?.type) {
        case 'active':
            color = "cardActiveNew";
            title = "Autorizados (novos)";
            break;
        case 'started':
            color = "cardOnboard"
            title = "No condomínio";
            break;
        case 'concluded':
            color = "cardCheckOut"
            title = "Check out";
            break;
        default:
            break;
    };

    return (
        <>
            <div 
                onClick={() => handleOpenTable({ setIsOpenNew: props.setIsOpenNew, setIsOpenConcluded: props.setIsOpenConcluded, setIsOpenStarted: props.setIsOpenStarted, type: props.type })}
                className={`cardRoot cardBody ${color}`}>
                <span className='cardIcon'>
                    {
                        props.type === 'active' ? 
                        <img src={ActiveIcon} alt="Autorizados" />
                        :
                        props.type === 'started' ?
                        <img src={OnboardIcon} alt="Onboard" />
                        :
                        props.type === 'concluded' ?
                        <img src={CheckOutIcon} alt="CheckOut" />
                        :
                        <></>
                    }
                </span>
                <div className='cardTitle'>
                    {title}
                    {props.type === 'active' &&                     
                        <Popovers trigger='hover' desc='Tokens ativos hoje.'>
                            <span className='toolTipAutorizados'>?</span>
                        </Popovers>
                    }
                </div>
                <div className='cardQuantity'>
                    {props.quantity}
                </div>
            </div>
        </>
    )
}