import { useEffect, useState } from 'react';
import { getBuildingList } from '../../services/building/building-service';
import { getBuildingsByUser } from '../../services/token/token-service';
import { BuildingList } from '../../type/building';

interface useUserBuildingsParams {
	userId: number;
	isSuperUser?: boolean
}

export function useUserBuildings({ userId, isSuperUser }: useUserBuildingsParams): BuildingList | undefined {
	const [userData, setUserData] = useState<BuildingList>();

	useEffect(() => {
		const getUserInfo = async () => {
			if (isSuperUser) {
				const data = await getBuildingList();
				setUserData(data);
			} else if (userId !== 0) {
				const data = await getBuildingsByUser(userId);
				setUserData(data);
			}
		};

		getUserInfo()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [userId, isSuperUser]);

	return userData;
}
