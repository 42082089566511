import styled, { css } from "styled-components";

type PreviewProps = {
  src: string;
}

type ContainerProps = {
  isSelfie: boolean;
}
export const Container = styled.ul<ContainerProps>`
  margin-top: 20px;

  ${props => !props.isSelfie &&
    css`
  li{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;

    & + li {
      margin-top: 15px;
    }
  }`}

  
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;

  div{
    display: flex;
    flex-direction: column;
  a{    
    margin-left: 5px;
  }
    span{
      font-size:12px;
      color: #999;

      button{
        border: 0;
        background: transparent;
        color: #e57878;
        cursor: pointer;
      }
    }
  }
`;

export const Preview = styled.div<PreviewProps>`
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 5px;
`;

export const SelfieInfo = styled.div<PreviewProps>`
  width:100px;
  height: 100px;
  border-radius: 5px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 5px;
`;