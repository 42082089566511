import { Fragment, useContext } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import * as Yup from 'yup';
import _get from 'lodash/get';

import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../bootstrap/Card';
import FormGroup from '../../../bootstrap/forms/FormGroup';
import Input from '../../../bootstrap/forms/Input';

import { createBuilding } from '../../../../services/building/building-service';
import showNotification from '../../../extras/showNotification';

import Select from '../../../bootstrap/forms/Select';
import Option from '../../../bootstrap/Option';

import { UserContext } from '../../../../contexts/user-context';
import { RequiredFieldMessage } from '../required-field-message';
import { SubmitButton } from '../submit-button-create-form';

export const BuildingForm = () => {
	const { user } = useContext(UserContext);
	const activeUserId = user.id;

	const formik = useFormik({
		initialValues: {
			buildingName: '',
			businessName: '',
			buildingTaxid: '',
			buildingLogo: '',
			buildingType: '',
			address: '',
			addressNumber: '',
			addressComplement: '',
			neighborhood: '',
			city: '',
			state: '',
			zipcode: '',
			buildingStatus: '',
		},

		validationSchema: Yup.object({
			buildingName: Yup.string().label('Nome').required('Nome é um campo obrigatório'),
			buildingTaxid: Yup.string()
				.label('CNPJ')
				.required('CNPJ é um campo obrigatório')
				.test('is-valid-taxid', 'Insira o CNPJ com apenas números', function (value) {
					const hasDot = value?.includes('.');
					const hasDash = value?.includes('-');
					const isValid = !hasDot && !hasDash;
					return isValid;
				}),
			businessName: Yup.string()
				.label('Nome fantasia')
				.required('Nome fantasia é um campo obrigatório'),
			address: Yup.string().label('Endereço').required('Endereço é um campo obrigatório'),
			neighborhood: Yup.string().label('Bairro').required('Bairro é um campo obrigatório'),
			city: Yup.string().label('Cidade').required('Cidade é um campo obrigatório'),
			state: Yup.string().label('Estado').required('Estado é um campo obrigatório'),
			zipcode: Yup.string()
				.label('CEP')
				.required('CEP é um campo obrigatório')
				.test('is-valid-zipcode', 'Insira o CEP com apenas números', function (value) {
					const hasDot = value?.includes('.');
					const hasDash = value?.includes('-');
					const isValid = !hasDot && !hasDash;
					return isValid;
				}),
		}),

		onSubmit: async (values) => {
			const createdBy = activeUserId;
			const createdAt = moment().toISOString();

			try {
				await createBuilding({
					name: values.buildingName,
					businessName: values.businessName,
					taxid: values.buildingTaxid,
					logo: values.buildingLogo,
					address: values.address,
					addressNumber: values.addressNumber,
					addressComplement: values.addressComplement,
					neighborhood: values.neighborhood,
					city: values.city,
					state: values.state,
					zipcode: values.zipcode,
					createdAt,
					createdBy,
					status: values.buildingStatus,
					buildingType: values.buildingType,
				});

				showNotification(`Condomínio criado com sucesso!`, ``, 'success');
				formik.resetForm();
			} catch (error) {
				console.error('Error creating building: ', error);
				showNotification(`Erro ao criar condomínio. Por favor, tente novamente.`, ``, 'danger');
			}
		},
	});

	return (
		<Fragment>
			<div key={'building-form'} className={'col-12'}>
				<Card className='table-responsive' borderSize={3}>
					<CardHeader borderSize={2}>
						<CardLabel icon='Building' iconColor='info'>
							<CardTitle>Criar condomínio</CardTitle>
						</CardLabel>
					</CardHeader>
					<CardBody>
						<div className='row g-3'>
							<form className='row g-3' onSubmit={formik.handleSubmit} style={{ fontSize: '15px' }}>
								<div className='col-12 d-flex flex-column justify-content-center'>
									<FormGroup id='buildingName' label='Nome: ' className='mt-2'>
										<Input
                      id='buildingName'
											onChange={formik.handleChange}
											value={formik.values.buildingName}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.buildingName')} />
									</FormGroup>

									<FormGroup id='businessName' label='Nome fantasia: ' className='mt-2'>
										<Input
                      id='businessName'
											onChange={formik.handleChange}
											value={formik.values.businessName}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.businessName')} />
									</FormGroup>

									<FormGroup id='buildingTaxid' label='CNPJ: ' className='mt-2'>
										<Input
                      id='buildingTaxid'
											onChange={formik.handleChange}
											value={formik.values.buildingTaxid}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.buildingTaxid')} />
									</FormGroup>

									<FormGroup id='buildingLogo' label='Logo: ' className='mt-2'>
										<Input
                      id='buildingLogo'
											onChange={formik.handleChange}
											value={formik.values.buildingLogo}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
									</FormGroup>

									<FormGroup className='mt-12' id='buildingType' label='Tipo de condomínio'>
										<Select
                      id='buildingType'
											ariaLabel='Tipo de condomínio'
											placeholder='Selecione'
											onChange={formik.handleChange}
											value={formik.values.buildingType}>
											<Option key={'business-type-residential'} value={'Residential'}>
												Residencial
											</Option>
											<Option key={'business-type-comercial'} value={'Business'}>
												Comercial
											</Option>
										</Select>
									</FormGroup>

									<FormGroup id='address' label='Rua: ' className='mt-2'>
										<Input
                      id='address'
											onChange={formik.handleChange}
											value={formik.values.address}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.address')} />
									</FormGroup>

									<FormGroup id='addressNumber' label='Número: ' className='mt-2'>
										<Input
                      id='addressNumber'
											onChange={formik.handleChange}
											value={formik.values.addressNumber}
											type='number'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
									</FormGroup>

									<FormGroup id='addressComplement' label='Complemento: ' className='mt-2'>
										<Input
                      id='addressComplement'
											onChange={formik.handleChange}
											value={formik.values.addressComplement}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
									</FormGroup>

									<FormGroup id='neighborhood' label='Bairro: ' className='mt-2'>
										<Input
                      id='neighborhood'
											onChange={formik.handleChange}
											value={formik.values.neighborhood}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.neighborhood')} />
									</FormGroup>

									<FormGroup id='city' label='Cidade: ' className='mt-2'>
										<Input
                      id='city'
											onChange={formik.handleChange}
											value={formik.values.city}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.city')} />
									</FormGroup>

									<FormGroup id='state' label='Estado: ' className='mt-2'>
										<Input
                      id='state'
											onChange={formik.handleChange}
											value={formik.values.state}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.state')} />
									</FormGroup>

									<FormGroup id='zipcode' label='Código postal: ' className='mt-2'>
										<Input
                      id='zipcode'
											onChange={formik.handleChange}
											value={formik.values.zipcode}
											type='text'
											style={{
												backgroundColor: 'transparent!important',
												borderColor: 'transparent!important',
											}}
										/>
										<RequiredFieldMessage fieldError={_get(formik, 'errors.zipcode')} />
									</FormGroup>

									<FormGroup className='mt-12' id='buildingStatus' label='Status:'>
										<Select
                      id='buildingStatus'
											ariaLabel='Status'
											placeholder='Selecione'
											onChange={formik.handleChange}
											value={formik.values.buildingStatus}>
											<Option key={'building-type-active'} value={'Active'}>
												Ativo
											</Option>
											<Option key={'building-type-inactive'} value={'Inactive'}>
												Inativo
											</Option>
										</Select>
									</FormGroup>
								</div>
							</form>
						</div>
						<SubmitButton onClick={formik.handleSubmit} buttonId={'building-form-button'} />
					</CardBody>
				</Card>
			</div>
		</Fragment>
	);
};
