import { SpotList } from '../../../../type/spot';

export const getUniqueSpotList = (spotsList: SpotList) => {
	const uniqueList: SpotList = [];

	for (let i = 0; i < spotsList.length; i += 1) {
		if (
			!uniqueList.some(
				(spot) => spot.buildingId === spotsList[i].buildingId && spot.id === spotsList[i].id,
			)
		) {
			uniqueList.push(spotsList[i]);
		}
	}
	return uniqueList;
};
