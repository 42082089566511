import { useEffect, useState } from "react";
import { useSpotTokens } from "../../../../hooks/entrada-segura/use-spot-tokens";
import { Spot } from "../../../../type/spot";
import { Token, TokenList } from "../../../../type/token";
import Accordion, { AccordionItem } from "../../../bootstrap/Accordion";
import Card from "../../../bootstrap/Card";
import PaginationButtons, { PER_COUNT } from "../../../PaginationButtons";
import { CreateOrEditUserForm } from "../../consult-components/edit-user-form";
import { TokensTable } from "../../consult-components/token-table/tokens-table";
import { ShowAccessesFromUser } from "../../consult-components/show-accesses-from-user";
import { EditTokenForm } from "../../consult-components/edit-token-form";

interface TokenTableBySpotProps {
    spot: Spot;
    buildingId: number;
}

export function TokenTableBySpot({ spot, buildingId }: TokenTableBySpotProps) {
    const [shouldOpenEditUserForm, setShouldOpenEditUserForm] = useState(false);
    const [userToEdit, setUserToEdit] = useState(0);
    const [shouldOpenSeeAccess, setShouldOpenSeeAccess] = useState(false);
    const [shouldOpenEditTokenForm, setShouldOpenEditTokenForm] = useState(false);

    const [tokenToEdit, setTokenToEdit] = useState<Token | null>(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(PER_COUNT['10']);
    const [tokens, setTokens] = useState<TokenList>();
    const [refreshTable, setRefreshTable] = useState<boolean>(false);

    const handleUpcomingEdit = (userId: number) => {
        setUserToEdit(userId);
        setShouldOpenEditUserForm(!shouldOpenEditUserForm);
    };

    const getTokensBySpot = useSpotTokens({ spotId: spot.id, perPage, page: currentPage, refreshTable });

    useEffect(() => {
        setTokens(getTokensBySpot);
        setRefreshTable(false);
    }, [getTokensBySpot]);

    return (
        <>
            <section className='d-flex flex-column'>
                <Card stretch={true}>
                    <TokensTable
                        setUserToEdit={setUserToEdit}
                        setShouldOpenEditUserForm={setShouldOpenEditUserForm}
                        setShouldOpenSeeAccess={setShouldOpenSeeAccess}
                        tokens={tokens?.results}
                        isFluid={true}
                        setRefreshTable={setRefreshTable}
                        handleUpcomingEdit={handleUpcomingEdit} 
                        setTokenToEdit={setTokenToEdit}
                        setShouldOpenEditTokenForm={setShouldOpenEditTokenForm}
                    />
                    <PaginationButtons
                        data={tokens?.results}
                        totalResources={tokens?.totalRecords}
                        label='Tokens'
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                </Card>
                
                <CreateOrEditUserForm
                    key={`edit-user-component-${userToEdit}-${currentPage}`}
                    userId={userToEdit}
                    setRefreshTable={setRefreshTable}
                    setShouldOpenEditUserForm={setShouldOpenEditUserForm}
                    shouldOpenEditUserForm={shouldOpenEditUserForm}
                />

                <ShowAccessesFromUser
                    key={`show-accesses-component-${userToEdit}`}
                    buildingId={buildingId}
                    userId={userToEdit}
                    setIsOpen={setShouldOpenSeeAccess}
                    isOpen={shouldOpenSeeAccess}
                />

                <EditTokenForm
                    token={tokenToEdit}
                    setIsOpenEditTokenForm={setShouldOpenEditTokenForm}
                    isOpenEditTokenForm={shouldOpenEditTokenForm}
                    setRefreshTable={setRefreshTable}
                />
            </section>
        </>
    )
}