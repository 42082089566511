import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen } from 'react-use';
import { Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';

import Portal from '../layout/Portal/Portal';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';

import { LoginView } from '../pages/entrada-segura/login/login-view';
import Card from '../components/bootstrap/Card';

import { ActivateView } from '../pages/entrada-segura/activate/activate-view';
import { ConsultView } from '../pages/entrada-segura/consult/consult-view';
import { HomeView } from '../pages/entrada-segura/home/home-view';
import { MetricsView } from '../pages/entrada-segura/metrics/metrics-view';
import { AdminView } from '../pages/entrada-segura/admin/admin-view';
import { Dashboard } from '../pages/entrada-segura/auth-chain/time-graph-view';

import { AppRoute } from './routes';
import { PrivateRouteWrapper } from './private-route-wrapper';
import { UserContextProvider } from '../contexts/user-context';
import { OnboardingView } from '../pages/entrada-segura/onboarding/onboarding-view';
import { RegisterView } from '../pages/entrada-segura/register/register-view';
import { ControlView } from '../pages/entrada-segura/control/control-view';
import Footer from '../layout/Footer/Footer';
import Auth from './Auth';
import { USER_ROLES_LOWER } from '../components/entrada-segura/token/token-activation-form/blocks/constants';
import { InquiryView } from '../pages/entrada-segura/inquiry/inquiry';

const tracker = new Tracker({
  projectKey: process.env.REACT_APP_OPEN_REPLAY_KEY || "nCtJm9L7crbi3KFVpEws",
  ingestPoint: "https://openreplay.entradasegura.com.br/ingest",
  network: {
    capturePayload: true,
    captureInIframes: true,
    failuresOnly: false,
    ignoreHeaders: false,
    sessionTokenHeader: false
  }
});
tracker.use(trackerAssist());
tracker.start();

const App = () => {
    getOS();

    /**
     * Dark Mode
     */
    const { themeStatus, darkModeStatus } = useDarkMode();
    const theme = {
        theme: themeStatus,
        primary: COLORS.PRIMARY.code,
        secondary: COLORS.SECONDARY.code,
        success: COLORS.SUCCESS.code,
        info: COLORS.INFO.code,
        warning: COLORS.WARNING.code,
        danger: COLORS.DANGER.code,
        dark: COLORS.DARK.code,
        light: COLORS.LIGHT.code,
    };

    useEffect(() => {
        if (darkModeStatus) {
            document.documentElement.setAttribute('theme', 'dark');
        }
        return () => {
            document.documentElement.removeAttribute('theme');
        };
    }, [darkModeStatus]);

    /**
     * Full Screen
     */
    // @ts-ignore
    const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
    const ref = useRef(null);
    useFullscreen(ref, fullScreenStatus, {
        onClose: () => setFullScreenStatus(false),
    });

    /**
     * Modern Design
     */
    useLayoutEffect(() => {
        if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
            document.body.classList.add('modern-design');
        } else {
            document.body.classList.remove('modern-design');
        }
    });

    return (
        <ThemeProvider theme={theme}>
            <ToastProvider components={{ ToastContainer, Toast }}>
                <TourProvider steps={steps} styles={styles} showNavigation={false} showBadge={false}>
                    <div
                        ref={ref}
                        className='app'
                        style={{
                            backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
                            zIndex: fullScreenStatus ? 1 : undefined,
                            overflow: fullScreenStatus ? 'scroll' : undefined,
                        }}>
                        <Card className='table-responsive'>
                            <UserContextProvider>
                                <Routes>
                                    <Route path={AppRoute.Login} element={<LoginView tracker={tracker}/>} />

                                    <Route
                                        element={
                                            <Auth
                                                allowedRoles={[
                                                    USER_ROLES_LOWER.SYSADMIN,
                                                    USER_ROLES_LOWER.CONTRACT_MANAGER,
                                                    USER_ROLES_LOWER.STAFF,
                                                    USER_ROLES_LOWER.SPOT_MANAGER,
                                                    USER_ROLES_LOWER.ACCESS_MANAGER
                                                ]}
                                                path={AppRoute.Home}
                                            />
                                        }
                                    >
                                        <Route
                                            path={AppRoute.Home}
                                            element={
                                                <PrivateRouteWrapper>
                                                    <HomeView />
                                                </PrivateRouteWrapper>
                                            }
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <Auth
                                                allowedRoles={[
                                                    USER_ROLES_LOWER.SYSADMIN,
                                                    USER_ROLES_LOWER.CONTRACT_MANAGER,
                                                    USER_ROLES_LOWER.STAFF,
                                                    USER_ROLES_LOWER.SPOT_MANAGER,
                                                    USER_ROLES_LOWER.ACCESS_MANAGER
                                                ]}
                                                path={AppRoute.Activate}
                                            />
                                        }
                                    >
                                        <Route
                                            path={AppRoute.Activate}
                                            element={
                                                <PrivateRouteWrapper>
                                                    <ActivateView />
                                                </PrivateRouteWrapper>
                                            }
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <Auth
                                                allowedRoles={[
                                                    USER_ROLES_LOWER.SYSADMIN,
                                                    USER_ROLES_LOWER.CONTRACT_MANAGER,
                                                    USER_ROLES_LOWER.STAFF,
                                                    USER_ROLES_LOWER.SPOT_MANAGER,
                                                    USER_ROLES_LOWER.ACCESS_MANAGER,
                                                    USER_ROLES_LOWER.GUEST
                                                ]}
                                                path={AppRoute.Consult}
                                            />
                                        }
                                    >
                                        <Route
                                            path={AppRoute.Consult}
                                            element={
                                                <PrivateRouteWrapper>
                                                    <ConsultView />
                                                </PrivateRouteWrapper>
                                            }
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <Auth
                                                allowedRoles={[
                                                    USER_ROLES_LOWER.SYSADMIN
                                                ]}
                                                path={AppRoute.Metrics}
                                            />
                                        }
                                    >
                                        <Route
                                            path={AppRoute.Metrics}
                                            element={
                                                <PrivateRouteWrapper>
                                                    <MetricsView />
                                                </PrivateRouteWrapper>
                                            }
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <Auth
                                                allowedRoles={[
                                                    USER_ROLES_LOWER.SYSADMIN
                                                ]}
                                                path={AppRoute.Dashboard}
                                            />
                                        }
                                    >
                                        <Route
                                            path={AppRoute.Dashboard}
                                            element={
                                                <PrivateRouteWrapper>
                                                    <Dashboard />
                                                </PrivateRouteWrapper>
                                            }
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <Auth
                                                allowedRoles={[
                                                    USER_ROLES_LOWER.SYSADMIN
                                                ]}
                                                path={AppRoute.Admin}
                                            />
                                        }
                                    >
                                        <Route
                                            path={AppRoute.Admin}
                                            element={
                                                <PrivateRouteWrapper>
                                                    <AdminView />
                                                </PrivateRouteWrapper>
                                            }
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <Auth
                                                allowedRoles={[
                                                    USER_ROLES_LOWER.CONCIERGE
                                                ]}
                                                path={AppRoute.Onboarding}
                                            />
                                        }
                                    >
                                        <Route
                                            path={AppRoute.Onboarding}
                                            element={
                                                <PrivateRouteWrapper>
                                                    <OnboardingView />
                                                </PrivateRouteWrapper>
                                            }
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <Auth
                                                allowedRoles={[
                                                    USER_ROLES_LOWER.SYSADMIN,
                                                    USER_ROLES_LOWER.CONTRACT_MANAGER,
                                                    USER_ROLES_LOWER.STAFF,
                                                ]}
                                                path={AppRoute.Register}
                                            />
                                        }
                                    >
                                        <Route
                                            path={AppRoute.Register}
                                            element={
                                                <PrivateRouteWrapper>
                                                    <RegisterView />
                                                </PrivateRouteWrapper>
                                            }
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <Auth
                                                allowedRoles={[
                                                    USER_ROLES_LOWER.SYSADMIN,
                                                    USER_ROLES_LOWER.CONCIERGE
                                                ]}
                                                path={AppRoute.Controle}
                                            />
                                        }
                                    >
                                        <Route
                                            path={AppRoute.Controle}
                                            element={
                                                <PrivateRouteWrapper>
                                                    <ControlView />
                                                </PrivateRouteWrapper>
                                            }
                                        />
                                    </Route>

                                    <Route
                                        element={
                                            <Auth
                                                allowedRoles={[
                                                    USER_ROLES_LOWER.SYSADMIN,
                                                    USER_ROLES_LOWER.CONTRACT_MANAGER,
                                                    USER_ROLES_LOWER.STAFF,
                                                ]}
                                                path={AppRoute.Qualificar}
                                            />
                                        }
                                    >
                                        <Route
                                            path={AppRoute.Qualificar}
                                            element={
                                                <PrivateRouteWrapper>
                                                    <InquiryView />
                                                </PrivateRouteWrapper>
                                            }
                                        />
                                    </Route>
                                </Routes>
                            </UserContextProvider>
                        </Card>
                    </div>

                    <Portal id='portal-notification'>
                        <ReactNotifications />
                    </Portal>

                    <Footer />
                </TourProvider>
            </ToastProvider>
        </ThemeProvider>
    );
};

export default App;
