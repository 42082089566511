import React, { Fragment } from 'react';

import { CardBody, CardTitle } from '../../../../components/bootstrap/Card';
import { useBuildingName } from '../../../../hooks/entrada-segura/use-building-name';

import { ItemHeadline } from './item-headline';

type NumberOrUndefined = number | undefined;
interface BuildingSpotMetricsProps {
	buildingIds: Array<NumberOrUndefined>;
}
export const BuildingGeneralMetricsTable: React.FC<BuildingSpotMetricsProps> = ({
	buildingIds,
}) => {
	return (
		<>
			<CardBody style={{ maxWidth: '1000px' }}>
				<CardTitle>Visão geral</CardTitle>
				<table className='table table-responsive table-modern table-hover'>
					<thead>
						<ItemHeadline style={{ fontWeight: 'bold' }}>Condomínio</ItemHeadline>
						<ItemHeadline style={{ fontWeight: 'bold' }}>Access Logs</ItemHeadline>
						<ItemHeadline style={{ fontWeight: 'bold' }}>Boarding passes ativos</ItemHeadline>
						<ItemHeadline style={{ fontWeight: 'bold' }}>Boarding passes inativos</ItemHeadline>
					</thead>
					<tbody>
						{buildingIds &&
							buildingIds.map((buildingId) => (
								<BuildingGeneralMetricsBody key={`building-general-metrics-${buildingId}`} buildingId={buildingId} />
							))}
					</tbody>
				</table>
			</CardBody>
		</>
	);
};

interface BuildingGeneralMetricsBodyProps {
	buildingId: number | undefined;
}
const BuildingGeneralMetricsBody = ({ buildingId }: BuildingGeneralMetricsBodyProps) => {
	const buildingName = useBuildingName({ buildingId });
	const accessLogCount = 0;
	const activeBoardingPassesCount = 0;
	const inactiveBoardingPassesCount = 0;

	return (
		<tr>
			<td>{buildingName}</td>
			<td>{accessLogCount}</td>
			<td>{activeBoardingPassesCount}</td>
			<td>{inactiveBoardingPassesCount}</td>
		</tr>
	);
};
