import React from 'react';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { EditUserInfoForm } from './user-info';
interface EditUserFormProps {
	userId?: number;
	setShouldOpenEditUserForm: React.Dispatch<React.SetStateAction<boolean>>;
	shouldOpenEditUserForm: boolean;
	setRefreshTable: React.Dispatch<React.SetStateAction<boolean>>;
}
export const CreateOrEditUserForm: React.FC<EditUserFormProps> = ({
	userId,
	setShouldOpenEditUserForm,
	shouldOpenEditUserForm,
	setRefreshTable,
}) => {
	return (
		<Modal
			setIsOpen={setShouldOpenEditUserForm}
			isOpen={shouldOpenEditUserForm}
			titleId='editUser'			
			isScrollable			
            isStaticBackdrop
			isCentered
			size={'lg'}>
			<ModalHeader setIsOpen={setShouldOpenEditUserForm}>
				<ModalTitle id='editUser'>{userId != 0 ? 'Editar' : 'Cadastrar'} usuário</ModalTitle>
			</ModalHeader>
			<ModalBody>
				{shouldOpenEditUserForm && (
					<EditUserInfoForm
						key={`user-form-${userId}`}
						userId={userId}
						setRefreshTable={setRefreshTable}
						additionalOnSubmitControl={() => setShouldOpenEditUserForm(false)}
						modalRegisterFormVisible={shouldOpenEditUserForm}
					/>
				)}
			</ModalBody>
		</Modal>
	);
};
