import { ControlComponents } from "../../../components/entrada-segura/control/control-components";
import Page from "../../../layout/Page/Page"
import PageWrapper from "../../../layout/PageWrapper/PageWrapper"

export const ControlView = () => {
    return (
        <>
            <PageWrapper title={'Controle de acessos'}>
                <Page container='fluid'>
                    <ControlComponents />
                </Page>
            </PageWrapper>
        </>
    )
}