import { useState, useContext, useEffect } from 'react';

import Card from '../../bootstrap/Card';
import PaginationButtons, { PER_COUNT } from '../../PaginationButtons';

import { TokenAccessLog } from './token-access-log/token-access-log';
import { CreateOrEditUserForm } from './edit-user-form';
import { UserContext } from '../../../contexts/user-context';
import { TokensTable } from './token-table/tokens-table';
import { useTokensIssuedByUserAuthorizationTypes } from '../../../hooks/entrada-segura/use-tokens-issued-by-user-authorization-types';
import { Token, TokenList } from '../../../type/token';
import { ShowAccessesFromUser } from './show-accesses-from-user';
import Input from '../../bootstrap/forms/Input';
import { EditTokenForm } from './edit-token-form';

export const ConsultComponents = ({ isFluid = true }) => {
	const [shouldOpenEditUserForm, setShouldOpenEditUserForm] = useState(false);
	const [shouldOpenSeeAccess, setShouldOpenSeeAccess] = useState(false);
	const [shouldOpenTokenLog, setShouldOpenTokenLog] = useState(false);
	const [shouldOpenEditTokenForm, setShouldOpenEditTokenForm] = useState(false);
	const [searchString, setSearchString] = useState('');

	const [userToEdit, setUserToEdit] = useState(0);
	const [tokenToShowLog, setTokenToShowLog] = useState('');
	const [tokenToEdit, setTokenToEdit] = useState<Token | null>(null);

	const handleUpcomingEdit = (userId: number) => {
		setUserToEdit(userId);
		setShouldOpenEditUserForm(!shouldOpenEditUserForm);
	};
	const handleShowTokenLog = (tokenId: string) => {
		setTokenToShowLog(tokenId);
		setShouldOpenTokenLog(!shouldOpenTokenLog);
	};

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(PER_COUNT['10']);
	const [tokens, setTokens] = useState<TokenList>();
	const [refreshTable, setRefreshTable] = useState<boolean>(false);
	const [isFetching, setIsFetching] = useState<boolean>(true);

	const { user } = useContext(UserContext);
	const activeUserId = user.id;
	const authorizationTypes = ["grant", "checkin"];
	const getTokens = useTokensIssuedByUserAuthorizationTypes({ 
		userId: activeUserId, 
		isSuperUser: user.isSuperUser, 
		perPage: perPage, 
		page: currentPage, 
		authorizationTypes: authorizationTypes, 
		refreshTable, 
		setIsFetching,
		searchUserRequestedName: searchString
	})

	useEffect(() => {
		setTokens(getTokens);
		setRefreshTable(false);
	}, [getTokens]);

	return (
		<section className='d-flex flex-column'>
			{isFetching ?
				<p>Carregando...</p>
				:
				<>
					<div className='fw-bold fs-5' style={{ marginLeft: '15px' }}>
						Tipos de Autorização
					</div>

					<form 
						className='d-flex my-3 align-items-center'
						onSubmit={(e) => {
							e.preventDefault() 
							setRefreshTable((prev) => !prev)
						}}
					>
						<Input
							className='search-input'
							onChange={(e: any) => setSearchString(e.target.value)}
							type='text'
							placeholder='Pesquisar...'
							style={{
								display: 'flex',
								justifyContent: 'center',
								maxWidth: '200px',
								backgroundColor: 'transparent!important',
								borderColor: 'transparent!important',
							}}
							value={searchString}
						/>

						<div className='d-flex justify-content-center align-items-center'>
							<button
								className='btn btn-primary'
								onClick={() => setRefreshTable((prev) => !prev)}
							>
								Pesquisar
							</button>
						</div>
					</form>

					<Card stretch={isFluid}>
						<TokensTable
							setShouldOpenEditUserForm={setShouldOpenEditUserForm}
							setShouldOpenSeeAccess={setShouldOpenSeeAccess}
							setShouldOpenEditTokenForm={setShouldOpenEditTokenForm}
							setTokenToEdit={setTokenToEdit}
							setUserToEdit={setUserToEdit}
							tokens={tokens?.results}
							isFluid={isFluid}
							setRefreshTable={setRefreshTable}
							handleUpcomingEdit={handleUpcomingEdit} 
						/>
						<PaginationButtons
							data={tokens?.results}
							totalResources={tokens?.totalRecords}
							label='Tokens'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
					<CreateOrEditUserForm
						key={`edit-user-component-${userToEdit}-${currentPage}`}
						userId={userToEdit}
						setRefreshTable={setRefreshTable}
						setShouldOpenEditUserForm={setShouldOpenEditUserForm}
						shouldOpenEditUserForm={shouldOpenEditUserForm}
					/>
					<TokenAccessLog
						tokenId={tokenToShowLog}
						setShouldOpenTokenLog={setShouldOpenTokenLog}
						shouldOpenTokenLog={shouldOpenTokenLog}
					/>
					<ShowAccessesFromUser
                    	key={`show-accesses-component-${userToEdit}`}
                    	userId={userToEdit}
                    	setIsOpen={setShouldOpenSeeAccess}
                    	isOpen={shouldOpenSeeAccess}
                	/>
					<EditTokenForm
						token={tokenToEdit}
						setIsOpenEditTokenForm={setShouldOpenEditTokenForm}
						isOpenEditTokenForm={shouldOpenEditTokenForm}
						setRefreshTable={setRefreshTable}
					/>
				</>
			}
		</section>
	);
};