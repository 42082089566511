import React, { useEffect, useState } from 'react';
import { format } from 'date-fns'

import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { getBuildingsTokensByUser } from '../../../services/token/token-service';
import { AUTH_TYPES, USER_TAGS_BR, getTokenStatusColor, getTokenStatusText } from '../token/token-activation-form/blocks/constants';
import { Token } from '../../../type/token';
import { Spot } from '../../../type/spot';
import { getSpotById } from '../../../services/spot/spot-service';
import { CardBody } from '../../bootstrap/Card';
import { TableColumn } from './table-components/table-column';

interface EditUserFormProps {
	userId: number;
	buildingId?: number;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
	isOpen: boolean;
}

export const ShowAccessesFromUser: React.FC<EditUserFormProps> = ({
	userId,
	buildingId,
	setIsOpen,
	isOpen
}) => {
	const [accesses, setAccesses] = useState<Token[]>([]);
	const [spots, setSpots] = useState<{[key: number]: Spot}>({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const fetchAccesses = async () => {
			const tokens = await getBuildingsTokensByUser(userId);

			const grantOrCheckinTokens = tokens
				.filter((token) => token.authorizationType === AUTH_TYPES.CHECKIN || token.authorizationType === AUTH_TYPES.GRANT)
				.filter((token) => buildingId === undefined || token.buildingId === buildingId);
			setAccesses(grantOrCheckinTokens)
				
			const spotIds = grantOrCheckinTokens.map((token) => token.spotId);
			const uniqueSpotIds = Array.from(new Set(spotIds));
			const spots: {[key: number]: Spot} = {};
			for (const spot of uniqueSpotIds) {
				const spotData = await getSpotById(spot);

				spots[spot] = spotData;
			}
			setSpots(spots);

			setLoading(false);
		};
		fetchAccesses();
	}, [userId, buildingId]);

	return (
		<Modal
			setIsOpen={setIsOpen}
			isOpen={isOpen}
			titleId='showAccesses'
			isScrollable
			isStaticBackdrop
			isCentered
			size={'lg'}>
			<ModalHeader setIsOpen={setIsOpen}>
				<ModalTitle id='showAccesses'>Acessos do usuário</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<div>
					{loading ? (
						<p>Carregando...</p>
					) : (
						<CardBody className='table-responsive' isScrollable={false} style={{ height: '40rem' }}>
							<table className='table table-modern table-responsive'>
								<thead>
									<tr key={'user-token-tables-names'}>
										<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Situação</TableColumn>
										<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Unidade</TableColumn>
										<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Autorizador</TableColumn>
										<TableColumn style={{ fontWeight: 'bold', fontSize: '16px' }}>Motivo</TableColumn>
										<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Data início</TableColumn>
										<TableColumn style={{ fontWeight: 'bold', fontSize: '18px' }}>Data fim</TableColumn>
									</tr>
								</thead>
								<tbody>
									{accesses.map((access) => {
										const statusColor = getTokenStatusColor(access.status)
										return (
										<tr style={{ textAlign: 'center' }}>
											<TableColumn>
												<small
													className={`border border-${statusColor} border-2 text-${statusColor} fw-bold px-2 py-1 rounded-1`}>
													{getTokenStatusText(access.status)}
												</small>
											</TableColumn>
											<TableColumn>{access.spotId && spots[access.spotId].name ? spots[access.spotId].name : 'Não informado'}</TableColumn>
											<TableColumn>{access.userIssued.name}</TableColumn>
											<TableColumn>{access.tag ? USER_TAGS_BR[access.tag] : 'Não informado'}</TableColumn>
											<TableColumn>{access.issuedAt ? format(new Date(access.issuedAt), 'dd/MM/yyyy') : 'Não informada'}</TableColumn>
											<TableColumn>{access.expiresAt ? format(new Date(access.expiresAt), 'dd/MM/yyyy') : 'Não informada'}</TableColumn>
										</tr>
									)})}
								</tbody>
							</table>
						</CardBody>
					)}
				</div>
			</ModalBody>
		</Modal>
	);
};
