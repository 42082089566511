import api from "../api";

interface requestBackgroundCheckParams {
    phoneNumber: string;
}

export function requestBackgroundCheck({ phoneNumber }: requestBackgroundCheckParams): Promise<void> {
    return api.post(`/api/v1/z-api/request-background-check/${phoneNumber}`, {
        headers: {
            'Content-type': 'application/json; charset=UTF-8',
        }
    })
}