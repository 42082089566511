import React, { useState, useEffect, useContext } from 'react';

import { getTokensIssuedForUserAsSpotList } from '../../../services/token/token-service';
import { getUniqueSpotList } from '../../../components/entrada-segura/spot/utils/get-unique-spots-from-tokens';
import { BuildingSpotMetrics } from './blocks/building-spot-metrics';
import { BuildingGateMetrics } from './blocks/building-gate-metrics';
import { BuildingTokenMetrics } from './blocks/building-token-metrics';
import { BuildingGeneralMetricsTable } from './blocks/building-general-metrics';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { UserContext } from '../../../contexts/user-context';

type NumberOrUndefined = number | undefined;
export const MetricsView = () => {
	const [userBuildingsList, setUserBuildingsList] = useState<Array<NumberOrUndefined>>();

	const { user } = useContext(UserContext);
	const activeUserId = user.id;

	useEffect(() => {
		const getUserTokens = async () => {
			if (activeUserId) {
				const spotsList = await getTokensIssuedForUserAsSpotList(activeUserId);
				const uniqueSpotsList = getUniqueSpotList(spotsList);
				const buildingIds = uniqueSpotsList.map(({ buildingId }) => {
					if (buildingId !== null) return buildingId;
				});

				setUserBuildingsList(buildingIds);
			}
		};

		getUserTokens()
			.then((data) => {
				return data;
			})
			.catch(console.error);
	}, [activeUserId]);

	return (
		<PageWrapper title={'Métricas'}>
			<div className='row'>
				<div className='col-xl-12'>
					{userBuildingsList && <BuildingGeneralMetricsTable buildingIds={userBuildingsList} />}
					{userBuildingsList && <BuildingSpotMetrics buildingIds={userBuildingsList} />}
					{userBuildingsList && <BuildingGateMetrics buildingIds={userBuildingsList} />}
					{userBuildingsList && <BuildingTokenMetrics buildingIds={userBuildingsList} />}
				</div>
			</div>
		</PageWrapper>
	);
};
