import { Fragment } from 'react';
import { CardBody, CardTitle } from '../../../../components/bootstrap/Card';
import {
	useTokensCountByBuildingIdAndTokenAuthType,
	useTokensCountByBuildingIdAndTokenRole,
	useTokensCountByBuildingIdAndTokenStatus,
} from '../../../../hooks/entrada-segura/metrics/use-tokens-count-by-building-id';

import { useBuildingName } from '../../../../hooks/entrada-segura/use-building-name';
import { ItemHeadline } from './item-headline';

type NumberOrUndefined = number | undefined;

interface BuildingTokenMetricsProps {
	buildingIds: Array<NumberOrUndefined>;
}
export const BuildingTokenMetrics: React.FC<BuildingTokenMetricsProps> = ({ buildingIds }) => {
	return (
		<Fragment>
			<CardTitle style={{ padding: '1.5rem 1.5rem' }}>TOKENS</CardTitle>
			<TokenRolesMetrics buildingIds={buildingIds} />
			<TokenAuthorizationMetrics buildingIds={buildingIds} />
			<TokenStatusMetrics buildingIds={buildingIds} />
		</Fragment>
	);
};

interface TokenRolesMetricsProps {
	buildingIds: Array<NumberOrUndefined>;
}

const TokenRolesMetrics: React.FC<TokenRolesMetricsProps> = ({ buildingIds }) => {
	return (
		<>
			<CardBody style={{ maxWidth: '1000px' }}>
				<table className='table table-responsive table-modern table-hover'>
					<thead>
						<ItemHeadline>Papel</ItemHeadline>
						<ItemHeadline>Total</ItemHeadline>
					</thead>
					<tbody>
						{buildingIds &&
							buildingIds.map((buildingId) => {
								return <BuildingTokenRolesMetricsRow key={`token-role-metrics-${buildingId}`} buildingId={buildingId} />;
							})}
					</tbody>
				</table>
			</CardBody>
		</>
	);
};

interface BuildingTokenRolesMetricsRowProps {
	buildingId: NumberOrUndefined;
}
const BuildingTokenRolesMetricsRow = ({ buildingId }: BuildingTokenRolesMetricsRowProps) => {
	const buildingName = useBuildingName({ buildingId });
	const sysAdminCount = useTokensCountByBuildingIdAndTokenRole({
		buildingId,
		tokenRole: 'sysadmin',
	});
	const contractManagerCount = useTokensCountByBuildingIdAndTokenRole({
		buildingId,
		tokenRole: 'contract_manager',
	});
	const spotManagerCount = useTokensCountByBuildingIdAndTokenRole({
		buildingId,
		tokenRole: 'spot_manager',
	});
	const accessManagerCount = useTokensCountByBuildingIdAndTokenRole({
		buildingId,
		tokenRole: 'access_manager',
	});
	const conciergesCount = useTokensCountByBuildingIdAndTokenRole({
		buildingId,
		tokenRole: 'concierge',
	});
	const guestsCount = useTokensCountByBuildingIdAndTokenRole({
		buildingId,
		tokenRole: 'guest',
	});

	return (
		<Fragment>
			<tr style={{ fontWeight: 'bold' }}>{buildingName}</tr>
			<tr>
				<td>Administrador do sistema</td>
				<td>{sysAdminCount || 0}</td>
			</tr>
			<tr>
				<td>Contract manager</td>
				<td>{contractManagerCount || 0}</td>
			</tr>
			<tr>
				<td>Spot manager</td>
				<td>{spotManagerCount || 0}</td>
			</tr>
			<tr>
				<td>Access manager</td>
				<td>{accessManagerCount || 0}</td>
			</tr>
			<tr>
				<td>Concierges</td>
				<td>{conciergesCount || 0}</td>
			</tr>
			<tr>
				<td>Guests</td>
				<td>{guestsCount || 0}</td>
			</tr>
		</Fragment>
	);
};

interface TokenAuthorizationMetricsProps {
	buildingIds: Array<NumberOrUndefined>;
}

const TokenAuthorizationMetrics: React.FC<TokenAuthorizationMetricsProps> = ({ buildingIds }) => {
	return (
		<>
			<CardBody style={{ maxWidth: '1000px' }}>
				<table className='table table-responsive table-modern table-hover'>
					<thead>
						<ItemHeadline>Tipo de acesso</ItemHeadline>
						<ItemHeadline>Total</ItemHeadline>
					</thead>
					<tbody>
						{buildingIds &&
							buildingIds.map((buildingId) => {
								return <BuildingTokenAuthMetricsRow key={`token-auth-metrics-${buildingId}`} buildingId={buildingId} />;
							})}
					</tbody>
				</table>
			</CardBody>
		</>
	);
};

interface BuildingTokenAuthMetricsRowProps {
	buildingId: NumberOrUndefined;
}
const BuildingTokenAuthMetricsRow = ({ buildingId }: BuildingTokenAuthMetricsRowProps) => {
	const buildingName = useBuildingName({ buildingId });
	const inviteCount = useTokensCountByBuildingIdAndTokenAuthType({
		buildingId,
		tokenAuthType: 'invite',
	});
	const checkinCount = useTokensCountByBuildingIdAndTokenAuthType({
		buildingId,
		tokenAuthType: 'checkin',
	});
	const registerCount = useTokensCountByBuildingIdAndTokenAuthType({
		buildingId,
		tokenAuthType: 'register',
	});
	const grantCount = useTokensCountByBuildingIdAndTokenAuthType({
		buildingId,
		tokenAuthType: 'grant',
	});

	return (
		<Fragment>
			<tr style={{ fontWeight: 'bold' }}>{buildingName}</tr>
			<tr>
				<td>Invite</td>
				<td>{inviteCount || 0}</td>
			</tr>
			<tr>
				<td>Check-in</td>
				<td>{checkinCount || 0}</td>
			</tr>
			<tr>
				<td>Register</td>
				<td>{registerCount || 0}</td>
			</tr>
			<tr>
				<td>Grant</td>
				<td>{grantCount || 0}</td>
			</tr>
		</Fragment>
	);
};

interface TokenStatusMetricsProps {
	buildingIds: Array<NumberOrUndefined>;
}

const TokenStatusMetrics: React.FC<TokenStatusMetricsProps> = ({ buildingIds }) => {
	return (
		<>
			<CardBody style={{ maxWidth: '1000px' }}>
				<table className='table table-responsive table-modern table-hover'>
					<thead>
						<ItemHeadline>Status</ItemHeadline>
						<ItemHeadline>Total</ItemHeadline>
					</thead>
					<tbody>
						{buildingIds &&
							buildingIds.map((buildingId) => {
								return <BuildingTokenStatusMetricsRow key={`token-status-building-metrics-${buildingId}`} buildingId={buildingId} />;
							})}
					</tbody>
				</table>
			</CardBody>
		</>
	);
};

interface BuildingTokenStatusMetricsRowProps {
	buildingId: NumberOrUndefined;
}
const BuildingTokenStatusMetricsRow = ({ buildingId }: BuildingTokenStatusMetricsRowProps) => {
	const buildingName = useBuildingName({ buildingId });
	const activeCount = useTokensCountByBuildingIdAndTokenStatus({
		buildingId,
		tokenStatus: 'active',
	});
	const inactiveCount = useTokensCountByBuildingIdAndTokenStatus({
		buildingId,
		tokenStatus: 'inactive',
	});

	return (
		<Fragment>
			<tr style={{ fontWeight: 'bold' }}>{buildingName}</tr>
			<tr>
				<td>Ativos</td>
				<td>{activeCount || 0}</td>
			</tr>
			<tr>
				<td>Revogados</td>
				<td>{inactiveCount || 0}</td>
			</tr>
		</Fragment>
	);
};
