import { CardBody, CardTitle } from '../../../../components/bootstrap/Card';
import {
	useGatesCountByBuildingIdAndGateStatus,
	useGatesCountByBuildingIdAndGateType,
} from '../../../../hooks/entrada-segura/metrics/use-gates-count';

import { useBuildingName } from '../../../../hooks/entrada-segura/use-building-name';
import { ItemHeadline } from './item-headline';

type NumberOrUndefined = number | undefined;
interface BuildingGateMetricsProps {
	buildingIds: Array<NumberOrUndefined>;
}

export const BuildingGateMetrics: React.FC<BuildingGateMetricsProps> = ({ buildingIds }) => {
	return (
		<>
			<CardBody style={{ maxWidth: '1000px' }}>
				<CardTitle>GATES</CardTitle>
				<table className='table table-responsive table-modern table-hover'>
					<thead>
						<tr>
							<ItemHeadline>Condomínio</ItemHeadline>
							<ItemHeadline># Gatehouses</ItemHeadline>
							<ItemHeadline># Gates </ItemHeadline>
							<ItemHeadline># Standalone totens </ItemHeadline>
							<ItemHeadline># Ativos </ItemHeadline>
							<ItemHeadline># Inativos</ItemHeadline>
						</tr>
					</thead>
					<tbody>
						{buildingIds &&
							buildingIds.map((buildingId) => {
								return <BuildingGatesMetricsRow key={`building-gate-metrics-${buildingId}`} buildingId={buildingId} />;
							})}
					</tbody>
				</table>
			</CardBody>
		</>
	);
};

interface BuildingGatesMetricsRowProps {
	buildingId: NumberOrUndefined;
}
const BuildingGatesMetricsRow = ({ buildingId }: BuildingGatesMetricsRowProps) => {
	const buildingName = useBuildingName({ buildingId });
	const gatehouseCount = useGatesCountByBuildingIdAndGateType({
		buildingId,
		gateType: 'gatehouse',
	});
	const gateCount = useGatesCountByBuildingIdAndGateType({
		buildingId,
		gateType: 'gate',
	});
	const gateStandaloneTotemCount = useGatesCountByBuildingIdAndGateType({
		buildingId,
		gateType: 'standalonetotem',
	});
	const activeGatesCount = useGatesCountByBuildingIdAndGateStatus({
		buildingId,
		gateStatus: 'active',
	});
	const inactiveGatesCount = useGatesCountByBuildingIdAndGateStatus({
		buildingId,
		gateStatus: 'inactive',
	});

	return (
		<tr key={`buildind-gates-metrics-${buildingId}`}>
			<td>{buildingName}</td>
			<td>{gateCount}</td>
			<td>{gatehouseCount}</td>
			<td>{gateStandaloneTotemCount}</td>
			<td>{activeGatesCount}</td>
			<td>{inactiveGatesCount}</td>
		</tr>
	);
};
