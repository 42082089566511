import { isEmpty, debounce } from "lodash";
import { useCallback, useContext, useEffect, useState } from "react";

import { SpotList } from '../../../type/spot';
import { UserContext } from "../../../contexts/user-context";
import { getBlocksByBuildingId, getBuildingById } from "../../../services/building/building-service";
import { getBuildingsTokensByUser } from "../../../services/token/token-service";
import { getBuildingSpotsList } from "../../../services/spot/spot-service";
import Accordion, { AccordionItem } from "../../bootstrap/Accordion";
import Card, { CardBody } from "../../bootstrap/Card";
import { TokenTableBySpot } from "./Table";
import { USER_ROLES_LOWER } from "../token/token-activation-form/blocks/constants";
import Input from "../../bootstrap/forms/Input";
import { BlockList } from "../../../type/Block";

export function ConsultComponentsContractManager() {
    const { user } = useContext(UserContext);
    const [searchSpot, setSearchSpot] = useState<string>('');
    const [spotsToDisplay, setSpotsToDisplay] = useState<number[]>([]);
    const [allBlocks, setAllBlocks] = useState<{ [buildingName: string]: BlockList }>({});
    const [isOnlyOneBuildingWithOneBlock, setIsOnlyOneBuildingWithOneBlock] = useState<boolean>(false);
    const [allSpots, setAllSpots] = useState<SpotList>([]);
    const [isLoading, setIsLoading] = useState(true); // Set initial loading state to true
    const [showSearchResult, setShowSearchResult] = useState<boolean>(false);

    const search = useCallback(async (query: string) => {
        if (query.length === 0) {
            setSpotsToDisplay([]);
            setShowSearchResult(false);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);

        const spots = allSpots.filter((spot) => spot.name.toLowerCase().includes(query.toLowerCase())).map((spot) => spot.id);

        setShowSearchResult(true);
        setIsLoading(false);
        setSpotsToDisplay(spots);
    }, [allSpots]);

    const debounceSearch = useCallback(debounce(search, 250), [allSpots]);

    useEffect(() => {
        const getUserSpots = async () => {
            setIsLoading(true);

            const tokens = await getBuildingsTokensByUser(user.id);

            if (isEmpty(tokens)) {
                setAllSpots([]);
            } else {
                const spotsArr: SpotList = [];

                const filteredToken = tokens.filter((token) => 
                    token.role === USER_ROLES_LOWER.CONTRACT_MANAGER 
                    || token.role === USER_ROLES_LOWER.STAFF 
                );

                if (filteredToken.length > 0) {
                    for (const token of filteredToken) {
                        const blocksData = await getBlocksByBuildingId(token.buildingId);
                        const buildingData = await getBuildingById(token.buildingId);

                        setAllBlocks((prev) => ({
                            ...prev,
                            [buildingData.name]: blocksData
                        }));
                            
                        const spotData = await getBuildingSpotsList(token.buildingId);

                        for (const spot of spotData) {
                            spotsArr.push(spot);
                        }
                    }

                    setAllSpots(spotsArr);
                } else {
                    setAllSpots([]);
                }
            }

            setIsLoading(false);
        };

        getUserSpots();
    }, [user.id]);

    useEffect(() => {
        if (showSearchResult) return

        if (Object.keys(allBlocks).length === 1) { // If there is only one building
            const buildingName = Object.keys(allBlocks)[0];
            const blocks = allBlocks[buildingName];

            if (Object.keys(blocks).length === 1) { // If there is only one block
                const blockName = Object.keys(blocks)[0];
                const spots = blocks[blockName];

                setSpotsToDisplay(spots);
                setIsOnlyOneBuildingWithOneBlock(true);
            }
        }
    }, [allBlocks, showSearchResult]);

    return (
        <>
            <section className='d-flex flex-column col-xl-2'>
                {spotsToDisplay.length && !isOnlyOneBuildingWithOneBlock 
                    ? (
                        <button className='btn btn-primary my-3' onClick={() => setSpotsToDisplay([])} style={{ width: 'fit-content' }}>
                            Voltar
                        </button>
                    ) : null}

                <Card stretch={true}>
                    <div className='fw-bold fs-5 d-flex justify-content-start'>
                        Pesquisar Unidade:
                    </div>
                    <div className='d-flex mt-3'>
                        <Input
                            id='searchSpotInput'
                            type='search'
                            className='border-0 shadow-none bg-transparent'
                            placeholder='Pesquise...'
                            onChange={(e: any) => {
                                debounceSearch(e.target.value)
                                setSearchSpot(e.target.value)
                            }}
                            value={searchSpot}
                            autoComplete='off'
                        />
                    </div>
                </Card>
            </section>

            {isLoading ? (
                <p>Carregando os Lotes Disponíveis...</p>
            ) : (
                <div className="d-flex flex-column gap-4">
                    {(!allSpots.length) &&
                        <p>Nenhum Lote Disponível.</p>
                    }

                    {showSearchResult && !spotsToDisplay.length && (
                        <p>Nenhum resultado encontrado para a pesquisa.</p>
                    )}

                    {(!isLoading && !isEmpty(allSpots)) && (
                        <div className='d-flex flex-column gap-4'>
                            {spotsToDisplay.length || showSearchResult ? 
                                allSpots.filter((spot) => spotsToDisplay.includes(spot.id)).map((spot) => (
                                    <Accordion key={`spot-accordion-${spot.id}`} id='Spots' shadow='sm'>
                                        <AccordionItem key={`spot-accordion-item-${spot.id}`} id={spot.id} title={`${spot.name}`}>
                                            <TokenTableBySpot
                                                key={spot.id}
                                                spot={spot}
                                                buildingId={spot.buildingId}
                                            />
                                        </AccordionItem>
                                    </Accordion>
                                ))
                            : Object.keys(allBlocks).map((buildingName, index) => (
                                <Accordion key={`building-accordion-${buildingName}`} id='Building' shadow='sm' activeItemId={index === 0 ? buildingName : false}>
                                    <AccordionItem key={`building-accordion-item-${buildingName}`} id={buildingName} title={`${buildingName}`}>
                                        <div className='d-flex flex-row gap-4 flex-wrap'>
                                            {Object.entries(allBlocks[buildingName]).map(([blockName, spots]) => (
                                                <Card key={`block-card-${blockName}`} stretch={true} onClick={() => setSpotsToDisplay(spots)} className='cursor-pointer'>
                                                    <CardBody>
                                                        <h3>{blockName}</h3>
                                                        <span>
                                                            {spots.length} Lotes
                                                        </span>
                                                    </CardBody>
                                                </Card>
                                            ))}
                                        </div>
                                    </AccordionItem>
                                </Accordion>
                            ))}
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
