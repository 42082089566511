import { useState } from 'react';
import Card, { CardBody, CardHeader } from '../../../components/bootstrap/Card';
import { QRScanner } from '../../../components/entrada-segura/onboarding/qr-scanner/qr-scanner';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import Button from '../../../components/bootstrap/Button';
import { GuestInfo } from '../../../components/entrada-segura/onboarding/guest-info/guest-info';
import { GuestInfoType } from '../../../type/guest-info';
import { AccessLog } from '../../../type/access-log';
import { GateList } from '../../../type/gate';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';

export const OnboardingView = () => {
	const [isScanQRCodeOpen, setIsScanQRCodeOpen] = useState(false);
	const [guestInfo, setGuestInfo] = useState<GuestInfoType | null>(null);
    const [scanStatus, setScanStatus] = useState<string>('');
	const [accessLog, setAccessLog] = useState<AccessLog | null>(null);
	const [gates, setGates] = useState<GateList | null>(null);

	function handleOpenScanQRCode() {
		setIsScanQRCodeOpen(true);
		setScanStatus('');
	}

	return (
		<PageWrapper title={'Onboarding'}>
			<Page container='fluid'>
				<section className='d-flex flex-column'>
					<div className='fw-bold fs-5 mx-4'>
						Onboarding Entrada
					</div>
					<small className='mx-4'>Se por algum motivo o convidado não puder apresentar o QRCode, você pode utilizar a tela Controle para fazer o check-in ou check-out manual do convidado.</small>
					<Card>
						<CardHeader>
							<Button
								className='btn btn-primary'
								onClick={() => handleOpenScanQRCode()}>
								Escanear QRCode
							</Button>
						</CardHeader>
						<CardBody>
							<GuestInfo 
								guestInfo={guestInfo}
								accessLog={accessLog}
								gates={gates}
							/>
						</CardBody>
						<Modal
							setIsOpen={setIsScanQRCodeOpen}
							isOpen={isScanQRCodeOpen}
							fullScreen={'lg'}
							isCentered
							titleId='qr-scanner-modal'>
							<ModalHeader setIsOpen={setIsScanQRCodeOpen}>
								<ModalTitle id='qr-scanner-modal'>Escanear QRCode</ModalTitle>
							</ModalHeader>
							<ModalBody className='h-100 d-flex align-items-center justify-content-center flex-column'>
								<div className='row w-100'>
									<QRScanner 
										setIsScanQRCodeOpen={setIsScanQRCodeOpen}
										setGuestInfo={setGuestInfo}
										setScanStatus={setScanStatus}
										setAccessLog={setAccessLog}
										setGates={setGates}
									/>
								</div>
								<div className='pt-3'>{scanStatus}</div>
							</ModalBody>
						</Modal>
					</Card>
				</section>
			</Page>
		</PageWrapper>
	);
};