import { useEffect, useState } from 'react';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';

import { UserSpotsList } from '../../../components/entrada-segura/user/user-spots-list';
import { CardBody } from '../../../components/bootstrap/Card';

export const HomeView = () => {
	const [whatsAppBotLink, setWhatsAppBotLink] = useState<string>('');

	useEffect(() => {
		const url = window.location.href;

		switch (url) {
			case 'https://demo.app.entradasegura.com.br/':
				setWhatsAppBotLink('https://api.whatsapp.com/send/?phone=5551990125633')
				break;
			case 'https://qa.app.entradasegura.com.br/':
				setWhatsAppBotLink('https://api.whatsapp.com/send/?phone=5551991822209')
				break;
			case 'https://appdev.entradasegura.com.br/':
				setWhatsAppBotLink('https://api.whatsapp.com/send/?phone=5551989664706')
				break;
			case 'http://app.entradasegura.com.br/':
				setWhatsAppBotLink('https://api.whatsapp.com/send/?phone=5551985173458')
				break;
			case 'http://localhost:3000/':
				setWhatsAppBotLink('https://api.whatsapp.com/send/?phone=5551989664706')
				break;
			default:
				break;
		}
	}, [])

	return (
		<>
			<PageWrapper title={'Home'}>
				<Page container='fluid'>
					<CardBody className='table-responsive'>
						<UserSpotsList />
						<span className="d-flex align-items-center justify-content-center">
							<a
								style={{ textDecoration: 'none', fontSize: '1.2rem', color: '#CECECE' }}
								href={whatsAppBotLink}
								rel="noreferrer"
								target="_blank"
							>
								Ajuda?
							</a>
						</span>
					</CardBody>
				</Page>
			</PageWrapper >
		</>
	);
};