import { GateList, Gate } from '../../type/gate';
import api from '../api';

import { HTTPGateListResponse, HTTPGateResponse } from './http-gate-response';
import { parseGate } from './parsers/gate-parser';

export async function getGateById(gateId: number): Promise<Gate> {
	return api.get(`/api/v1/gates/${gateId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPGateResponse;

		return parseGate(responseJson);
	});
}

export async function gatGatesByBuildingId(buildingId: number): Promise<GateList> {
	return api.get(`/api/v1/gates/building_id/${buildingId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPGateListResponse;

		const returnValue = responseJson?.results.map(parseGate);
		return returnValue;
	});
}

interface CreateGateInterface {
	name: string;
	buildingId: number;
	gateType: string;
	isOT: boolean;
	hasIntegration: boolean;
	iotManufacturer?: string | null;
	iotModel?: string | null;
	apiEndpoint?: string | null;
	apiUser?: string | null;
	apiPassword?: string | null;
	apiToken?: string | null;
	status: string;
	createdBy: number;
	createdAt: string;
}
export async function createGate({
	name,
	buildingId,
	gateType,
	isOT,
	hasIntegration,
	iotManufacturer,
	iotModel,
	apiEndpoint,
	apiUser,
	apiPassword,
	apiToken,
	status,
	createdBy,
	createdAt,
}: CreateGateInterface): Promise<Gate> {
	const body = {
		name,
		building_id: buildingId,
		gate_type: gateType,
		isOT,
		hasIntegration,
		iot_manufacturer: iotManufacturer,
		iot_model: iotModel,
		api_endpoint: apiEndpoint,
		api_user: apiUser,
		api_password: apiPassword,
		api_token: apiToken,
		status,
		created_by: createdBy,
		created_at: createdAt,
	};

	return api.post(`/api/v1/gates/`, JSON.stringify(body), {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPGateResponse;

		return parseGate(responseJson);
	});
}