interface TableColumnProps {
	children?: any;
	style?: any;
}

export const TableColumn: React.FC<TableColumnProps> = ({ children, style }) => (
	<td>
		<div className='d-flex'>
			<div className='ms-3 d-flex align-items-center text-nowrap' style={style}>
				{children}
			</div>
		</div>
	</td>
);
