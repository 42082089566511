import React from "react";
import { useContext } from "react";
import Button from "../../components/bootstrap/Button";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../components/bootstrap/Dropdown";
import { UserContext } from "../../contexts/user-context";
import { useUserBuildingsWithRoles } from "../../hooks/entrada-segura/use-user-buildings-with-roles";
import { useUserData } from "../../hooks/entrada-segura/use-user-data";
import useDarkMode from "../../hooks/useDarkMode";
import { logout } from "../../services/login/login-service";

export const UserInfo = () => {
  const { user } = useContext(UserContext);
  const { darkModeStatus } = useDarkMode();
  const userData = useUserData({ userId: user.id });
  const buildingsWithRoles = useUserBuildingsWithRoles({ userId: user.id, isSuperUser: user.isSuperUser });

  const userName = `${userData?.name} [${user.id}]`

  return (
    <Dropdown className="d-inline-block">
      <DropdownToggle hasIcon={false}>
        <Button
          id='userMenu'
          icon='Person'
          color={darkModeStatus ? 'dark' : undefined}>
          {userName}
        </Button>
      </DropdownToggle>
      <DropdownMenu isAlignmentEnd>
        {buildingsWithRoles && buildingsWithRoles.map((buildingWithRole, i) => {
          return (
            <DropdownItem key={`dropdown-item-building-info-${buildingWithRole.buildingName}-${i}`}>
              <Button
                isDisable
                icon='Building'
                color='info'
                isLight>
                {`${buildingWithRole.buildingName} - ${buildingWithRole.role}`}
              </Button>
            </DropdownItem>
          )
        })}
        <DropdownItem>
          <Button
            icon='Logout'
            color='info'
            isLight
            onClick={() => logout()}>
            Logout
          </Button>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};