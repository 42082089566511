import _isEmpty from 'lodash/isEmpty';

import { useEffect, useState } from "react";
import _get from 'lodash/get';
import { useBuildingName } from "../../../hooks/entrada-segura/use-building-name";
import { useSpotName } from "../../../hooks/entrada-segura/use-spot-name";
import { useUserName } from "../../../hooks/entrada-segura/use-user-name";
import { getUserSelfieById } from "../../../services/user/user-service";
import { Token } from "../../../type/token";
import Avatar from '../../Avatar';
import { phoneMask } from '../../../helpers/helpers';
import Button from '../../bootstrap/Button';

interface HeaderTokenActivationProps {
    token: Token;
    handleRejectToken: () => void;
    reasonAccess?: string;
}

export function HeaderTokenActivation({ token, handleRejectToken, reasonAccess }: HeaderTokenActivationProps) {
    const buildingName = useBuildingName({ buildingId: token.buildingId });
    const spotName = useSpotName({ spotId: token.spotId });
    const userName = useUserName({ userId: token.issuedFor });
    const userToActivateFone = _get(token, 'userRequested.telephone')
    const [avatar, setAvatar] = useState<string>("");

    useEffect(() => {
        const loadUserAvatar = async () => {
            const avatarSelfie = await getUserSelfieById(token.issuedFor, { setAvatar });
        };
    
        loadUserAvatar();
    }, [token.issuedFor]);
    

    return (
        <div className='col-12'>
            {reasonAccess &&
                <div className='fw-bold fs-5 me-2 d-flex justify-content-center'>
                    Informações do ENTREGADOR
                </div>
            }
            <br />
            <div className='d-flex align-items-center justify-content-between flex-row'>
                <div>
                    <div className='d-flex align-items-center w-100 justify-content-between gap-3 fw-bold fs-4 me-2 mb-3'>
                        {userName ? userName : "Usuário sem nome"}
                    </div>
                    <div className='fw-bold fs-7 me-2 mb-3'>
                        Fone: {phoneMask(userToActivateFone)}
                    </div>
                    {!reasonAccess &&
                        <div className='fw-normal fs-5 mb-3'>
                            {`${buildingName} ${_isEmpty(spotName) ? '' : `| ${spotName}`}`}
                        </div>
                    }
                    <div className='fw-normal fs-5 mb-3'>
                        <Button
                            className='text-danger px-2 ml-2 py-1'
                            onClick={() => handleRejectToken()}
                        >
                            Rejeitar
                        </Button>
                    </div>
                </div>
                <div>
                    <Avatar
                        srcSet={avatar}
                        src={avatar}
                        className='rounded-circle'
                        shadow='sm'
                        size={100}
                    />
                </div>
            </div>
        </div>
    )
}