import { useLocation, Navigate, Outlet } from "react-router-dom";
import { USER_ROLES_LOWER } from "../components/entrada-segura/token/token-activation-form/blocks/constants";
import { AppRoute } from "./routes";

interface AuthProps {
    allowedRoles: string[];
    path: string;
}

const Auth = ({ allowedRoles, path }: AuthProps) => {
    const userHighestRole = localStorage.getItem('highest-role') || "";
    const location = useLocation();

    if (userHighestRole !== "" && userHighestRole !== undefined) {
        if (path === AppRoute.Home && userHighestRole === USER_ROLES_LOWER.CONCIERGE) {
            return <Navigate to={AppRoute.Onboarding} state={{ from: location }} replace />
        }
    
        if (path === AppRoute.Home && userHighestRole === USER_ROLES_LOWER.GUEST) {
            return <Navigate to={AppRoute.Consult} state={{ from: location }} replace />
        }
    
        return allowedRoles.find((role) => userHighestRole.includes(role)) ? (
            <Outlet />
        ) : (
            <Navigate to={AppRoute.Home} state={{ from: location }} replace />
        )
    }

    return <Navigate to={AppRoute.Login} state={{ from: location }} replace />
};

export default Auth;