import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { AUTH_TYPES, TOKEN_STATUS, USER_ROLES_LOWER } from "../components/entrada-segura/token/token-activation-form/blocks/constants";
import { getTokensActivate, getTokensAnalyze, getTokensByAuthorizationTypes } from "../services/token/token-service";
import { UserContext } from "./user-context";
import { getBuildingId } from "../services/building/building-service";

const DEFAULT_VALUE = {
    hasPendingActivations: false,
    hasPendingAnalyzes: false,
    setBooleanValue: async () => {},
};

export const PendingActionsContext = createContext(DEFAULT_VALUE);

interface PendingActionsProviderProps {
    children: ReactNode;
}
export const PendingActionsProvider: React.FC<PendingActionsProviderProps> = ({ children }) => {
    const { user } = useContext(UserContext);
    const [hasPendingActivations, setHasPendingActivations] = useState<boolean>(false);
    const [hasPendingAnalyzes, setHasPendingAnalyzes] = useState<boolean>(false);
    const userHighestRole = localStorage.getItem("highest-role");

    const setBooleanValue = async () => {
        const responseActivate = await getTokensActivate({ issuedBy: user.id });
        setHasPendingActivations(responseActivate.totalRecords > 0);

        if (userHighestRole === USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole === USER_ROLES_LOWER.STAFF) {
            const buildingId = await getBuildingId(user.id);
            const tokensToQualify = await getTokensByAuthorizationTypes({ authorizationTypes: [AUTH_TYPES.QUALIFY], status: TOKEN_STATUS.PENDING, buildingId })

            setHasPendingAnalyzes(tokensToQualify.totalRecords > 0);
        }

        if (user.isSuperUser) {
            const responseAnalyze = await getTokensAnalyze({ status: TOKEN_STATUS.ACTIVE });
            setHasPendingAnalyzes(responseAnalyze.totalRecords > 0);
        }
    };

    useEffect(() => {
        setBooleanValue();
    }, [])

    return (
        <PendingActionsContext.Provider
            value={{
                hasPendingActivations,
                hasPendingAnalyzes,
                setBooleanValue
            }}>
            {children}
        </PendingActionsContext.Provider>
    )
};