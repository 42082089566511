import React, { useEffect, useState } from 'react';
import { USER_ROLES_LOWER } from '../../components/entrada-segura/token/token-activation-form/blocks/constants';
import { getAllTokensByAuthorizationTypes, getBuildingsByUser, getTokensIssuedByUserAndChildrenAuthorizationTypes, getTokensIssuedByUserAuthorizationTypes, getTokensIssuedForUserAuthorizationTypes } from '../../services/token/token-service';

import { TokenList } from '../../type/token';
import { useUserHighestRole } from './use-user-highest-role';

interface UseTokensIssuedByUserAuthorizationTypesParams {
	userId: number;
	isSuperUser: boolean;
	perPage?: number;
	page?: number;
	authorizationTypes: Array<string>;
	refreshTable: boolean;
	setIsFetching: React.Dispatch<React.SetStateAction<boolean>>;
	searchUserRequestedName?: string;
}

export function useTokensIssuedByUserAuthorizationTypes({
	userId,
	isSuperUser,
	page,
	perPage,
	authorizationTypes,
	refreshTable,
	setIsFetching,
	searchUserRequestedName
}: UseTokensIssuedByUserAuthorizationTypesParams): TokenList | undefined {
	const [tokens, setTokens] = useState<TokenList>();

	useEffect(() => {
		const getTokensData = async () => {
			const userHighestRole = localStorage.getItem('highest-role');

			if (userId) {
				if (isSuperUser) {
					const tokens = await getAllTokensByAuthorizationTypes({ page, perPage, authorizationTypes })

					setTokens(tokens);
					setIsFetching(false);
				}
				else if (userHighestRole === USER_ROLES_LOWER.SPOT_MANAGER ||
					userHighestRole === USER_ROLES_LOWER.ACCESS_MANAGER) {
					const buildingsOfUser = await getBuildingsByUser(userId);
					const buildingIds = buildingsOfUser.map(building => building.id);

					const tokens = await getTokensIssuedByUserAuthorizationTypes({ 
						userId, 
						page: 1, 
						perPage: 10000, 
						authorizationTypes, 
						buildingId: buildingIds,
						userRequestedName: searchUserRequestedName
					});

					const hasGrantUsers = tokens.results
						.filter(token => token.authorizationType === 'grant')
						.map(token => token.issuedFor)

					if (hasGrantUsers) {
						hasGrantUsers.push(userId);

						const tokensIssuedByUserAndChildren = await getTokensIssuedByUserAndChildrenAuthorizationTypes({ 
							userId: hasGrantUsers, 
							page, perPage, 
							authorizationTypes, 
							buildingId: buildingIds,
							userRequestedName: searchUserRequestedName
						});

						setTokens(tokensIssuedByUserAndChildren);
					} else {
						setTokens(tokens);
					}

					setIsFetching(false)
				}
				else if (userHighestRole === USER_ROLES_LOWER.GUEST || 
					userHighestRole === USER_ROLES_LOWER.CONCIERGE) {
					const tokens = await getTokensIssuedForUserAuthorizationTypes({ userId, page: 1, perPage: 10000, authorizationTypes });

					setTokens(tokens);
					setIsFetching(false)
				}
			}
			setIsFetching(false)
		};

		getTokensData()
			.then((data) => {
				return data;
			})
			.catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, page, perPage, refreshTable]);

	return tokens;
}