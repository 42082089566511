import api from '../../api';
import { HTTPMetricResponse } from '../http-metric-response';
import { parseMetric } from '../parsers/metric-parser';

export async function getSpotsCountInBuilding(buildingId: number): Promise<number> {
	return api.get(`/api/v1/metrics/spots/${buildingId}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPMetricResponse;

		return parseMetric(responseJson);
	});
}

interface GetSpotsCountByTypeInBuildingParams {
	buildingId: number;
	spotType: string;
}
export async function getSpotsCountByTypeInBuilding({
	buildingId,
	spotType,
}: GetSpotsCountByTypeInBuildingParams): Promise<number> {
	return api.get(`/api/v1/metrics/spots/${buildingId}/spot_type/${spotType}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPMetricResponse;

		return parseMetric(responseJson);
	});
}

interface GetSpotsCountByStatusInBuildingParams {
	buildingId: number;
	spotStatus: string;
}
export async function getSpotsCountByStatusInBuilding({
	buildingId,
	spotStatus,
}: GetSpotsCountByStatusInBuildingParams): Promise<number> {
	return api.get(`/api/v1/metrics/spots/${buildingId}/status/${spotStatus}`, {
		headers: {
			'Content-type': 'application/json; charset=UTF-8',
		}
	}).then((response) => {
		const responseJson = response.data as HTTPMetricResponse;

		return parseMetric(responseJson);
	});
}