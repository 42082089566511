import React from 'react';

import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../bootstrap/OffCanvas';
import { useTokenAccessLog } from '../../../../hooks/entrada-segura/use-token-access-log';
// import { AccessLogEntry } from './access-log-entry';
import AccessLogEntry2 from './access-log-entry-2';

interface TokenAccessLogProps {
	tokenId: string;
	userId?: number;
	setShouldOpenTokenLog: any;
	shouldOpenTokenLog: any;
}
export const TokenAccessLog: React.FC<TokenAccessLogProps> = ({
	tokenId,
	setShouldOpenTokenLog,
	shouldOpenTokenLog,
}) => {
	const accessLogs = useTokenAccessLog({ tokenId });

	return (
		<OffCanvas
			key={`token-access-log-${tokenId}`}
			setOpen={setShouldOpenTokenLog}
			isOpen={shouldOpenTokenLog}
			titleId='tokenAccessLog'
			isBodyScroll
			placement='end'>
			<OffCanvasHeader setOpen={setShouldOpenTokenLog}>
				<OffCanvasTitle id='tokenAccessLog'>Extrato de acessos do token</OffCanvasTitle>
			</OffCanvasHeader>

			{accessLogs && accessLogs?.length >= 1 &&
        (<OffCanvasBody>
          {accessLogs?.map((accessLog, index) => (
            <AccessLogEntry2 accessLog={accessLog} key={`access-logs-${index}`} />
          ))}
        </OffCanvasBody>
        )}
		</OffCanvas>
	);
};
