import Button from "../../../bootstrap/Button";
import Card, { CardBody } from "../../../bootstrap/Card";

interface ProvideGrantAccessProps {
    setCanGrantAccess: React.Dispatch<React.SetStateAction<string>>;
    canGrantAccess: string;
}

export function ProvideGrantAccess({ setCanGrantAccess, canGrantAccess }: ProvideGrantAccessProps) {
    return (
        <Card>
            <CardBody>
                <div className='fw-bold fs-5 mb-3 d-flex justify-content-center'>
                    Ele(a) poderá autorizar em seu nome?
                </div>

                <div className='d-flex justify-content-center gap-3'>
                    <Button
                        color='info'
                        isLink={canGrantAccess === "NO" ? false : true}
                        onClick={() => {setCanGrantAccess("NO")}}
                    >
                        Não
                    </Button>
                    <Button
                        color='info'
                        isLink={canGrantAccess === "YES" ? false : true}
                        onClick={() => {setCanGrantAccess("YES")}}
                    >
                        Sim
                    </Button>
                </div>
            </CardBody>
        </Card>
    )
}