import { useContext } from 'react';

import PageWrapper from '../../../layout/PageWrapper/PageWrapper';

import { ConsultComponents } from '../../../components/entrada-segura/consult-components/consult-components';
import { ConsultComponentsContractManager } from '../../../components/entrada-segura/consult-components-contract-manager';
import { UserContext } from '../../../contexts/user-context';
import { useUserHighestRole } from '../../../hooks/entrada-segura/use-user-highest-role';
import { USER_ROLES_LOWER } from '../../../components/entrada-segura/token/token-activation-form/blocks/constants';

export const ConsultView = () => {
	const { user } = useContext(UserContext)

	const userHighestRole = useUserHighestRole({ userId: user.id })

	return (
		<PageWrapper title={'Consultar'}>
			<div className='row'>
				<div className='col-xl-12'>
					{(userHighestRole === USER_ROLES_LOWER.CONTRACT_MANAGER || userHighestRole === USER_ROLES_LOWER.STAFF) ?
						<ConsultComponentsContractManager />
						:
						<ConsultComponents />
					}
				</div>
			</div>
		</PageWrapper>
	);
};
