import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';

import { CardBody } from '../../../components/bootstrap/Card';

import Chart, { IChartOptions } from '../../../components/extras/Chart';

import { useState } from 'react';

export const Dashboard = () => {
	const [stackedColumn] = useState<IChartOptions>({
		series: [
			{
				name: 'Tempo para autorizar',
				data: [44, 55, 41, 37, 22, 43, 21],
			},
			{
				name: 'Tempo de deslocamento',
				data: [53, 32, 33, 52, 13, 43, 32],
			},
		],
		options: {
			chart: {
				type: 'bar',
				height: 3500,
				stacked: true,
			},
			plotOptions: {
				bar: {
					horizontal: true,
				},
			},
			stroke: {
				width: 1,
				colors: ['#000'],
			},
			title: {
				text: 'Instituto Caldeira',
			},
			xaxis: {
				categories: ['Token 1', 'Token 2', 'Token 3', 'Token 4', 'Token 5', 'Token 6', 'Token 7'],
			},
			yaxis: {
				title: {
					text: undefined,
				},
			},
			tooltip: {
				y: {
					formatter: function (val) {
						return val + 'min';
					},
				},
			},
			fill: {
				opacity: 1,
			},
			legend: {
				position: 'top',
				horizontalAlign: 'left',
				offsetX: 40,
			},
		},
	});

	return (
		<PageWrapper title={'Tempo de Autorizações'}>
			<Page container='fluid'>
				<CardBody className='table-responsive'>
					<Chart
						series={stackedColumn.series}
						options={stackedColumn.options}
						type='area'
						height={650}
					/>
				</CardBody>
			</Page>
		</PageWrapper>
	);
};
