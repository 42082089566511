import styled, { css } from 'styled-components';

interface IDropContainerProps {
  isDragActive: boolean;
  isDragReject: boolean;
}

interface IMessageProps {
  type: string;
}

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 400px;
  margin:10px;
  background:#F1F0EF ;
  border-radius: 4px;
  padding: 20px;
`;

export const DropContainer = styled.div.attrs({
  className: "dropzone"
}) <IDropContainerProps>`
  border: 1px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;

  transition: height 0.2s ease;

  ${props => props.isDragActive && dragActive};
  
  ${props => props.isDragReject && dragReject}
`;

const messageColors: { [key in any]: string } = {
  default: '#999',
  error: '#e57878',
  success: '#78e5d5'
}

export const UploadMessage = styled.p<IMessageProps>`
  display: flex;
  color: ${props => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  margin: 0;
  text-align: center;
`;