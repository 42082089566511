import { useEffect, useState } from 'react';
import {
	getGatesCountByStatusInBuilding,
	getGatesCountByTypeInBuilding,
	getGatesCountInBuilding,
} from '../../../services/metrics/gates/gates-metrics-service';

interface UseGatesCountByBuildingIdParams {
	buildingId: number | undefined;
}

export function useGatesCountByBuildingId({
	buildingId,
}: UseGatesCountByBuildingIdParams): number | undefined {
	const [count, setCount] = useState<number>();

	useEffect(() => {
		if (buildingId) {
			const getBuildingsData = async () => {
				const data = await getGatesCountInBuilding(buildingId);
				setCount(data);
			};

			getBuildingsData()
				.then((data) => {
					return data;
				})
				.catch(console.error);
		}
	}, [buildingId]);

	return count;
}

interface UseGatesCountByBuildingIdAndGateTypeParams {
	buildingId: number | undefined;
	gateType: string | undefined;
}

export function useGatesCountByBuildingIdAndGateType({
	buildingId,
	gateType,
}: UseGatesCountByBuildingIdAndGateTypeParams): number | undefined {
	const [count, setCount] = useState<number>();

	useEffect(() => {
		if (buildingId && gateType) {
			const getBuildingsData = async () => {
				const data = await getGatesCountByTypeInBuilding({ buildingId, gateType });
				setCount(data);
			};

			getBuildingsData()
				.then((data) => {
					return data;
				})
				.catch(console.error);
		}
	}, [buildingId, gateType]);

	return count;
}

interface UseGatesCountByBuildingIdAndGateStatusParams {
	buildingId: number | undefined;
	gateStatus: string | undefined;
}

export function useGatesCountByBuildingIdAndGateStatus({
	buildingId,
	gateStatus,
}: UseGatesCountByBuildingIdAndGateStatusParams): number | undefined {
	const [count, setCount] = useState<number>();

	useEffect(() => {
		if (buildingId && gateStatus) {
			const getBuildingsData = async () => {
				const data = await getGatesCountByStatusInBuilding({ buildingId, gateStatus });
				setCount(data);
			};

			getBuildingsData()
				.then((data) => {
					return data;
				})
				.catch(console.error);
		}
	}, [buildingId, gateStatus]);

	return count;
}
