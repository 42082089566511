interface RequiredFieldMessageProps {
	fieldError: any;
}

export const RequiredFieldMessage: React.FC<RequiredFieldMessageProps> = ({ fieldError }) => {
	return (
		fieldError && (
			<span className='text-red-400' style={{ color: 'red' }}>
				{`${fieldError}`}
			</span>
		)
	);
};
