import moment from 'moment';
import React from 'react';

import _get from 'lodash/get';

import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../bootstrap/Card';
import { TimelineItem } from '../../../extras/Timeline';
import { useBuildingName } from '../../../../hooks/entrada-segura/use-building-name';
import { useSpotName } from '../../../../hooks/entrada-segura/use-spot-name';
import { useUserName } from '../../../../hooks/entrada-segura/use-user-name';
import { AccessLog } from '../../../../type/access-log';

interface AccessLogEntryProps {
	accessLog: AccessLog;
	key: string;
}

export const AccessLogEntry2: React.FC<AccessLogEntryProps> = ({ accessLog, key }) => {
	const conciergeName = useUserName({ userId: accessLog.conciergeUserId });
	const buildingName = useBuildingName({ buildingId: accessLog.buildingId });
	const spotName = useSpotName({ spotId: accessLog.spotId });

	const onboardingAt = _get(accessLog, 'onboardingAt', '');
	const checkoutAt = _get(accessLog, 'checkoutAt', '');
	const checkoutGateId = _get(accessLog, 'checkoutGateId', '');
	const rejectedAt = _get(accessLog, 'rejectedAt');

	return (
		<Card stretch>
			<CardHeader>
				<CardLabel icon='ViewList' iconColor='warning'>
					<CardTitle tag='h4' className='h5'>
						{`${buildingName} | ${spotName}`}
					</CardTitle>
					<CardSubTitle>{_get(accessLog, 'description', '')}</CardSubTitle>
				</CardLabel>
			</CardHeader>
			<CardBody isScrollable>
				<TimelineItem label={moment(checkoutAt).format('DD/MM/YYYY, hh:mm:ss')} color='warning'>
					{`Check-out pelo portão ${checkoutGateId}`}
				</TimelineItem>

				{rejectedAt && (
					<TimelineItem label={moment(rejectedAt).format('DD/MM/YYYY, hh:mm:ss')} color='danger'>
						Acesso negado
					</TimelineItem>
				)}

				<TimelineItem label={moment(onboardingAt).format('DD/MM/YYYY, hh:mm:ss')} color='success'>
					{`Onboarding feito por funcionário ${conciergeName}, no portão ${_get(
						accessLog,
						'onboardingGateId',
						'',
					)}`}
				</TimelineItem>
			</CardBody>
		</Card>
	);
};

export default AccessLogEntry2;
