import { User, UserDocumentsByUserId } from '../../../type/user';
import { HTTPUserDocumentsByUserId, HTTPUserResponse } from '../http-users-response';

export function parseUser(httpUser: HTTPUserResponse): User {
	return {
		id: httpUser.id,
		name: httpUser.name,
		taxid: httpUser.taxid,
		telephone: httpUser.telephone,
		email: httpUser.email,
		selfie: httpUser.selfie,
		role: httpUser.role,
		birthdate: httpUser.birthdate,
		fatherName: httpUser.father_name,
		motherName: httpUser.mother_name,
		documentId: httpUser.document_id,
		status: httpUser.status,
		isSuperuser: httpUser.is_superuser,
		createdAt: httpUser.created_at,
		createdBy: httpUser.created_by,
		modifiedAt: httpUser.modified_at,
		modifiedBy: httpUser.modified_by,
		selfieSourceUrl: httpUser.selfie_source_url,
		selfieUploadedAt: httpUser.selfie_uploaded_at,
		inquiryStatus: httpUser.inquiry_status,
		observation: httpUser.observation
	};
}


export function parseUserDocuments(httpUserDocuments: HTTPUserDocumentsByUserId): UserDocumentsByUserId {
	return {
		id: httpUserDocuments.id,
		userId: httpUserDocuments.user_id,
		documentType: httpUserDocuments.document_type,
		documentUrl: httpUserDocuments.document_url,
		documentSourceUrl: httpUserDocuments.document_source_url,
		uploadedAt: httpUserDocuments.uploaded_at,
		documentIssuedAt: httpUserDocuments.document_issued_at,
		documentExpiresAt: httpUserDocuments.document_expires_at,
		status: httpUserDocuments.status,
		createdAt: httpUserDocuments.created_at,
		createdBy: httpUserDocuments.created_by,
		modifiedAt: httpUserDocuments.modified_at,
		modifiedBy: httpUserDocuments.modified_by,
	};
}