import React, { Fragment, ReactNode, useContext, useEffect, useState } from 'react';

import Spinner from '../components/bootstrap/Spinner';
import { UserContext } from '../contexts/user-context';
import api from '../services/api';
import { restoreSession } from '../services/login/login-service';
import { LoginRedirect } from './login-redirect';

interface PrivateProps {
	children: ReactNode;
}

export const Private: React.FC<PrivateProps> = ({ children }) => {
	const { user, setUser } = useContext(UserContext);

	const [authenticating, setAuthenticating] = useState(true);

	useEffect(() => {
		const token = localStorage.getItem('active-token');

		if (token) {
			api.defaults.headers.Authorization = `Bearer ${token}`;
		}
	}, []);

	useEffect(() => {
		const authenticateUser = async () => {
			if (user.id === 0) {
				setAuthenticating(true);
				try {
					await restoreSession(setUser);
					setAuthenticating(false);
				} catch {
					setAuthenticating(false);
				}
			}
		}

		authenticateUser()
			.then((data) => {
				return data;
			})
			.catch(console.error);

	}, [user, authenticating, setUser]);

	if (user.id !== 0) {
		return <Fragment>{children}</Fragment>;
	}

	return authenticating ? (
		<Spinner
			style={{ position: 'absolute', height: '100px', width: '100px', top: '50%', left: '50%' }}
		/>
	) : (
		<LoginRedirect />
	);
};